import Warehouse from '@models/Warehouse';
import axios from './axios';
import {
  INormalizedWarehouse,
  INormalizedWarehouses,
  normalizeWarehouse,
  normalizeWarehouses,
} from './warehousesUtils';

export const WAREHOUSE_API = {
  getWarehosues: '/warehouses',
  postWarehouse: '/warehouses/new',
  putWarehouse: '/warehouses/edit',
};

/**
 * Create warehouse
 * @param  {Object} warehouse Warehouse
 * @param  {string} warehouse.name Warehouse name
 * @param  {string} warehouse.site Warehouse site
 * @param  {string} warehouse.timezone Warehouse timezone
 * @return {Object} Warehouse created
 */
/* eslint-disable @typescript-eslint/indent */
export const createWarehouse = async ({
  name,
  site,
  timezone,
  country,
  metricsTotalPalletsMoved,
  metricsFleetUsedCapacity,
  numberOfShifts,
  shiftHours,
  workingDays,
  targetForkliftType,
}: Pick<
  Warehouse,
  | 'name'
  | 'site'
  | 'timezone'
  | 'country'
  | 'metricsTotalPalletsMoved'
  | 'metricsFleetUsedCapacity'
  | 'numberOfShifts'
  | 'shiftHours'
  | 'workingDays'
  | 'targetForkliftType'
>): Promise<INormalizedWarehouse> => {
  /* eslint-enable @typescript-eslint/indent */
  const { data: warehouse } = await axios.post(WAREHOUSE_API.postWarehouse, {
    name,
    site,
    timezone,
    country,
    metricsTotalPalletsMoved,
    metricsFleetUsedCapacity,
    numberOfShifts,
    shiftHours,
    workingDays,
    targetForkliftType,
  });

  return normalizeWarehouse(warehouse);
};

/**
 * Update warehouse
 * @param  {string} warehouseId Warehouse id
 * @param  {Object} warehouse Warehouse properties
 * @param  {string} warehouse.name Warehouse name
 * @param  {string} warehouse.site Warehouse site
 * @param  {string} warehouse.timezone Warehouse timezone
 * @return {Object} Warehouse created
 */
export const updateWarehouse = async (
  warehouseId: string,
  {
    name,
    site,
    timezone,
    country,
    metricsTotalPalletsMoved,
    metricsFleetUsedCapacity,
    numberOfShifts,
    shiftHours,
    workingDays,
    targetForkliftType,
  }: Pick<
    Warehouse,
    | 'name'
    | 'site'
    | 'timezone'
    | 'country'
    | 'metricsTotalPalletsMoved'
    | 'metricsFleetUsedCapacity'
    | 'numberOfShifts'
    | 'shiftHours'
    | 'workingDays'
    | 'targetForkliftType'
  >
): Promise<INormalizedWarehouse> => {
  const { data: warehouse } = await axios.put(WAREHOUSE_API.putWarehouse, {
    id: warehouseId,
    name,
    site,
    timezone,
    country,
    metricsTotalPalletsMoved,
    metricsFleetUsedCapacity,
    numberOfShifts,
    shiftHours,
    workingDays,
    targetForkliftType,
  });

  return normalizeWarehouse(warehouse);
};

/**
 * Fetches all warehouses from API and converts it to a object with complete and incomplete warehouses.
 * @return {Promise<{warehouses: types, incompleteWarehouses: types}>} Object with complete and incomplete warehouses
 */
export async function fetchWarehouses(): Promise<INormalizedWarehouses> {
  const { data: warehouses } = await axios.get(WAREHOUSE_API.getWarehosues);

  return normalizeWarehouses(warehouses);
}

/**
 * Delete warehouse
 * @param   {string} warehouseId Warehouse id
 * @returns {void}   Nothing
 */
export const deleteWarehouse = async (warehouseId: string) =>
  axios.delete(`/warehouses/delete/id/${warehouseId}`);
