import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/plans';
import { Plans } from '@models/Plan';

type Actions = ActionType<typeof types | typeof auth>;

export interface IPlansState {
  readonly plans: Plans;
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectId?: string;
}

export const initialState: IPlansState = {
  plans: {},
  error: undefined,
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IPlansState = initialState,
  action: Actions
): IPlansState => {
  switch (action.type) {
    case getType(types.createPlan.request):
      return {
        ...state,
        error: undefined,
        loading: true,
        redirectId: undefined,
      };

    case getType(types.createPlan.success):
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.payload.id]: action.payload,
        },
        redirectId: action.payload.id,
        loading: false,
        error: undefined,
      };

    case getType(types.createPlan.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.fetchPlans.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchPlans.success): {
      const { plans } = action.payload;

      return {
        ...state,
        plans,
        error: undefined,
        loading: false,
      };
    }

    case getType(types.fetchPlans.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.updatePlan.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.updatePlan.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.updatePlan.success):
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.payload.id]: action.payload,
        },
        loading: false,
        error: undefined,
      };

    case getType(types.deletePlan.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deletePlan.success):
      return {
        ...state,
        plans: omit(state.plans, action.payload.id),
        error: undefined,
        loading: false,
      };

    case getType(types.deletePlan.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.wsUpdatePlan):
    case getType(types.wsCreatePlan): {
      const { plan } = action.payload;

      return {
        ...state,
        plans: {
          ...state.plans,
          [plan.id]: plan,
        },
      };
    }

    case getType(types.wsDeletePlan):
      return {
        ...state,
        plans: omit(state.plans, action.payload.id),
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
