import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/configurations';
import { ConfigFiles } from '@models/ConfigFile';
import FetchError from '@models/FetchError';

type ConfigurationType = ActionType<typeof types>;

interface IFiles {
  configFiles?: ConfigFiles;
  error?: FetchError;
  loading: boolean;
}

export interface IConfigurationState {
  readonly files: Record<string, IFiles>;
}

export const initialState: IConfigurationState = {
  files: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IConfigurationState = initialState,
  action: ConfigurationType
): IConfigurationState => {
  switch (action.type) {
    case getType(types.fetchConfigFiles.request): {
      const { type } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [type]: {
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchConfigFiles.success): {
      const { configFiles, type } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [type]: {
            configFiles,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.deleteConfigFile.failure):
    case getType(types.updateConfigFile.failure):
    case getType(types.createConfigFile.failure):
    case getType(types.fetchConfigFiles.failure): {
      const { type, error } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [type]: {
            ...state.files[type],
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.deleteConfigFile.request):
    case getType(types.updateConfigFile.request):
    case getType(types.createConfigFile.request): {
      const { type } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          [type]: {
            ...state.files[type],
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.createConfigFile.success): {
      const { type } = action.payload;

      const configFiles = state.files[type].configFiles || [];
      configFiles.push(action.payload);

      return {
        ...state,
        files: {
          ...state.files,
          [type]: {
            configFiles,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.updateConfigFile.success): {
      const file = action.payload;

      const configFiles = state.files[file.type].configFiles || [];
      const index = configFiles.findIndex((f) => f.id === file.id);
      configFiles[index] = { ...configFiles[index], ...file };

      return {
        ...state,
        files: {
          ...state.files,
          [file.type]: {
            configFiles,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.deleteConfigFile.success): {
      const file = action.payload;

      const configFiles = state.files[file.type].configFiles || [];
      const index = configFiles.findIndex((f) => f.id === file.id);
      configFiles.splice(index, 1);

      return {
        ...state,
        files: {
          ...state.files,
          [file.type]: {
            configFiles,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    default:
      return state;
  }
};
