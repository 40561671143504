import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/simulations';
import {
  createSimulation,
  deleteSimulation,
  fetchSimulation,
  fetchSimulationData,
  fetchSimulations,
  fetchRoutingData,
  createRoutingPaths,
  stopSimulation,
} from '@api/simulations';
import { treatFetchError } from '@api/utils';
import { Date } from '@dashboard_utils/index';
import { ISimulationData, ISimulatorConfig, Simulation, Simulations } from '@models/Simulation';

export function* fetchSimulationSaga(
  action: ActionType<typeof types.fetchSimulation.request>
) {
  const { id } = action.payload;

  try {
    const data: Simulation = yield call(fetchSimulation, id);

    yield put(types.fetchSimulation.success(data));
  } catch (error: any) {
    yield put(types.fetchSimulation.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchSimulationsSaga(
  action: ActionType<typeof types.fetchSimulations.request>
) {
  const { floorplanId, shallow } = action.payload;

  try {
    const simulations: Simulations = yield call(fetchSimulations, floorplanId);

    yield put(
      types.fetchSimulations.success({ floorplanId, simulations, shallow })
    );
  } catch (error: any) {
    yield put(
      types.fetchSimulations.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* createSimulationSaga(
  action: ActionType<typeof types.createSimulation.request>
) {
  const {
    floorplanId,
    name,
    agents,
    orderVolume,
    length,
    router,
    routerDynamic,
    modelFloorplanId,
    inputCsv,
    startTs,
    shifts,
  } = action.payload;

  try {
    const data: Simulation = yield call(
      createSimulation,
      floorplanId,
      name,
      agents,
      orderVolume,
      length,
      router,
      routerDynamic,
      modelFloorplanId,
      inputCsv,
      startTs,
      shifts,
    );

    yield put(types.createSimulation.success(data));
  } catch (error: any) {
    yield put(
      types.createSimulation.failure({ error: treatFetchError(error) })
    );
  }
}

export function* deleteSimulationSaga(
  action: ActionType<typeof types.deleteSimulation.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteSimulation, id);

    yield put(types.deleteSimulation.success({ id }));
  } catch (error: any) {
    yield put(
      types.deleteSimulation.failure({ error: treatFetchError(error) })
    );
  }
}

export function* stopSimulationSaga(
  action: ActionType<typeof types.stopSimulation.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(stopSimulation, id);

    yield put(types.stopSimulation.success({ id }));
  } catch (error: any) {
    yield put(
      types.stopSimulation.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchSimulationDataSaga(
  action: ActionType<typeof types.fetchSimulationData.request>
) {
  const { id, timestamp } = action.payload;

  try {
    const data: Record<string, ISimulationData[]> = yield call(
      fetchSimulationData,
      id,
      timestamp
    );

    yield put(
      types.fetchSimulationData.success({
        data,
        id,
        timestamp,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchSimulationData.failure({
        error: treatFetchError(error),
        id,
        timestamp,
        lastErrorTs: Date.now(),
      })
    );
  }
}

export function* fetchRoutingDataSaga(
  action: ActionType<typeof types.fetchRoutingData.request>
) {
  const { floorplanId } = action.payload;

  try {
    const data: ISimulatorConfig = yield call(
      fetchRoutingData,
      floorplanId
    );

    yield put(
      types.fetchRoutingData.success({
        floorplanId,
        data,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchRoutingData.failure({
        floorplanId,
        error: treatFetchError(error),
      })
    );
  }
}

export function* createRoutingPathsSaga(
  action: ActionType<typeof types.createRoutingPaths.request>
) {
  const { floorplanId, position, points } = action.payload;

  try {
    const data: {
      path: [number, number][],
      orders: [number, number][],
    } = yield call(createRoutingPaths, floorplanId, position, points);

    yield put(types.createRoutingPaths.success({ floorplanId, data }));
  } catch (error: any) {
    yield put(
      types.createRoutingPaths.failure({ floorplanId, error: treatFetchError(error) })
    );
  }
}
