import Diagram, { Diagrams } from '@models/Diagram';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export function normalizeDiagrams(rules: Diagram[]): Diagrams {
  return convertArrayToIdMap(rules);
}

export const DIAGRAMS_API = {
  postDiagram: '/diagrams/new/',
  putDiagram: '/diagrams/edit/',
  getDiagrams: '/diagrams/',
};

export async function createDiagram(
  name: string,
  diagram: object
): Promise<Diagram> {
  const { data } = await axios.post(DIAGRAMS_API.postDiagram, {
    name,
    diagram,
  });

  return data;
}

export async function updateDiagram(
  id: string,
  name: string,
  diagram: object
): Promise<Diagram> {
  const { data } = await axios.put(DIAGRAMS_API.putDiagram, {
    id,
    name,
    diagram,
  });

  return data;
}

export async function deleteDiagram(id: string): Promise<void> {
  await axios.delete(`/diagrams/delete/id/${id}`);
}

export async function fetchDiagrams(): Promise<Diagrams> {
  const { data } = await axios.get(DIAGRAMS_API.getDiagrams);

  return normalizeDiagrams(data);
}
