import { createAction, createAsyncAction } from 'typesafe-actions';

import { Notification, Notifications } from '@models/Notification';
import { IFailed } from './types';

export enum NotificationsTypes {
  DISMISS_NOTIFICATIONS = '@@notifications/DISMISS_NOTIFICATIONS',
  DISMISS_NOTIFICATIONS_SUCCEEDED = '@@notifications/DISMISS_NOTIFICATIONS_SUCCEEDED',
  DISMISS_NOTIFICATIONS_FAILED = '@@notifications/DISMISS_NOTIFICATIONS_FAILED',

  FETCH_NOTIFICATIONS_COUNT = '@@notifications/FETCH_NOTIFICATIONS_COUNT',
  FETCH_NOTIFICATIONS_COUNT_FAILED = '@@notifications/FETCH_NOTIFICATIONS_COUNT_FAILED',
  FETCH_NOTIFICATIONS_COUNT_SUCCEEDED = '@@notifications/FETCH_NOTIFICATIONS_COUNT_SUCCEEDED',

  FETCH_NOTIFICATIONS = '@@notifications/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_FAILED = '@@notifications/FETCH_NOTIFICATIONS_FAILED',
  FETCH_NOTIFICATIONS_SUCCEEDED = '@@notifications/FETCH_NOTIFICATIONS_SUCCEEDED',

  HANDLE_NOTIFICATION = '@@notifications/HANDLE_NOTIFICATION',

  UNREAD_NOTIFICATIONS = '@@notifications/UNREAD_NOTIFICATIONS',
  UNREAD_NOTIFICATIONS_SUCCEEDED = '@@notifications/UNREAD_NOTIFICATIONS_SUCCEEDED',
  UNREAD_NOTIFICATIONS_FAILED = '@@notifications/UNREAD_NOTIFICATIONS_FAILED',
}

export interface INotificationId {
  id: string;
  type: string;
}
export interface INotificationsAction {
  ids: INotificationId[];
  locale: string;
  units: string;
}

export const dismissNotifications = createAsyncAction(
  NotificationsTypes.DISMISS_NOTIFICATIONS,
  NotificationsTypes.DISMISS_NOTIFICATIONS_SUCCEEDED,
  NotificationsTypes.DISMISS_NOTIFICATIONS_FAILED
)<INotificationsAction, Notifications, IFailed>();

export const fetchNotificationsCount = createAsyncAction(
  NotificationsTypes.FETCH_NOTIFICATIONS_COUNT,
  NotificationsTypes.FETCH_NOTIFICATIONS_COUNT_SUCCEEDED,
  NotificationsTypes.FETCH_NOTIFICATIONS_COUNT_FAILED
)<void, number, IFailed>();

export interface IFetchNotifications {
  locale: string;
  units: string;
}
export const fetchNotifications = createAsyncAction(
  NotificationsTypes.FETCH_NOTIFICATIONS,
  NotificationsTypes.FETCH_NOTIFICATIONS_SUCCEEDED,
  NotificationsTypes.FETCH_NOTIFICATIONS_FAILED
)<IFetchNotifications, Notifications, IFailed>();

export const handleNotification = createAction(
  NotificationsTypes.HANDLE_NOTIFICATION,
  (notification: Notification, type: string) =>
    ({ notification, type })
)();

export const unreadNotifications = createAsyncAction(
  NotificationsTypes.UNREAD_NOTIFICATIONS,
  NotificationsTypes.UNREAD_NOTIFICATIONS_SUCCEEDED,
  NotificationsTypes.UNREAD_NOTIFICATIONS_FAILED
)<INotificationsAction, Notifications, IFailed>();
