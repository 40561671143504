import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/reports';
import FetchError from '@models/FetchError';

type Actions = ActionType<typeof types>;

export interface IReport {
  loading?: boolean;
  report?: any;
  error?: FetchError;
}

export interface IReportsState {
  readonly reports: Record<string, IReport>;
  readonly roiReports: Record<string, IReport>;
}

export const initialState: IReportsState = {
  reports: {},
  roiReports: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IReportsState = initialState,
  action: Actions
): IReportsState => {
  switch (action.type) {
    case getType(types.fetchReport.request): {
      const { filterId } = action.payload;

      return {
        ...state,
        reports: {
          ...state.reports,
          [filterId]: {
            ...(state.reports[filterId] || {}),
            error: undefined,
            loading: true,
            report: undefined,
          },
        },
      };
    }

    case getType(types.fetchReport.success): {
      const { filterId, report } = action.payload;

      return {
        ...state,
        reports: {
          ...state.reports,
          [filterId]: {
            ...(state.reports[filterId] || {}),
            error: undefined,
            loading: false,
            report,
          },
        },
      };
    }

    case getType(types.fetchReport.failure): {
      const { error, filterId } = action.payload;

      return {
        ...state,
        reports: {
          ...state.reports,
          [filterId]: {
            ...(state.reports[filterId] || {}),
            error,
            loading: false,
            report: undefined,
          },
        },
      };
    }

    case getType(types.fetchROIReport.request): {
      const { filterId } = action.payload;

      return {
        ...state,
        roiReports: {
          ...state.roiReports,
          [filterId]: {
            ...(state.roiReports[filterId] || {}),
            error: undefined,
            loading: true,
            report: undefined,
          },
        },
      };
    }

    case getType(types.fetchROIReport.success): {
      const { filterId, report } = action.payload;

      return {
        ...state,
        roiReports: {
          ...state.roiReports,
          [filterId]: {
            ...(state.roiReports[filterId] || {}),
            error: undefined,
            loading: false,
            report,
          },
        },
      };
    }

    case getType(types.fetchROIReport.failure): {
      const { error, filterId } = action.payload;

      return {
        ...state,
        roiReports: {
          ...state.roiReports,
          [filterId]: {
            ...(state.roiReports[filterId] || {}),
            error,
            loading: false,
            report: undefined,
          },
        },
      };
    }

    default:
      return state;
  }
};
