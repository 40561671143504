/* eslint-disable no-console */
import { EventEmitter } from 'events';

import {
  wsCreateAsset,
  wsDeleteAsset,
  wsUpdateAsset,
  wsCreateAssetTicket,
  wsCreateAssetTicketComment,
  wsAssignAssetTicket,
  wsChangeAssetTicketStatus,
  wsChangeAssetTicketArchive,
  wsCreateAssetDocument,
  wsDeleteAssetDocument,
} from '@actions/assets';
import { wsUpdateUser } from '@actions/authentication';
import {
  wsCreateDiagram,
  wsDeleteDiagram,
  wsUpdateDiagram,
} from '@actions/diagrams';
import { wsCreatePlan, wsDeletePlan, wsUpdatePlan } from '@actions/plans';
import {
  wsCreateEmployee,
  wsDeleteEmployee,
  wsUpdateEmployee,
} from '@actions/employees';
import {
  wsCreateShift,
  wsDeleteShift,
  wsUpdateShift,
} from '@actions/shifts';
import {
  wsCreateFloorplan,
  wsDeleteFloorplan,
  wsUpdateFloorplan,
} from '@actions/floorplans';
import { wsCreateSensor } from '@actions/sensors';
import {
  wsCreateSimulation,
  wsDeleteSimulation,
  wsSimulationStatusUpdate,
} from '@actions/simulations';
import {
  wsCreateWarehouse,
  wsDeleteWarehouse,
  wsUpdateWarehouse,
} from '@actions/warehouses';
import { wsCreateZone, wsDeleteZone, wsUpdateZone } from '@actions/zones';
import {
  wsCreateTicket,
  wsDeleteTicket,
  wsUpdateTicket,
  wsCreateTicketComment,
  wsDeleteTicketComment,
} from '@actions/tickets';
import {
  wsChatMessage
} from '@actions/chats';
import {
  wsCreateScan
} from '@actions/scans';
import store from '../store';
import { wsCreateTask, wsDeleteTask, wsUpdateTask } from '@actions/tasks';

class WS extends EventEmitter {
  private con: WebSocket | undefined;

  private uri?: string;

  private reconnectionTimer?: any;

  private disconnectionTimer?: any;

  public connectionId?: string;

  constructor() {
    super();

    if (!process.env.REACT_APP_WS_URL) {
      console.log(
        'REACT_APP_WS_URL not set. Web socket capability will not function correctly.'
      );
    } else {
      this.uri = `${process.env.REACT_APP_WS_URL}/ws`;

      this.connect();
    }

    this.handleMessage = this.handleMessage.bind(this);
  }

  public componentWillUnmount() {
    if (this.disconnectionTimer) {
      clearTimeout(this.disconnectionTimer);
    }

    if (this.reconnectionTimer) {
      clearTimeout(this.reconnectionTimer);
    }
  }

  /**
   * @public
   * @description Sends sensor command through ws
   * @param       {string} id Sensor id
   * @param       {string} command command, list (https://sonodot.atlassian.net/wiki/spaces/SON/pages/4390913/Messages+API)
   * @param       {string} type command type (sensor-command|group-command)
   */
  public sendCommand(
    id: string,
    command: string,
    type = 'sensor-command'
  ) {
    if (this.con && this.con.readyState) {
      this.con.send(JSON.stringify({ type, message: { id, command } }));
    }
  }

  /**
   * @public
   * @description Sends sensor group command through ws
   * @param       {string} id Sensor group id
   * @param       {string} command command, list
   */
  public sendGroupCommand(id: string, command: string) {
    this.sendCommand(id, command, 'group-command');
  }
  /**
   * @public
   * @description Sends mobile datapoint through ws
   * @param       {Object} data Data point data
   */
  public sendMobileDataPoint(data: object) {
    if (this.con && this.con.readyState) {
      this.con.send(JSON.stringify({ type: 'mobile-datapoint', message: data }));
    }
  }

  public ping(activeSensorGroupId: string) {
    if (this.con && this.con.readyState) {
      this.con.send(
        JSON.stringify({
          type: 'livemap-ping',
          message: { id: activeSensorGroupId },
        })
      );
    }
  }

  public handleMessage(msg: any) {
    const messageData = JSON.parse(msg.data);

    switch (messageData.type) {
      case 'authentication':
        this.connectionId = messageData.message.connectionId;
        break;

      case 'new_warehouse':
        store.dispatch(
          wsCreateWarehouse({
            warehouse: { ...messageData.message, floorplans: [] },
          })
        );
        break;

      case 'edit_warehouse':
        store.dispatch(wsUpdateWarehouse({ warehouse: messageData.message }));
        break;

      case 'delete_warehouse':
        store.dispatch(wsDeleteWarehouse(messageData.message));
        break;

      case 'new_floorplan':
        store.dispatch(wsCreateFloorplan({ floorplan: messageData.message }));
        break;

      case 'edit_floorplan':
        store.dispatch(wsUpdateFloorplan({ floorplan: messageData.message }));
        break;

      case 'delete_floorplan':
        store.dispatch(
          wsDeleteFloorplan({
            floorplanId: messageData.message.id,
            warehouseId: messageData.message.warehouseId,
          })
        );
        break;

      case 'new_sensor':
        store.dispatch(wsCreateSensor({ sensor: messageData.message }));
        break;

      case 'new_zone':
        store.dispatch(wsCreateZone({ zone: messageData.message }));
        break;

      case 'edit_zone':
        store.dispatch(wsUpdateZone({ zone: messageData.message }));
        break;

      case 'delete_zone':
        store.dispatch(wsDeleteZone({ id: messageData.message.id }));
        break;

      case 'new_asset':
        store.dispatch(wsCreateAsset({ asset: messageData.message }));
        break;

      case 'edit_asset':
        store.dispatch(wsUpdateAsset({ asset: messageData.message }));
        break;

      case 'delete_asset':
        store.dispatch(wsDeleteAsset(messageData.message));
        break;

      case 'new_asset-ticket':
        store.dispatch(wsCreateAssetTicket(messageData.message));
        break;

      case 'new_asset-ticket-comment':
        store.dispatch(wsCreateAssetTicketComment(messageData.message));
        break;

      case 'update_asset-ticket-assign':
        store.dispatch(wsAssignAssetTicket(messageData.message));
        break;

      case 'update_asset-ticket-status':
        store.dispatch(wsChangeAssetTicketStatus(messageData.message));
        break;

      case 'update_asset-ticket-archive':
        store.dispatch(wsChangeAssetTicketArchive(messageData.message));
        break;

      case 'new_asset-document':
        store.dispatch(wsCreateAssetDocument(messageData.message));
        break;

      case 'delete_asset-document':
        store.dispatch(wsDeleteAssetDocument(messageData.message));
        break;

      case 'new_diagram':
        store.dispatch(wsCreateDiagram({ diagram: messageData.message }));
        break;

      case 'edit_diagram':
        store.dispatch(wsUpdateDiagram({ diagram: messageData.message }));
        break;

      case 'delete_diagram':
        store.dispatch(wsDeleteDiagram(messageData.message));
        break;

      case 'new_plan':
        store.dispatch(wsCreatePlan({ plan: messageData.message }));
        break;

      case 'edit_plan':
        store.dispatch(wsUpdatePlan({ plan: messageData.message }));
        break;

      case 'delete_plan':
        store.dispatch(wsDeletePlan(messageData.message));
        break;

      case 'new_employee':
        store.dispatch(wsCreateEmployee({ employee: messageData.message }));
        break;

      case 'edit_employee':
        store.dispatch(wsUpdateEmployee({ employee: messageData.message }));
        break;

      case 'delete_employee':
        store.dispatch(wsDeleteEmployee(messageData.message));
        break;

      case 'new_shift':
        store.dispatch(wsCreateShift({ shift: messageData.message }));
        break;

      case 'edit_shift':
        store.dispatch(wsUpdateShift({ shift: messageData.message }));
        break;

      case 'delete_shift':
        store.dispatch(wsDeleteShift(messageData.message));
        break;

      case 'new_task':
        store.dispatch(wsCreateTask({ task: messageData.message }));
        break;

      case 'edit_task':
        store.dispatch(wsUpdateTask({ task: messageData.message }));
        break;

      case 'delete_task':
        store.dispatch(wsDeleteTask(messageData.message));
        break;

      case 'create_simulation':
        store.dispatch(wsCreateSimulation(messageData.message));
        break;

      case 'delete_simulation':
        store.dispatch(wsDeleteSimulation(messageData.message));
        break;

      case 'simulation_run':
        store.dispatch(wsSimulationStatusUpdate(messageData.message));
        break;

      case 'new_ticket':
        store.dispatch(wsCreateTicket({ ticket: messageData.message }));
        break;

      case 'edit_ticket':
        store.dispatch(wsUpdateTicket({ ticket: messageData.message }));
        break;

      case 'delete_ticket':
        store.dispatch(wsDeleteTicket(messageData.message));
        break;

      case 'new_ticket_comment':
        store.dispatch(wsCreateTicketComment({ comment: messageData.message }));
        break;

      case 'delete_ticket_comment':
        store.dispatch(wsDeleteTicketComment(messageData.message));
        break;

      case 'update_user':
        store.dispatch(wsUpdateUser(messageData.message));
        break;

      case 'chat':
        store.dispatch(wsChatMessage(messageData.message));
        break;

      case 'new_scan':
        store.dispatch(wsCreateScan({ scan: messageData.message }));
        break;
  
      default:
        this.emit(messageData.type, messageData.message);
        break;
    }
  }

  public reconnect(triggerFetching?: boolean) {
    window.dispatchEvent(new Event('WSReconnecting'));

    if (this.con) {
      this.con.onclose = () => null;
      this.con.close();
    }

    this.connect(true, triggerFetching);
  }

  private connect(
    reconnect = false,
    triggerFetching = false
  ) {
    try {
      if (this.uri) {
        this.con = new WebSocket(this.uri);

        if (this.con) {
          this.con.onerror = (error) => {
            console.error(error);

            this.con!.close();
          };

          this.con.onopen = () => {
            if (this.disconnectionTimer) {
              clearTimeout(this.disconnectionTimer);
            }

            if (reconnect) {
              window.dispatchEvent(new Event('WSReconnected'));

              if (triggerFetching) {
                window.dispatchEvent(new Event('WSFetchTrigger'));
              }
            }

            if (this.reconnectionTimer) {
              clearTimeout(this.reconnectionTimer);
            }
          };

          this.con.onmessage = (msg: any) => this.handleMessage(msg);

          this.con.onclose = () => {
            this.connectionId = undefined;

            this.reconnectionTimer = setTimeout(() => {
              this.reconnect();
            }, 10000);

            this.disconnectionTimer = setTimeout(() => {
              window.dispatchEvent(new Event('WSDisconnected'));
            }, 15000);

            console.log('WS socket closed');
          };
        }
      }
    } catch (e) {
      console.error(
        `Web socket to ${process.env.REACT_APP_WS_URL} could not be created. Data streaming will not function correctly.`,
        e
      );
    }
  }
}

export default new WS();

export interface IReportProgressData {
  requestData?: { filterId: string };
  step: string;
}

export enum ReportProgress {
  'CollectingWhInfo',
  'FetchingAnalytics',
  'GeneratingAnalytics',
  'CompilingReport',
  'Downloading',
}

export interface ISensorData {
  direction?: number;
  id: string;
  ts: number;
  group?: string;
  moving?: boolean;
  segment?: number;
  // eslint-disable-next-line camelcase
  vel_xy?: number;
  // eslint-disable-next-line camelcase
  vel_z?: number;
  dtype?: string;
  speed?: number;
  x: number;
  y: number;
  z?: number;
}

export interface IOrdersEvents {
  ts: number;
  orderId: string;
  eventtype: 'order_created' | 'item_assigned' | 'item_picked' | 'item_fulfilled';
  items: number;
}

export interface IRawSensorData {
  id: string;
  ts: number;
  dtype: string;
  latitude: number;
  longitude: number;
  speed: number;
}

export interface ISensorBatteryData {
  sensorId: string;
  ts: number;
  status: string;
  batteryFlag: string;
  rssi: number;
  vcc: number;
  workingTime: number;
}

export interface IGatewayPingData {
  sensorGroupId: string;
  ts: number;
  event: string;
  info: {
    ip: string;
    device: string;
  };
}
