import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/accounts';
import * as auth from '@actions/authentication';
import * as chats from '@actions/chats';
import { AccountsMeta } from '@models/AccountMeta';
import FetchError from '@models/FetchError';
import Account from '@models/Account';

type Actions = ActionType<typeof types | typeof chats | typeof auth>;

export interface IAccountsState {
  readonly account?: Account;
  readonly accounts?: AccountsMeta;
  readonly form: {
    open: boolean;
    loading: boolean;
    error?: FetchError;
  };
  readonly inviteForm: {
    open: boolean;
    loading: boolean;
    error?: FetchError;
  };
  readonly loading: boolean;
  readonly permissions?: string[];
  readonly permissionsLoading: boolean;
  readonly error?: FetchError;
}

export const initialState: IAccountsState = {
  form: { open: false, loading: false },
  inviteForm: { open: false, loading: false },
  loading: false,
  permissionsLoading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAccountsState = initialState,
  action: Actions
): IAccountsState => {
  switch (action.type) {
    case getType(types.fetchAvailablePermissions.request):
      return {
        ...state,
        error: undefined,
        permissions: undefined,
        permissionsLoading: true,
      };

    case getType(types.fetchAvailablePermissions.failure):
      return {
        ...state,
        error: action.payload.error,
        permissionsLoading: false,
      };

    case getType(types.fetchAvailablePermissions.success):
      return {
        ...state,
        error: undefined,
        permissions: action.payload.permissions,
        permissionsLoading: false,
      };

    case getType(types.activateAccount.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.activateAccount.failure):
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case getType(types.activateAccount.success): {
      const { id, active } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: {
            ...(state.accounts || {})[id],
            active,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(types.createAccount.request):
      return {
        ...state,
        error: undefined,
        form: {
          ...state.form,
          loading: true,
        },
      };

    case getType(types.createAccount.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createAccount.success): {
      const { account } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [account.id]: account,
        },
        form: { open: false, loading: false },
      };
    }

    case getType(types.createAccountInvite.request):
      return {
        ...state,
        error: undefined,
        inviteForm: {
          ...state.inviteForm,
          loading: true,
        },
      };

    case getType(types.createAccountInvite.failure): {
      const { error } = action.payload;

      return {
        ...state,
        inviteForm: {
          ...state.inviteForm,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createAccountInvite.success): {
      const { account } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [account.id]: account,
        },
        inviteForm: { open: false, loading: false },
      };
    }

    case getType(types.changeAccountPassword.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.changeAccountPassword.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.changeAccountPassword.success): {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }

    case getType(types.updateAccountPermissions.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.updateAccountPermissions.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.updateAccountPermissions.success): {
      const { id, permissions } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: {
            ...(state.accounts || {})[id],
            permissions,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(types.fetchAccounts.request): {
      const { shallow } = action.payload;
      const { accounts, loading } = state;

      return {
        ...state,
        accounts: shallow === true ? accounts : undefined,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchAccounts.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchAccounts.success): {
      const { accounts, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        accounts,
        error: undefined,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.openAccountForm):
      return {
        ...state,
        form: { open: true, loading: false },
      };

    case getType(types.closeAccountForm):
      return {
        ...state,
        form: { open: false, loading: false },
      };

    case getType(types.openInviteForm):
      return {
        ...state,
        inviteForm: { open: true, loading: false },
      };

    case getType(types.closeInviteForm):
      return {
        ...state,
        inviteForm: { open: false, loading: false },
      };

    case getType(types.wsChatAlive): {
      const { accountId } = action.payload;
      const { accounts } = state;

      const newAccounts = { ...accounts };
      if (newAccounts[accountId]) {
        newAccounts[accountId] = {
          ...newAccounts[accountId],
          lastSeen: Date.now(),
        };
      }

      return {
        ...state,
        accounts: newAccounts,
        error: undefined,
        loading: false,
      };
    }

    case getType(chats.fetchMessages.success): {
      const { toAccountId } = action.payload;
      const { accounts } = state;

      const newAccounts = { ...accounts };
      if (newAccounts[toAccountId]) {
        newAccounts[toAccountId] = {
          ...newAccounts[toAccountId],
          messagesUnseen: 0,
        };
      }

      return {
        ...state,
        accounts: newAccounts,
      };
    }

    case getType(chats.wsChatMessage): {
      const { toAccountId, fromAccountId } = action.payload;
      const { account, accounts } = state;

      let key = toAccountId;
      if ((account || {}).id === toAccountId) {
        key = fromAccountId;
      }

      const newAccounts = { ...accounts };
      if (newAccounts[key]) {
        newAccounts[key] = {
          ...newAccounts[key],
          messagesUnseen: ((newAccounts[key] || {}).messagesUnseen || 0) + 1,
        };
      }

      return {
        ...state,
        accounts: newAccounts,
      };
    }

    case getType(auth.fetchAccount.success): {
      const account = action.payload;

      return {
        ...state,
        account,
      };
    }
    case getType(auth.deleteAccount.success):
    case getType(auth.logout.success):
    case getType(auth.login.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
