import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/configurations';
import {
  createConfigFile,
  deleteConfigFile,
  fetchConfigFiles,
  updateConfigFile,
} from '@api/configurations';
import { treatFetchError } from '@api/utils';
import { ConfigFile, ConfigFiles } from '@models/ConfigFile';

export function* fetchConfigFilesSaga(
  action: ActionType<typeof types.fetchConfigFiles.request>
) {
  const { type } = action.payload;
  try {
    const configFiles: ConfigFiles = yield call(fetchConfigFiles, type);

    yield put(types.fetchConfigFiles.success({ configFiles, type }));
  } catch (error: any) {
    yield put(
      types.fetchConfigFiles.failure({ error: treatFetchError(error), type })
    );
  }
}

export function* createConfigFileSaga(
  action: ActionType<typeof types.createConfigFile.request>
) {
  const {
    content,
    filename,
    floorplanId,
    sensorGroupId,
    type,
    version,
    warehouseId,
  } = action.payload;
  try {
    const configFile: ConfigFile = yield call(
      createConfigFile,
      type,
      filename,
      content,
      warehouseId,
      floorplanId,
      sensorGroupId
    );

    yield put(
      types.createConfigFile.success({
        ...configFile,
        type,
        version,
      })
    );
  } catch (error: any) {
    yield put(
      types.createConfigFile.failure({ error: treatFetchError(error), type })
    );
  }
}

export function* updateConfigFileSaga(
  action: ActionType<typeof types.updateConfigFile.request>
) {
  const { content, filename, id, type } = action.payload;

  try {
    const configFile: ConfigFile = yield call(
      updateConfigFile,
      id,
      filename,
      content
    );

    yield put(types.updateConfigFile.success({ ...configFile, type }));
  } catch (error: any) {
    yield put(
      types.updateConfigFile.failure({ error: treatFetchError(error), type })
    );
  }
}

export function* deleteConfigFileSaga(
  action: ActionType<typeof types.deleteConfigFile.request>
) {
  const { id, type, intl } = action.payload;
  try {
    yield call(deleteConfigFile, id);

    yield put(types.deleteConfigFile.success({ id, type }));
  } catch (error: any) {
    yield put(
      types.deleteConfigFile.failure({
        error: treatFetchError(error),
        intl,
        type,
      })
    );
  }
}
