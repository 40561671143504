import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/authentication';
import {
  accountVerification,
  accountSetup,
  changeMode,
  fetchAccount,
  passwordRecovery,
  passwordReset,
  login,
  logout,
  signUp,
  updateAccountContacts,
  updateAccountPassword,
  updateAccountReport,
  updateAccountSettings,
  deleteAccount,
} from '@api/authentication';
import { treatFetchError } from '@api/utils';
import Account, { IAccountMeta } from '@models/Account';

export function* accountVerificationSaga(
  action: ActionType<typeof types.accountVerification.request>
) {
  const { email, verification } = action.payload;

  try {
    yield call(accountVerification, email, verification);

    yield put(types.accountVerification.success());
  } catch (error: any) {
    yield put(
      types.accountVerification.failure({ error: treatFetchError(error) })
    );
  }
}

export function* accountSetupSaga(
  action: ActionType<typeof types.accountSetup.request>
) {
  const { email, password, verification } = action.payload;

  try {
    yield call(accountSetup, email, password, verification);

    yield put(types.accountSetup.success());
  } catch (error: any) {
    yield put(
      types.accountSetup.failure({ error: treatFetchError(error) })
    );
  }
}

export function* changeModeSaga(
  action: ActionType<typeof types.changeMode.request>
) {
  const { organisationId } = action.payload;

  try {
    yield call(changeMode, organisationId);

    yield put(types.changeMode.success({ organisationId }));
  } catch (error: any) {
    yield put(types.changeMode.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchAccountSaga() {
  try {
    const account: Account = yield call(fetchAccount);

    yield put(types.fetchAccount.success(account));
  } catch (error: any) {
    yield put(types.fetchAccount.failure({ error: treatFetchError(error) }));
  }
}

export function* passwordRecoverySaga(
  action: ActionType<typeof types.passwordRecovery.request>
) {
  const { email } = action.payload;

  try {
    yield call(passwordRecovery, email);

    yield put(types.passwordRecovery.success());
  } catch (error: any) {
    yield put(
      types.passwordRecovery.failure({ error: treatFetchError(error) })
    );
  }
}

export function* passwordResetSaga(
  action: ActionType<typeof types.passwordReset.request>
) {
  const { email, code, password } = action.payload;

  try {
    yield call(passwordReset, email, code, password);

    yield put(types.passwordReset.success());
  } catch (error: any) {
    yield put(types.passwordReset.failure({ error: treatFetchError(error) }));
  }
}

export function* loginSaga(action: ActionType<typeof types.login.request>) {
  const { email, password, fcmDeviceInfo } = action.payload;

  try {
    yield call(login, email, password, fcmDeviceInfo);

    yield put(types.login.success());
  } catch (error: any) {
    yield put(types.login.failure({ error: treatFetchError(error) }));
  }
}

export function* logoutSaga() {
  try {
    const response: AxiosResponse<any> = yield call(logout);

    if (response.status === 204) {
      yield put(types.logout.success());
    } else {
      yield put(types.logout.failure(response.data.message));
    }
  } catch (error: any) {
    yield put(types.logout.failure({ error: treatFetchError(error) }));
  }
}

export function* signUpSaga(action: ActionType<typeof types.signUp.request>) {
  const { name, company, email, password } = action.payload;

  try {
    yield call(signUp, name, company, email, password);

    yield put(types.signUp.success());
  } catch (error: any) {
    yield put(types.signUp.failure({ error: treatFetchError(error) }));
  }
}

export function* updateAccountSettingsSaga(
  action: ActionType<typeof types.updateAccountSettings.request>
) {
  const { intl, properties } = action.payload;

  try {
    const accountMeta: IAccountMeta = yield call(
      updateAccountSettings,
      properties
    );

    yield put(types.updateAccountSettings.success({ accountMeta, intl }));
  } catch (error: any) {
    yield put(
      types.updateAccountSettings.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* updateAccountContactsSaga(
  action: ActionType<typeof types.updateAccountContacts.request>
) {
  const { properties, intl } = action.payload;

  try {
    const accountMeta: IAccountMeta = yield call(
      updateAccountContacts,
      properties
    );

    yield put(types.updateAccountContacts.success({ accountMeta, intl }));
  } catch (error: any) {
    yield put(
      types.updateAccountContacts.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* updateAccountPasswordSaga(
  action: ActionType<typeof types.updateAccountPassword.request>
) {
  const { currentPassword, intl, password } = action.payload;

  try {
    const account: Account = yield call(
      updateAccountPassword,
      currentPassword,
      password
    );

    yield put(types.updateAccountPassword.success({ account, intl }));
  } catch (error: any) {
    yield put(
      types.updateAccountPassword.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* updateAccountReportSaga(
  action: ActionType<typeof types.updateAccountReport.request>
) {
  const { reportConfigs, intl } = action.payload;

  try {
    const accountMeta: IAccountMeta = yield call(
      updateAccountReport,
      reportConfigs
    );

    yield put(types.updateAccountReport.success({ accountMeta, intl }));
  } catch (error: any) {
    yield put(
      types.updateAccountReport.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* deleteAccountSaga(
  action: ActionType<typeof types.deleteAccount.request>
) {
  const { intl } = action.payload;

  try {
    yield call(deleteAccount);

    yield put(types.deleteAccount.success());
  } catch (error: any) {
    yield put(
      types.deleteAccount.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}
