import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { treatFetchError } from '@api/utils';

import * as types from '@actions/rules';
import {
  activateRule,
  createRule,
  deactivateRule,
  deleteRule,
  fetchRuleAlerts,
  fetchRules,
  fetchTemplates,
  updateRule,
} from '@api/rules';
import { RuleTemplates } from '@models/RuleTemplate';
import Rule, { Rules } from '@models/Rule';

export function* activateRuleSaga(
  action: ActionType<typeof types.deleteRule.request>
) {
  try {
    yield call(activateRule, action.payload.id);

    yield put(types.activateRule.success(action.payload));
  } catch (error: any) {
    yield put(types.activateRule.failure({ error: treatFetchError(error) }));
  }
}

export function* createRuleSaga(
  action: ActionType<typeof types.createRule.request>
) {
  const {
    name,
    notifyEmail,
    notifyMobile,
    notifyWhatsapp,
    options,
    templateId,
  } = action.payload;

  try {
    const rule: Rule = yield call(
      createRule,
      name,
      notifyEmail,
      notifyMobile,
      notifyWhatsapp,
      options,
      templateId
    );

    yield put(types.createRule.success(rule));
  } catch (error: any) {
    yield put(types.createRule.failure({ error: treatFetchError(error) }));
  }
}

export function* deactivateRuleSaga(
  action: ActionType<typeof types.deleteRule.request>
) {
  try {
    yield call(deactivateRule, action.payload.id);

    yield put(types.deactivateRule.success(action.payload));
  } catch (error: any) {
    yield put(types.deactivateRule.failure({ error: treatFetchError(error) }));
  }
}

export function* deleteRuleSaga(
  action: ActionType<typeof types.deleteRule.request>
) {
  try {
    yield call(deleteRule, action.payload.id);

    yield put(types.deleteRule.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteRule.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchTemplatesSaga(
  action: ActionType<typeof types.fetchTemplates.request>
) {
  const { locale, units, shallow } = action.payload;

  try {
    const templates: RuleTemplates = yield call(fetchTemplates, locale, units);

    yield put(types.fetchTemplates.success({ shallow, templates }));
  } catch (error: any) {
    yield put(
      types.fetchTemplates.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* fetchRulesSaga(
  action: ActionType<typeof types.fetchRules.request>
) {
  const { shallow } = action.payload;

  try {
    const rules: Rules = yield call(fetchRules);

    yield put(types.fetchRules.success({ rules, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchRules.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* fetchRuleAlertsSaga(
  action: ActionType<typeof types.fetchRuleAlerts.request>
) {
  const {
    filterId,
    floorplanId,
    locale,
    ruleIds,
    templateIds,
    units,
    startTs,
    endTs,
  } = action.payload;

  try {
    const ruleAlerts: types.RuleAlerts = yield call(
      fetchRuleAlerts,
      floorplanId,
      locale,
      ruleIds,
      templateIds,
      units,
      startTs,
      endTs
    );

    yield put(types.fetchRuleAlerts.success({ filterId, ruleAlerts }));
  } catch (error: any) {
    yield put(
      types.fetchRuleAlerts.failure({ filterId, error: treatFetchError(error) })
    );
  }
}

export function* updateRuleSaga(
  action: ActionType<typeof types.updateRule.request>
) {
  const {
    id,
    name,
    notifyEmail,
    notifyMobile,
    notifyWhatsapp,
    options,
    templateId,
  } = action.payload;

  try {
    const rule: Rule = yield call(
      updateRule,
      id,
      name,
      notifyEmail,
      notifyMobile,
      notifyWhatsapp,
      options,
      templateId
    );

    yield put(types.updateRule.success(rule));
  } catch (error: any) {
    yield put(types.updateRule.failure({ error: treatFetchError(error) }));
  }
}
