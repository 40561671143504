import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Plan, { Plans } from '@models/Plan';
import { IFailed, IFeedbackFailed } from './types';

export enum PlansTypes {
  FETCH = '@@plans/FETCH',
  FETCH_SUCCEEDED = '@@plans/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@plans/FETCH_FAILED',

  CREATE = '@@plans/CREATE',
  CREATE_SUCCEEDED = '@@plans/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@plans/CREATE_FAILED',

  UPDATE = '@@plans/UPDATE',
  UPDATE_SUCCEEDED = '@@plans/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@plans/UPDATE_FAILED',

  DELETE = '@@plans/DELETE',
  DELETE_SUCCEEDED = '@@plans/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@plans/DELETE_FAILED',

  WS_CREATE = '@@plans/WS_CREATE',
  WS_UPDATE = '@@plans/WS_UPDATE',
  WS_DELETE = '@@plans/WS_DELETE',
}

export interface IFetchPlans {
  floorplanId: string;
}

interface IFetchSuccess {
  plans: Plans;
}

export const fetchPlans = createAsyncAction(
  PlansTypes.FETCH,
  PlansTypes.FETCH_SUCCEEDED,
  PlansTypes.FETCH_FAILED
)<IFetchPlans, IFetchSuccess, IFailed>();

export interface ICreatePlan {
  intl: IntlShape;

  name: string;
  floorplanId: string;
  plan: object;
}
export const createPlan = createAsyncAction(
  PlansTypes.CREATE,
  PlansTypes.CREATE_SUCCEEDED,
  PlansTypes.CREATE_FAILED
)<ICreatePlan, Plan, IFeedbackFailed>();

export interface IUpdatePlan extends ICreatePlan {
  id: string;
}

export const updatePlan = createAsyncAction(
  PlansTypes.UPDATE,
  PlansTypes.UPDATE_SUCCEEDED,
  PlansTypes.UPDATE_FAILED
)<IUpdatePlan, Plan, IFeedbackFailed>();

export interface IDeletePlan {
  id: string;
  intl: IntlShape;
}
export const deletePlan = createAsyncAction(
  PlansTypes.DELETE,
  PlansTypes.DELETE_SUCCEEDED,
  PlansTypes.DELETE_FAILED
)<IDeletePlan, IDeletePlan, IFeedbackFailed>();

interface IWSPlanUpdated {
  plan: Plan;
}
interface IWSPlanDeleted {
  id: string;
}

export const wsCreatePlan = createAction(
  PlansTypes.WS_CREATE,
  (data: IWSPlanUpdated) => data
)();
export const wsUpdatePlan = createAction(
  PlansTypes.WS_UPDATE,
  (data: IWSPlanUpdated) => data
)();
export const wsDeletePlan = createAction(
  PlansTypes.WS_DELETE,
  (data: IWSPlanDeleted) => data
)();
