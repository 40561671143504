import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Ticket, { Tickets, TicketComment } from '@models/Ticket';
import { IFailed, IFeedbackFailed } from './types';

export enum TicketsTypes {
  CREATE = '@@tickets/CREATE',
  CREATE_SUCCEEDED = '@@tickets/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@tickets/CREATE_FAILED',

  EDIT = '@@tickets/EDIT',
  EDIT_SUCCEEDED = '@@tickets/EDIT_SUCCEEDED',
  EDIT_FAILED = '@@tickets/EDIT_FAILED',

  ARCHIVE = '@@tickets/ARCHIVE',
  ARCHIVE_SUCCEEDED = '@@tickets/ARCHIVE_SUCCEEDED',
  ARCHIVE_FAILED = '@@tickets/ARCHIVE_FAILED',

  DELETE = '@@tickets/DELETE',
  DELETE_SUCCEEDED = '@@tickets/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@tickets/DELETE_FAILED',

  CREATE_COMMENT = '@@tickets/CREATE_COMMENT',
  CREATE_COMMENT_SUCCEEDED = '@@tickets/CREATE_COMMENT_SUCCEEDED',
  CREATE_COMMENT_FAILED = '@@tickets/CREATE_COMMENT_FAILED',

  DELETE_COMMENT = '@@tickets/DELETE_COMMENT',
  DELETE_COMMENT_SUCCEEDED = '@@tickets/DELETE_COMMENT_SUCCEEDED',
  DELETE_COMMENT_FAILED = '@@tickets/DELETE_COMMENT_FAILED',

  FETCH = '@@tickets/FETCH',
  FETCH_SUCCEEDED = '@@tickets/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@tickets/FETCH_FAILED',

  WS_CREATE = '@@tickets/WS_CREATE',
  WS_UPDATE = '@@tickets/WS_UPDATE',
  WS_DELETE = '@@tickets/WS_DELETE',

  WS_CREATE_COMMENT = '@@tickets/WS_CREATE_COMMENT',
  WS_DELETE_COMMENT = '@@tickets/WS_DELETE_COMMENT',
}

export interface ExtendedFile extends File {
  id?: string;
}

export interface CreateTicket {
  subject: string;
  description: string;
  files: ExtendedFile[];
  intl: IntlShape;
}

export interface UpdateTicket extends CreateTicket {
  id: string;
}

interface TicketSuccess {
  intl: IntlShape;
  ticket: Ticket;
}

export const createTicket = createAsyncAction(
  TicketsTypes.CREATE,
  TicketsTypes.CREATE_SUCCEEDED,
  TicketsTypes.CREATE_FAILED
)<CreateTicket, TicketSuccess, IFeedbackFailed>();

export const updateTicket = createAsyncAction(
  TicketsTypes.EDIT,
  TicketsTypes.EDIT_SUCCEEDED,
  TicketsTypes.EDIT_FAILED
)<UpdateTicket, TicketSuccess, IFeedbackFailed>();

export interface ArchiveTicket {
  id: string;
  intl: IntlShape;
}
export const archiveTicket = createAsyncAction(
  TicketsTypes.ARCHIVE,
  TicketsTypes.ARCHIVE_SUCCEEDED,
  TicketsTypes.ARCHIVE_FAILED
)<ArchiveTicket, TicketSuccess, IFeedbackFailed>();

export interface DeleteTicket {
  id: string;
  intl: IntlShape;
}
export const deleteTicket = createAsyncAction(
  TicketsTypes.DELETE,
  TicketsTypes.DELETE_SUCCEEDED,
  TicketsTypes.DELETE_FAILED
)<DeleteTicket, DeleteTicket, IFeedbackFailed>();

export interface FetchTickets {
  ticketStatus: 'open' | 'archived';
}
export const fetchTickets = createAsyncAction(
  TicketsTypes.FETCH,
  TicketsTypes.FETCH_SUCCEEDED,
  TicketsTypes.FETCH_FAILED
)<FetchTickets, Tickets, IFailed>();

export interface CreateTicketComment {
  ticketId: string;
  comment: string;
  intl: IntlShape;
}
interface TicketCommentSuccess {
  comment: TicketComment;
}

export const createTicketComment = createAsyncAction(
  TicketsTypes.CREATE_COMMENT,
  TicketsTypes.CREATE_COMMENT_SUCCEEDED,
  TicketsTypes.CREATE_COMMENT_FAILED
)<CreateTicketComment, TicketCommentSuccess, IFeedbackFailed>();

export interface DeleteTicketComment {
  ticketId: string;
  id: string;
  intl: IntlShape;
}
interface DeleteTicketCommentSuccess {
  ticketId: string;
  id: string;
}

export const deleteTicketComment = createAsyncAction(
  TicketsTypes.DELETE_COMMENT,
  TicketsTypes.DELETE_COMMENT_SUCCEEDED,
  TicketsTypes.DELETE_COMMENT_FAILED
)<DeleteTicketComment, DeleteTicketCommentSuccess, IFeedbackFailed>();

interface IWSTicketUpdated {
  ticket: Ticket;
}
interface IWSTicketDeleted {
  id: string;
}

export const wsCreateTicket =  createAction(
  TicketsTypes.WS_CREATE,
  (data: IWSTicketUpdated) => data,
)();
export const wsUpdateTicket =  createAction(
  TicketsTypes.WS_UPDATE,
  (data: IWSTicketUpdated) => data
)();
export const wsDeleteTicket =  createAction(
  TicketsTypes.WS_DELETE,
  (data: IWSTicketDeleted) => data
)();

interface IWSTicketCommentUpdated {
  comment: TicketComment;
}
interface IWSTicketCommentDeleted {
  ticketId: string;
  id: string;
}
export const wsCreateTicketComment =  createAction(
  TicketsTypes.WS_CREATE_COMMENT,
  (data: IWSTicketCommentUpdated) => data
)();
export const wsDeleteTicketComment =  createAction(
  TicketsTypes.WS_DELETE_COMMENT,
  (data: IWSTicketCommentDeleted) => data
)();
