import { IntlShape } from 'react-intl';
import { createAction, createAsyncAction } from 'typesafe-actions';

import Floorplan from '@models/Floorplan';
import { IDrawDefinition } from '@models/types';
import IncompleteFloorplan, {
  IGeoMapping,
  ItemLocation,
} from '@models/IncompleteFloorplan';
import { Zones } from '@models/Zone';
import { TransformationMatrix2D } from '../utils';
import { INormalizedIncompleteFloorplan } from '../api/warehousesUtils';
import { IFailed, IFeedbackFailed, IFeedbackFailedWithShallow } from './types';

export enum FloorplanTypes {
  FETCH = '@@floorplans/FETCH',
  FETCH_SUCCEEDED = '@@floorplans/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@floorplans/FETCH_FAILED',

  CREATE = '@@floorplans/CREATE',
  CREATE_SUCCEEDED = '@@floorplans/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@floorplans/CREATE_FAILED',

  UPDATE = '@@floorplans/UPDATE',
  UPDATE_SUCCEEDED = '@@floorplans/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@floorplans/UPDATE_FAILED',

  DELETE = '@@floorplans/DELETE',
  DELETE_SUCCEEDED = '@@floorplans/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@floorplans/DELETE_FAILED',

  WS_CREATE = '@@floorplans/WS_CREATE',
  WS_UPDATE = '@@floorplans/WS_UPDATE',
  WS_DELETE = '@@floorplans/WS_DELETE',
}

interface IFetchFloorplan {
  id: string;
}

interface IFloorplanUpdated {
  warehouseId: string;
  floorplan: IncompleteFloorplan | Floorplan;
  zones: Zones;
}

export interface ICreateFloorplan {
  warehouseId: string;
  properties: {
    boundaries?: IDrawDefinition[];
    geoMapping?: IGeoMapping[];
    exteriorBoundaries?: IDrawDefinition[];
    image?: File;
    name: string;
    obstacles?: IDrawDefinition[];
    racks?: IDrawDefinition[];
    scale?: number;
    transformationMatrix?: TransformationMatrix2D;
  };
  intl: IntlShape;
}

export interface IUpdateFloorplanProps {
  name?: string;
  boundaries?: IDrawDefinition[];
  enuTransformationMatrix?: TransformationMatrix2D;
  exteriorBoundaries?: IDrawDefinition[];
  geoMapping?: IGeoMapping[];
  geodeticCenterLatitude?: number;
  geodeticCenterLongitude?: number;
  itemLocations?: ItemLocation[];
  obstacles?: IDrawDefinition[];
  racks?: IDrawDefinition[];
  landmarks?: IDrawDefinition[];
  scale?: number;
  image?: File;
  transformationMatrix?: TransformationMatrix2D;
  anchorType?: 'wired' | 'battery';
  tracking?: 'indoor' | 'outdoor';
  connectivity?: 'wifi' | 'cellular';
  targetNumberOfForklifts?: number;
  floorplanArea?: number;
  aisleLength?: number;
  numberOfAisles?: number;
  openAreasSpace?: number[];
  hidden?: boolean;
}

export interface IDeleteFloorplanRsp {
  warehouseId: string;
  floorplanId: string;
}

export interface IDeleteFloorplan extends IDeleteFloorplanRsp {
  intl: IntlShape;
}

interface IWSFloorplanUpdated {
  floorplan: IncompleteFloorplan | Floorplan;
}

interface IWSFloorplanDelete {
  floorplanId: string;
  warehouseId: string;
}

export const fetchFloorplan = createAsyncAction(
  FloorplanTypes.FETCH,
  FloorplanTypes.FETCH_SUCCEEDED,
  FloorplanTypes.FETCH_FAILED
)<IFetchFloorplan, INormalizedIncompleteFloorplan, IFailed>();

export const createFloorplan = createAsyncAction(
  FloorplanTypes.CREATE,
  FloorplanTypes.CREATE_SUCCEEDED,
  FloorplanTypes.CREATE_FAILED
)<ICreateFloorplan, IFloorplanUpdated, IFeedbackFailed>();

export interface IUpdateFloorplan {
  warehouseId: string;
  floorplanId: string;
  properties: IUpdateFloorplanProps;
  intl: IntlShape;
  shallow?: boolean;
}

interface IFloorplanUpdatedWithFeedback extends IFloorplanUpdated {
  intl: IntlShape;
  shallow?: boolean;
}

export const updateFloorplan = createAsyncAction(
  FloorplanTypes.UPDATE,
  FloorplanTypes.UPDATE_SUCCEEDED,
  FloorplanTypes.UPDATE_FAILED
  // eslint-disable-next-line prettier/prettier
)<IUpdateFloorplan, IFloorplanUpdatedWithFeedback, IFeedbackFailedWithShallow>();

export const deleteFloorplan = createAsyncAction(
  FloorplanTypes.DELETE,
  FloorplanTypes.DELETE_SUCCEEDED,
  FloorplanTypes.DELETE_FAILED
)<IDeleteFloorplan, IDeleteFloorplanRsp, IFeedbackFailed>();

export const wsCreateFloorplan = createAction(
  FloorplanTypes.WS_CREATE,
  (data: IWSFloorplanUpdated) => data
)();
export const wsUpdateFloorplan = createAction(
  FloorplanTypes.WS_UPDATE,
  (data: IWSFloorplanUpdated) => data
)();
export const wsDeleteFloorplan = createAction(
  FloorplanTypes.WS_DELETE,
  (data: IWSFloorplanDelete) => data
)();
