import Axios from 'axios';
import Config from 'react-native-config';

const axios = Axios.create({
  baseURL: Config.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  headers: { 'Cache-Control': 'no-cache' },
  timeout: 120000,
  withCredentials: true,
});

export default axios;
