import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/tasks';
import {
  createTask,
  deleteTask,
  fetchTasks,
  updateTask,
} from '@api/tasks';
import { treatFetchError } from '@api/utils';
import { Task, Tasks } from '@models/Task';

export function* deleteTaskSaga(
  action: ActionType<typeof types.deleteTask.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteTask, id);

    yield put(types.deleteTask.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteTask.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* createTaskSaga(
  action: ActionType<typeof types.createTask.request>
) {
  const {
    intl,
    warehouseId,
    floorplanId,
    zoneId,
    name,
    notes,
    priority,
    status,
    type,
    dueDate,
    pictures,
    employees,
    teams,
    assets,
    subtasks,
    positionX,
    positionY,
    positionZ,
  } = action.payload;

  try {
    const task: Task = yield call(
      createTask,
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes,
      priority,
      status,
      type,
      dueDate,
      pictures,
      employees,
      teams,
      assets,
      subtasks,
      positionX,
      positionY,
      positionZ,
    );

    yield put(types.createTask.success({ task, intl }));
  } catch (error: any) {
    yield put(
      types.createTask.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchTasksSaga(
  action: ActionType<typeof types.fetchTasks.request>
) {
  const { shallow } = action.payload;

  try {
    const tasks: Tasks = yield call(fetchTasks);

    yield put(types.fetchTasks.success({ tasks, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchTasks.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* updateTaskSaga(
  action: ActionType<typeof types.updateTask.request>
) {
  const {
    id,
    intl,
    warehouseId,
    floorplanId,
    zoneId,
    name,
    notes,
    priority,
    status,
    type,
    dueDate,
    pictures,
    employees,
    teams,
    assets,
    subtasks,
    positionX,
    positionY,
    positionZ,
  } = action.payload;

  try {
    const task: Task = yield call(
      updateTask,
      id,
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes,
      priority,
      status,
      type,
      dueDate,
      pictures,
      employees,
      teams,
      assets,
      subtasks,
      positionX,
      positionY,
      positionZ,
    );

    yield put(types.updateTask.success({ task, intl }));
  } catch (error: any) {
    yield put(
      types.updateTask.failure({ error: treatFetchError(error), intl })
    );
  }
}
