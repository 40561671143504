import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/sensors';
import { ISensorData } from '@api/websocket';
import Sensor, { Sensors } from '@models/Sensor';
import { SensorGroups } from '@models/SensorGroup';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import { SensorsWithStatus } from '@models/SensorWithStatus';

type SensorsAction = ActionType<typeof types | typeof auth>;

interface ISensorGroups {
  error?: object;
  loading: boolean;
  sensorGroups?: SensorGroups;
}
interface ISensorGroupWithStatus {
  error?: object;
  loading: boolean;
  sensorGroup?: SensorGroupWithStatus;
}

interface ISensorsContainer {
  byFloorplan: Record<string, ISensors>;
  bySensor: Record<string, ISensors>;
}

interface ISensors {
  error?: object;
  loading: boolean;
  sensors?: Sensors;
}

interface ISensorsWithStatus {
  error?: object;
  loading: boolean;
  sensors?: SensorsWithStatus;
}

export interface IIntervalState {
  assetIds: string[];
  erroredAttempts?: number;
  lastErrorTs?: number;
  loading: boolean;
}
interface ISensorDataState {
  data: Record<string, ISensorData[]>;
  error?: object;
  intervals: Record<string, IIntervalState>;
  loading: boolean;
}

export interface ISensorsState {
  readonly groups: Record<string, ISensorGroups>;
  readonly groupsWithStatus: Record<string, ISensorGroupWithStatus>;
  readonly sensorData: Record<string, ISensorDataState>;
  readonly sensors: ISensorsContainer;
  readonly sensorsWithStatus: Record<string, ISensorsWithStatus>;
  readonly form: {
    error?: object;
    loading: boolean;
    sensorId?: string;
  };
}

export const initialState: ISensorsState = {
  groups: {},
  groupsWithStatus: {},
  sensorData: {},
  sensors: {
    byFloorplan: {},
    bySensor: {},
  },
  sensorsWithStatus: {},
  form: { loading: false },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ISensorsState = initialState,
  action: SensorsAction
): ISensorsState => {
  switch (action.type) {
    case getType(types.fetchFloorplanSensors.request): {
      const { floorplanId, shallow } = action.payload;
      const { sensors } = state;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          byFloorplan: {
            ...state.sensors.byFloorplan,
            [floorplanId]: {
              loading:
                shallow !== true
                  ? true
                  : (sensors.byFloorplan[floorplanId] || {}).loading,
            },
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensors.success): {
      const { floorplanId, sensors, shallow } = action.payload;

      const sensorList: Record<string, Sensor> = {};
      const receivedSensors: Sensor[] = Object.values(sensors);
      receivedSensors.forEach((s: Sensor) => {
        sensorList[s.id] = s;
      });

      return {
        ...state,
        sensors: {
          ...state.sensors,
          byFloorplan: {
            ...state.sensors.byFloorplan,
            [floorplanId]: {
              error: undefined,
              loading:
                shallow !== true
                  ? false
                  : (state.sensors.byFloorplan[floorplanId] || {}).loading,
              sensors,
            },
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensors.failure): {
      const { floorplanId, error, shallow } = action.payload;
      const { sensors } = state;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          byFloorplan: {
            ...state.sensors.byFloorplan,
            [floorplanId]: {
              error,
              loading:
                shallow !== true
                  ? false
                  : (sensors.byFloorplan[floorplanId] || {}).loading,
            },
          },
        },
      };
    }

    case getType(types.fetchSensorInfo.request): {
      const { sensorId } = action.payload;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          bySensor: {
            ...state.sensors.bySensor,
            [sensorId]: {
              loading: true,
            },
          },
        },
      };
    }

    case getType(types.fetchSensorInfo.success): {
      const { sensor } = action.payload;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          bySensor: {
            ...state.sensors.bySensor,
            [sensor.id]: {
              error: undefined,
              loading: false,
              sensors: {
                [sensor.id]: sensor,
              },
            },
          },
        },
      };
    }

    case getType(types.fetchSensorInfo.failure): {
      const { sensorId, error } = action.payload;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          bySensor: {
            ...state.sensors.bySensor,
            [sensorId]: {
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(types.fetchSensorGroupsByFloorplan.request): {
      const { floorplanId, shallow } = action.payload;
      const { groups } = state;

      return {
        ...state,
        groups: {
          ...state.groups,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true ? true : (groups[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchSensorGroupsByFloorplan.success): {
      const { floorplanId, sensorGroups, shallow } = action.payload;
      const { groups } = state;

      return {
        ...state,
        groups: {
          ...state.groups,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true ? false : (groups[floorplanId] || {}).loading,
            sensorGroups,
          },
        },
      };
    }

    case getType(types.fetchSensorGroupsByFloorplan.failure): {
      const { floorplanId, error, shallow } = action.payload;
      const { groups } = state;

      return {
        ...state,
        groups: {
          ...state.groups,
          [floorplanId]: {
            error,
            loading:
              shallow !== true ? false : (groups[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorsStatus.request): {
      const { floorplanId, shallow } = action.payload;
      const { sensorsWithStatus } = state;

      return {
        ...state,
        sensorsWithStatus: {
          ...state.sensorsWithStatus,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true
                ? true
                : (sensorsWithStatus[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorsStatus.success): {
      const { floorplanId, sensors, shallow } = action.payload;
      const { sensorsWithStatus } = state;

      return {
        ...state,
        sensorsWithStatus: {
          ...state.sensorsWithStatus,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true
                ? false
                : (sensorsWithStatus[floorplanId] || {}).loading,
            sensors,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorsStatus.failure): {
      const { floorplanId, error, shallow } = action.payload;
      const { sensorsWithStatus } = state;

      return {
        ...state,
        sensorsWithStatus: {
          ...state.sensorsWithStatus,
          [floorplanId]: {
            error,
            loading:
              shallow !== true
                ? false
                : (sensorsWithStatus[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorGroupStatus.request): {
      const { floorplanId, shallow } = action.payload;
      const { groupsWithStatus } = state;

      return {
        ...state,
        groupsWithStatus: {
          ...state.groupsWithStatus,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true
                ? true
                : (groupsWithStatus[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorGroupStatus.success): {
      const { floorplanId, sensorGroup, shallow } = action.payload;
      const { groupsWithStatus } = state;

      return {
        ...state,
        groupsWithStatus: {
          ...state.groupsWithStatus,
          [floorplanId]: {
            error: undefined,
            loading:
              shallow !== true
                ? false
                : (groupsWithStatus[floorplanId] || {}).loading,
            sensorGroup: sensorGroup.id ? sensorGroup : undefined,
          },
        },
      };
    }

    case getType(types.fetchFloorplanSensorGroupStatus.failure): {
      const { floorplanId, error, shallow } = action.payload;
      const { groupsWithStatus } = state;

      return {
        ...state,
        groupsWithStatus: {
          ...state.groupsWithStatus,
          [floorplanId]: {
            error,
            loading:
              shallow !== true
                ? false
                : (groupsWithStatus[floorplanId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchSensorsData.request): {
      const { assetIds, floorplanId, timestamp } = action.payload;

      return {
        ...state,
        sensorData: {
          ...state.sensorData,
          [floorplanId]: {
            ...(state.sensorData[floorplanId] || {}),
            error: undefined,
            intervals: {
              ...((state.sensorData[floorplanId] || {}).intervals || {}),
              [timestamp]: {
                ...(((state.sensorData[floorplanId] || {}).intervals || {})[
                  timestamp
                ] || {}),
                assetIds: assetIds.concat(
                  (
                    ((state.sensorData[floorplanId] || {}).intervals || {})[
                      timestamp
                    ] || {}
                  ).assetIds || []
                ),
                loading: true,
              },
            },
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchSensorsData.success): {
      const { floorplanId, data, timestamp } = action.payload;

      const stateData = (state.sensorData[floorplanId] || {}).data || {};
      Object.keys(data).forEach((k: any) => {
        stateData[k] = data[k];
      });

      return {
        ...state,
        sensorData: {
          ...state.sensorData,
          [floorplanId]: {
            ...state.sensorData[floorplanId],
            data: stateData,
            intervals: {
              ...((state.sensorData[floorplanId] || {}).intervals || {}),
              [timestamp]: {
                assetIds: (
                  ((state.sensorData[floorplanId] || {}).intervals || {})[
                    timestamp
                  ] || {}
                ).assetIds,
                loading: false,
              },
            },
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchSensorsData.failure): {
      const { assetIds, floorplanId, error, timestamp, lastErrorTs } =
        action.payload;

      return {
        ...state,
        sensorData: {
          ...state.sensorData,
          [floorplanId]: {
            ...state.sensorData[floorplanId],
            error,
            intervals: {
              ...((state.sensorData[floorplanId] || {}).intervals || {}),
              [timestamp]: {
                ...((state.sensorData[floorplanId] || {}).intervals || {})[
                  timestamp
                ],
                assetIds: (
                  ((state.sensorData[floorplanId] || {}).intervals || {})[
                    timestamp
                  ].assetIds || []
                ).filter((assetId) => assetIds.indexOf(assetId) !== -1),
                erroredAttempts:
                  ((
                    ((state.sensorData[floorplanId] || {}).intervals || {})[
                      timestamp
                    ] || {}
                  ).erroredAttempts || 0) + 1,
                lastErrorTs,
                loading: false,
              },
            },
            loading: false,
          },
        },
      };
    }

    case getType(types.createSensor.request): {
      return {
        ...state,
        form: {
          error: undefined,
          loading: true,
        },
      };
    }

    case getType(types.createSensor.success): {
      const { sensor } = action.payload;

      return {
        ...state,
        form: {
          error: undefined,
          loading: false,
          sensorId: sensor.id,
        },
      };
    }

    case getType(types.createSensor.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          error,
          loading: false,
        },
      };
    }

    case getType(types.wsCreateSensor): {
      const { sensor } = action.payload;
      const { floorplanId } = sensor;

      return {
        ...state,
        sensors: {
          ...state.sensors,
          byFloorplan: {
            ...state.sensors.byFloorplan,
            [floorplanId]: {
              ...(state.sensors.byFloorplan[floorplanId] || {}),
              sensors: {
                ...(state.sensors.byFloorplan[floorplanId] || {}).sensors,
                [sensor.id]: sensor,
              },
            },
          },
        },
      };
    }
    
    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
