import { all, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/warehouses';
import { treatFetchError } from '@api/utils';
import {
  createWarehouse,
  deleteWarehouse,
  fetchWarehouses,
  updateWarehouse,
} from '@api/warehouses';
import {
  INormalizedWarehouse,
  INormalizedWarehouses,
} from '@api/warehousesUtils';

export function* fetchWarehousesSaga(
  action: ActionType<typeof types.fetchWarehouses.request>
) {
  const { shallow } = action.payload;

  try {
    const { warehouses, floorplans, zones }: INormalizedWarehouses = yield call(
      fetchWarehouses
    );

    yield put(
      types.fetchWarehouses.success({
        floorplans,
        warehouses,
        zones,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchWarehouses.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* createWarehouseSaga(
  action: ActionType<typeof types.createWarehouse.request>
) {
  const {
    name,
    site,
    timezone,
    country,
    metricsTotalPalletsMoved,
    metricsFleetUsedCapacity,
    numberOfShifts,
    shiftHours,
    workingDays,
    targetForkliftType,
    intl,
  } = action.payload;

  try {
    const { warehouse, floorplans, zones }: INormalizedWarehouse = yield call(
      createWarehouse,
      {
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      }
    );

    yield put(
      types.createWarehouse.success({
        floorplans,
        intl,
        warehouse,
        zones,
      })
    );
  } catch (error: any) {
    yield put(
      types.createWarehouse.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* updateWarehouseSaga(
  action: ActionType<typeof types.updateWarehouse.request>
) {
  const {
    id: warehouseId,
    name,
    site,
    timezone,
    country,
    metricsTotalPalletsMoved,
    metricsFleetUsedCapacity,
    numberOfShifts,
    shiftHours,
    workingDays,
    targetForkliftType,
    intl,
  } = action.payload;

  try {
    const { warehouse, floorplans, zones }: INormalizedWarehouse = yield call(
      updateWarehouse,
      warehouseId,
      {
        name,
        site,
        timezone,
        country,
        metricsTotalPalletsMoved,
        metricsFleetUsedCapacity,
        numberOfShifts,
        shiftHours,
        workingDays,
        targetForkliftType,
      }
    );

    yield put(
      types.updateWarehouse.success({
        floorplans,
        intl,
        warehouse,
        zones,
      })
    );
  } catch (error: any) {
    yield put(
      types.updateWarehouse.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deleteWarehouseSaga(
  action: ActionType<typeof types.deleteWarehouse.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteWarehouse, id);

    yield put(types.deleteWarehouse.success({ id }));
  } catch (error: any) {
    yield put(
      types.deleteWarehouse.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deleteAllWarehousesSaga(
  action: ActionType<typeof types.deleteAllWarehouses.request>
) {
  const { ids, intl } = action.payload;

  try {
    yield all(ids.map((id: string) => call(deleteWarehouse, id)));

    yield put(types.deleteAllWarehouses.success());
  } catch (error: any) {
    yield put(
      types.deleteAllWarehouses.failure({ error: treatFetchError(error), intl })
    );
  }
}
