import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/scans';
import { createScan } from '@api/scans';
import { treatFetchError } from '@api/utils';
import Scan from '@models/Scan';

export function* createScanSaga(
  action: ActionType<typeof types.createScan.request>
) {
  const { scan, type, x, y, z } = action.payload;

  try {
    const data: Scan = yield call(
      createScan,
      scan,
      type,
      x,
      y,
      z,
    );

    yield put(types.createScan.success({ scan: data }));
  } catch (error: any) {
    yield put(
      types.createScan.failure({
        error: treatFetchError(error),
      })
    );
  }
}
