import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/tickets';
import FetchError from '@models/FetchError';
import { Tickets } from '@models/Ticket';

type Actions = ActionType<typeof types | typeof auth>;

interface IForm {
  loading: boolean;
  error?: FetchError;
  redirect: boolean;
}
export interface ITicketsState {
  readonly error?: FetchError;
  readonly form: IForm;
  readonly loading: boolean;
  readonly tickets: Tickets;
}

export const initialState: ITicketsState = {
  error: undefined,
  loading: false,
  form: { loading: false, redirect: false },
  tickets: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ITicketsState = initialState,
  action: Actions
): ITicketsState => {
  switch (action.type) {
    case getType(types.createTicket.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
          redirect: false,
        },
      };

    case getType(types.createTicket.success): {
      const { ticket } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirect: true,
        },
        tickets: {
          ...state.tickets,
          [ticket.id]: ticket,
        },
      };
    }

    case getType(types.createTicket.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.fetchTickets.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchTickets.success): {
      return {
        ...state,
        error: undefined,
        loading: false,
        tickets: action.payload,
      };
    }

    case getType(types.fetchTickets.failure):
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case getType(types.updateTicket.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
          redirect: false,
        },
      };

    case getType(types.updateTicket.success): {
      const { ticket } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirect: true,
        },
        tickets: {
          ...state.tickets,
          [ticket.id]: ticket,
        },
      };
    }

    case getType(types.updateTicket.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.archiveTicket.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
          redirect: false,
        },
      };

    case getType(types.archiveTicket.success): {
      const { ticket } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirect: true,
        },
        tickets: omit(state.tickets, ticket.id),
      };
    }

    case getType(types.archiveTicket.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.deleteTicket.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
          redirect: false,
        },
      };

    case getType(types.deleteTicket.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirect: true,
        },
        tickets: omit(state.tickets, id),
      };
    }

    case getType(types.deleteTicket.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.createTicketComment.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createTicketComment.success): {
      const { comment } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
        },
        tickets: {
          ...state.tickets,
          [comment.ticketId]: {
            ...(state.tickets[comment.ticketId] || {}),
            comments: {
              ...((state.tickets[comment.ticketId] || {}).comments || {}),
              [comment.id]: comment,
            },
          },
        },
      };
    }

    case getType(types.createTicketComment.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.deleteTicketComment.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.deleteTicketComment.success): {
      const { ticketId, id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
        },
        tickets: {
          ...state.tickets,
          [ticketId]: {
            ...(state.tickets[ticketId] || {}),
            comments: omit((state.tickets[ticketId] || {}).comments, id),
          },
        },
      };
    }

    case getType(types.deleteTicketComment.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.wsUpdateTicket):
    case getType(types.wsCreateTicket): {
      const { ticket } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          [ticket.id]: ticket,
        },
      };
    }

    case getType(types.wsDeleteTicket):
      return {
        ...state,
        tickets: omit(state.tickets, action.payload.id),
      };

    case getType(types.wsCreateTicketComment): {
      const { comment } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          [comment.ticketId]: {
            ...(state.tickets[comment.ticketId] || {}),
            comments: {
              ...((state.tickets[comment.ticketId] || {}).comments || {}),
              [comment.id]: comment,
            },
          },
        },
      };
    }

    case getType(types.wsDeleteTicketComment): {
      const { ticketId, id } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ...state.tickets,
          [ticketId]: {
            ...(state.tickets[ticketId] || {}),
            comments: omit((state.tickets[ticketId] || {}).comments, id),
          },
        },
      };
    }

    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
