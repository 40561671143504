import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as notificationTypes from '@actions/notifications';
import * as types from '@actions/rules';
import FetchError from '@models/FetchError';
import { Rule, Rules } from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';

type Actions = ActionType<
  typeof types | typeof auth | typeof notificationTypes
>;

interface IRuleForm {
  active: boolean;
  data?: Rule;
  error?: FetchError;
  loading: boolean;
}

interface IRuleAlerts {
  error?: FetchError;
  loading: boolean;
  floorplanId: string;
  data?: types.RuleAlerts;
}

export interface IRulesState {
  readonly error?: FetchError;
  readonly rules: Rules;
  readonly loading: boolean;
  readonly templates: RuleTemplates;
  readonly templatesLoading: boolean;
  readonly templatesError?: FetchError;
  readonly form: IRuleForm;
  readonly ruleAlerts: Record<string, IRuleAlerts>;
}

export const initialState: IRulesState = {
  error: undefined,
  form: {
    active: false,
    loading: false,
  },
  loading: false,
  ruleAlerts: {},
  rules: {},
  templates: {},
  templatesLoading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IRulesState = initialState,
  action: Actions
): IRulesState => {
  switch (action.type) {
    case getType(types.activateRule.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.activateRule.success):
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.id]: {
            ...state.rules[action.payload.id],
            enabled: true,
          },
        },
        error: undefined,
        loading: false,
      };

    case getType(types.activateRule.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.deactivateRule.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deactivateRule.success):
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.id]: {
            ...state.rules[action.payload.id],
            enabled: false,
          },
        },
        error: undefined,
        loading: false,
      };

    case getType(types.deactivateRule.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.fetchTemplates.request): {
      const { shallow } = action.payload;
      const { templatesLoading } = state;

      return {
        ...state,
        templatesError: undefined,
        templatesLoading: shallow !== true ? true : templatesLoading,
      };
    }

    case getType(types.fetchTemplates.success): {
      const { templates, shallow } = action.payload;
      const { templatesLoading } = state;

      return {
        ...state,
        templates,
        templatesError: undefined,
        templatesLoading: shallow !== true ? false : templatesLoading,
      };
    }

    case getType(types.fetchTemplates.failure): {
      const { error, shallow } = action.payload;
      const { templatesLoading } = state;

      return {
        ...state,
        templatesError: error,
        templatesLoading: shallow !== true ? false : templatesLoading,
      };
    }

    case getType(types.fetchRules.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchRules.success): {
      const { rules, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? false : loading,
        rules,
      };
    }

    case getType(types.fetchRules.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchRuleAlerts.request): {
      const { filterId, floorplanId } = action.payload;

      return {
        ...state,
        ruleAlerts: {
          ...state.ruleAlerts,
          [filterId]: {
            ...(state.ruleAlerts[filterId] || {}),
            floorplanId,
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchRuleAlerts.success): {
      const { filterId, ruleAlerts } = action.payload;

      return {
        ...state,
        ruleAlerts: {
          ...state.ruleAlerts,
          [filterId]: {
            ...(state.ruleAlerts[filterId] || {}),
            data: ruleAlerts,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchRuleAlerts.failure): {
      const { filterId, error } = action.payload;

      return {
        ...state,
        ruleAlerts: {
          ...state.ruleAlerts,
          [filterId]: {
            ...(state.ruleAlerts[filterId] || {}),
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.createRule.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createRule.success):
      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          loading: false,
        },
        rules: { ...state.rules, [action.payload.id]: action.payload },
      };

    case getType(types.createRule.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.updateRule.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateRule.success):
      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          loading: false,
        },
        rules: { ...state.rules, [action.payload.id]: action.payload },
      };

    case getType(types.updateRule.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.deleteRule.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.deleteRule.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteRule.success):
      return {
        ...state,
        error: undefined,
        loading: false,
        rules: omit(state.rules, action.payload.id),
      };

    case getType(notificationTypes.handleNotification): {
      const { type, notification } = action.payload;
      const { ruleAlerts } = state;

      Object.keys(ruleAlerts).forEach((key) => {
        if (
          type === 'alerts' &&
          ruleAlerts[key].floorplanId === notification.floorplanId
        ) {
          ruleAlerts[key].data = {
            ...(ruleAlerts[key].data || { returned: 0, total: 0 }),
            list: [
              ...((ruleAlerts[key].data || {}).list || []),
              { ...notification, dismissed: false },
            ],
            returned: 1,
            total: 1,
          };
        }
      });

      return { ...state, ruleAlerts };
    }

    case getType(notificationTypes.dismissNotifications.success): {
      const dismissedNotifications = action.payload;
      const { alerts } = dismissedNotifications;
      const { ruleAlerts } = state;

      Object.keys(ruleAlerts).forEach((key) => {
        const data = [...(((ruleAlerts[key] || {}).data || {}).list || [])];

        alerts.forEach((alert) => {
          const notificationIndex = data.findIndex((n) => n.id === alert.id);

          if (notificationIndex !== -1) {
            ruleAlerts[key].data!.list[notificationIndex].dismissed = true;
          }
        });
      });

      return { ...state, ruleAlerts };
    }

    case getType(notificationTypes.unreadNotifications.success): {
      const unreadNotifications = action.payload;
      const { alerts } = unreadNotifications;
      const { ruleAlerts } = state;

      Object.keys(ruleAlerts).forEach((key) => {
        const data = [...(((ruleAlerts[key] || {}).data || {}).list || [])];

        alerts.forEach((alert) => {
          const notificationIndex = data.findIndex((n) => n.id === alert.id);

          if (notificationIndex !== -1) {
            ruleAlerts[key].data!.list[notificationIndex].dismissed = false;
          }
        });
      });

      return { ...state, ruleAlerts };
    }

    case getType(types.openRuleForm):
      return {
        ...state,
        form: {
          active: true,
          data: action.payload,
          loading: false,
        },
      };

    case getType(types.closeRuleForm):
      return {
        ...state,
        form: {
          active: false,
          data: undefined,
          loading: false,
        },
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
