import { createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import FetchError from '@models/FetchError';
import { DefaultMetricAggregation } from '@models/MetricMeta';
import ROIReport from '@models/ROIReport';

export enum ReportsTypes {
  FETCH_REPORT = '@@reports/FETCH_REPORT',
  FETCH_REPORT_SUCCEEDED = '@@reports/FETCH_REPORT_SUCCEEDED',
  FETCH_REPORT_FAILED = '@@reports/FETCH_REPORT_FAILED',

  FETCH_ROI_REPORT = '@@reports/FETCH_ROI_REPORT',
  FETCH_ROI_REPORT_SUCCEEDED = '@@reports/FETCH_ROI_REPORT_SUCCEEDED',
  FETCH_ROI_REPORT_FAILED = '@@reports/FETCH_ROI_REPORT_FAILED',
}

// eslint-disable-next-line prettier/prettier
export type IFetchReportData = Record<string, { others: Record<string, boolean>; maps: { id: string, type: string }[]; aggregations: DefaultMetricAggregation[] }>;

export interface IFetchReport {
  filterId: string;

  floorplanId: string;
  warehouseId: string;

  locale: string;
  measurementUnits: string;

  assetIds?: string[];
  executiveSummary?: string;
  data: IFetchReportData;
  sensorPlacement?: boolean;

  endDate: number;
  startDate: number;

  intl: IntlShape;
}

export interface IFetchROIReport {
  filterId: string;
  intl: IntlShape;
  roiData: ROIReport;
}

export interface IRequestSucceed {
  filterId: string;
  report: object;
}

export interface IRequestFailed {
  filterId: string;
  error: FetchError;
  intl: IntlShape;
}

export const fetchReport = createAsyncAction(
  ReportsTypes.FETCH_REPORT,
  ReportsTypes.FETCH_REPORT_SUCCEEDED,
  ReportsTypes.FETCH_REPORT_FAILED
)<IFetchReport, IRequestSucceed, IRequestFailed>();

export const fetchROIReport = createAsyncAction(
  ReportsTypes.FETCH_ROI_REPORT,
  ReportsTypes.FETCH_ROI_REPORT_SUCCEEDED,
  ReportsTypes.FETCH_ROI_REPORT_FAILED
)<IFetchROIReport, IRequestSucceed, IRequestFailed>();
