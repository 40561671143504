import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/plans';
import { createPlan, deletePlan, fetchPlans, updatePlan } from '@api/plans';
import { treatFetchError } from '@api/utils';
import Plan, { Plans } from '@models/Plan';

export function* createPlanSaga(
  action: ActionType<typeof types.createPlan.request>
) {
  const { intl, name, floorplanId, plan } = action.payload;

  try {
    const planData: Plan = yield call(createPlan, name, floorplanId, plan);

    yield put(types.createPlan.success(planData));
  } catch (error: any) {
    yield put(
      types.createPlan.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deletePlanSaga(
  action: ActionType<typeof types.deletePlan.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deletePlan, id);

    yield put(types.deletePlan.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deletePlan.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchPlansSaga(
  action: ActionType<typeof types.fetchPlans.request>
) {
  const { floorplanId } = action.payload;

  try {
    const plans: Plans = yield call(fetchPlans, floorplanId);

    yield put(types.fetchPlans.success({ plans }));
  } catch (error: any) {
    yield put(types.fetchPlans.failure({ error: treatFetchError(error) }));
  }
}

export function* updatePlanSaga(
  action: ActionType<typeof types.updatePlan.request>
) {
  const { id, intl, name, floorplanId, plan } = action.payload;

  try {
    const planData: Plan = yield call(updatePlan, id, name, floorplanId, plan);

    yield put(types.updatePlan.success(planData));
  } catch (error: any) {
    yield put(
      types.updatePlan.failure({ error: treatFetchError(error), intl })
    );
  }
}
