import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Account, { IAccountMeta, ReportConfig } from '@models/Account';
import { IFailed, IFeedbackFailed } from './types';

export enum AuthTypes {
  FETCH = '@@authentication/FETCH',
  FETCH_FAILED = '@@authentication/FETCH_FAILED',
  FETCH_SUCCEEDED = '@@authentication/FETCH_SUCCEEDED',

  PASSWORD_RECOVERY = '@@authentication/PASSWORD_RECOVERY',
  PASSWORD_RECOVERY_FAILED = '@@authentication/PASSWORD_RECOVERY_FAILED',
  PASSWORD_RECOVERY_SUCCEEDED = '@@authentication/PASSWORD_RECOVERY_SUCCEEDED',

  PASSWORD_RESET = '@@authentication/PASSWORD_RESET',
  PASSWORD_RESET_FAILED = '@@authentication/PASSWORD_RESET_FAILED',
  PASSWORD_RESET_SUCCEEDED = '@@authentication/PASSWORD_RESET_SUCCEEDED',

  LOGIN = '@@authentication/LOGIN',
  LOGIN_FAILED = '@@authentication/LOGIN_FAILED',
  LOGIN_SUCCEEDED = '@@authentication/LOGIN_SUCCEEDED',

  CHANGE_MODE = '@@authentication/CHANGE_MODE',
  CHANGE_MODE_FAILED = '@@authentication/CHANGE_MODE_FAILED',
  CHANGE_MODE_SUCCEEDED = '@@authentication/CHANGE_MODE_SUCCEEDED',

  CLEAR_LOGIN = '@@authentication/CLEAR_LOGIN',

  LOGOUT = '@@authentication/LOGOUT',
  LOGOUT_FAILED = '@@authentication/LOGOUT_FAILED',
  LOGOUT_SUCCEEDED = '@@authentication/LOGOUT_SUCCEEDED',

  SIGN_UP = '@@authentication/SIGN_UP',
  SIGN_UP_FAILED = '@@authentication/SIGN_UP_FAILED',
  SIGN_UP_SUCCEEDED = '@@authentication/SIGN_UP_SUCCEEDED',

  VERIFICATION = '@@authentication/VERIFICATION',
  VERIFICATION_FAILED = '@@authentication/VERIFICATION_FAILED',
  VERIFICATION_SUCCEEDED = '@@authentication/VERIFICATION_SUCCEEDED',

  SETUP = '@@authentication/SETUP',
  SETUP_FAILED = '@@authentication/SETUP_FAILED',
  SETUP_SUCCEEDED = '@@authentication/SETUP_SUCCEEDED',

  UPDATE_ACCOUNT_SETTINGS = '@@authentication/UPDATE_ACCOUNT_SETTINGS',
  UPDATE_ACCOUNT_SETTINGS_FAILED = '@@authentication/UPDATE_ACCOUNT_SETTINGS_FAILED',
  UPDATE_ACCOUNT_SETTINGS_SUCCEEDED = '@@authentication/UPDATE_ACCOUNT_SETTINGS_SUCCEEDED',

  UPDATE_ACCOUNT_CONTACTS = '@@authentication/UPDATE_ACCOUNT_CONTACTS',
  UPDATE_ACCOUNT_CONTACTS_SUCCEEDED = '@@authentication/UPDATE_ACCOUNT_CONTACTS_SUCCEEDED',
  UPDATE_ACCOUNT_CONTACTS_FAILED = '@@authentication/UPDATE_ACCOUNT_CONTACTS_FAILED',

  UPDATE_ACCOUNT_PASSWORD = '@@authentication/UPDATE_ACCOUNT_PASSWORD',
  UPDATE_ACCOUNT_PASSWORD_FAILED = '@@authentication/UPDATE_ACCOUNT_PASSWORD_FAILED',
  UPDATE_ACCOUNT_PASSWORD_SUCCEEDED = '@@authentication/UPDATE_ACCOUNT_PASSWORD_SUCCEEDED',

  UPDATE_ACCOUNT_REPORT = '@@authentication/UPDATE_ACCOUNT_REPORT',
  UPDATE_ACCOUNT_REPORT_FAILED = '@@authentication/UPDATE_ACCOUNT_REPORT_FAILED',
  UPDATE_ACCOUNT_REPORT_SUCCEEDED = '@@authentication/UPDATE_ACCOUNT_REPORT_SUCCEEDED',

  DELETE_ACCOUNT = '@@authentication/DELETE_ACCOUNT',
  DELETE_ACCOUNT_FAILED = '@@authentication/DELETE_ACCOUNT_FAILED',
  DELETE_ACCOUNT_SUCCEEDED = '@@authentication/DELETE_ACCOUNT_SUCCEEDED',

  UPDATE_USER = '@@authentication/UPDATE_USER',

  CLOSE_MODE_SELECTOR = '@@authentication/CLOSE_MODE_SELECTOR',
  OPEN_MODE_SELECTOR = '@@authentication/OPEN_MODE_SELECTOR',
}

interface ISignUp {
  email: string;
  password: string;
  company: string;
  name: string;
}

interface IVerification {
  email: string;
  verification: string;
}

interface ISetup {
  email: string;
  password: string;
  verification: string;
}

export interface IFCMDeviceInfo {
  deviceId: string;
  token: string;
}

interface IChangeMode {
  organisationId: string;
}

export const fetchAccount = createAsyncAction(
  AuthTypes.FETCH,
  AuthTypes.FETCH_SUCCEEDED,
  AuthTypes.FETCH_FAILED
)<void, Account, IFailed>();

export const accountVerification = createAsyncAction(
  AuthTypes.VERIFICATION,
  AuthTypes.VERIFICATION_SUCCEEDED,
  AuthTypes.VERIFICATION_FAILED
)<IVerification, void, IFailed>();

export const accountSetup = createAsyncAction(
  AuthTypes.SETUP,
  AuthTypes.SETUP_SUCCEEDED,
  AuthTypes.SETUP_FAILED
)<ISetup, void, IFailed>();

interface PasswordRecovery {
  email: string;
}

export const passwordRecovery = createAsyncAction(
  AuthTypes.PASSWORD_RECOVERY,
  AuthTypes.PASSWORD_RECOVERY_SUCCEEDED,
  AuthTypes.PASSWORD_RECOVERY_FAILED
)<PasswordRecovery, void, IFailed>();

export interface PasswordReset {
  email: string;
  code: string;
  password: string;
}

export const passwordReset = createAsyncAction(
  AuthTypes.PASSWORD_RESET,
  AuthTypes.PASSWORD_RESET_SUCCEEDED,
  AuthTypes.PASSWORD_RESET_FAILED
)<PasswordReset, void, IFailed>();

interface ILogin {
  email: string;
  password: string;
  fcmDeviceInfo?: IFCMDeviceInfo;
}

export const login = createAsyncAction(
  AuthTypes.LOGIN,
  AuthTypes.LOGIN_SUCCEEDED,
  AuthTypes.LOGIN_FAILED
)<ILogin, void, IFailed>();

export const changeMode = createAsyncAction(
  AuthTypes.CHANGE_MODE,
  AuthTypes.CHANGE_MODE_SUCCEEDED,
  AuthTypes.CHANGE_MODE_FAILED
)<IChangeMode, IChangeMode, IFailed>();

export const logout = createAsyncAction(
  AuthTypes.LOGOUT,
  AuthTypes.LOGOUT_SUCCEEDED,
  AuthTypes.LOGOUT_FAILED
)<void, void, IFailed>();

export const signUp = createAsyncAction(
  AuthTypes.SIGN_UP,
  AuthTypes.SIGN_UP_SUCCEEDED,
  AuthTypes.SIGN_UP_FAILED
)<ISignUp, void, IFailed>();

export interface IUpdateAccountSettings {
  intl: IntlShape;
  properties: {
    image?: string;
    file?: File;
    enableNotifications?: boolean;
    locale?: string;
    measurementUnits?: string;
    showHiddenFloorplans?: boolean;
  };
}

export interface IUpdateAccountContacts {
  intl: IntlShape;
  properties: {
    contactsEmail?: string;
    contactsMobile?: string;
    contactsWhatsapp?: string;
  };
}

export interface IUpdateAccountPassword {
  currentPassword: string;
  password: string;
  intl: IntlShape;
}

interface IUpdateAccountSuccess {
  account: Account;
  intl: IntlShape;
}

interface IUpdateAccountMetaSuccess {
  accountMeta: IAccountMeta;
  intl: IntlShape;
}

export interface IUpdateAccountReport {
  reportConfigs: ReportConfig[];
  intl: IntlShape;
}

export interface IDeleteAccount {
  intl: IntlShape;
}

export const updateAccountSettings = createAsyncAction(
  AuthTypes.UPDATE_ACCOUNT_SETTINGS,
  AuthTypes.UPDATE_ACCOUNT_SETTINGS_SUCCEEDED,
  AuthTypes.UPDATE_ACCOUNT_SETTINGS_FAILED
)<IUpdateAccountSettings, IUpdateAccountMetaSuccess, IFeedbackFailed>();

export const updateAccountContacts = createAsyncAction(
  AuthTypes.UPDATE_ACCOUNT_CONTACTS,
  AuthTypes.UPDATE_ACCOUNT_CONTACTS_SUCCEEDED,
  AuthTypes.UPDATE_ACCOUNT_CONTACTS_FAILED
)<IUpdateAccountContacts, IUpdateAccountMetaSuccess, IFeedbackFailed>();

export const updateAccountPassword = createAsyncAction(
  AuthTypes.UPDATE_ACCOUNT_PASSWORD,
  AuthTypes.UPDATE_ACCOUNT_PASSWORD_SUCCEEDED,
  AuthTypes.UPDATE_ACCOUNT_PASSWORD_FAILED
)<IUpdateAccountPassword, IUpdateAccountSuccess, IFeedbackFailed>();

export const updateAccountReport = createAsyncAction(
  AuthTypes.UPDATE_ACCOUNT_REPORT,
  AuthTypes.UPDATE_ACCOUNT_REPORT_SUCCEEDED,
  AuthTypes.UPDATE_ACCOUNT_REPORT_FAILED
)<IUpdateAccountReport, IUpdateAccountMetaSuccess, IFeedbackFailed>();

export const deleteAccount = createAsyncAction(
  AuthTypes.DELETE_ACCOUNT,
  AuthTypes.DELETE_ACCOUNT_SUCCEEDED,
  AuthTypes.DELETE_ACCOUNT_FAILED
)<IDeleteAccount, void, IFeedbackFailed>();

export const clearLogin = createAction(
  AuthTypes.CLEAR_LOGIN,
)();

export const wsUpdateUser = createAction(
  AuthTypes.UPDATE_USER,
  (user: Account) => user
)();

export const closeModeSelector = createAction(
  AuthTypes.CLOSE_MODE_SELECTOR,
)();
export const openModeSelector = createAction(
  AuthTypes.OPEN_MODE_SELECTOR,
)();
