import { createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { ConfigFile, ConfigFiles } from '@models/ConfigFile';
import { IFailed, IFeedbackFailed } from './types';

export enum ConfigurationsTypes {
  FETCH = '@@configurations/FETCH',
  FETCH_SUCCEEDED = '@@configurations/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@configurations/FETCH_FAILED',

  CREATE = '@@configurations/CREATE',
  CREATE_SUCCEEDED = '@@configurations/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@configurations/CREATE_FAILED',

  UPDATE = '@@configurations/UPDATE',
  UPDATE_SUCCEEDED = '@@configurations/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@configurations/UPDATE_FAILED',

  DELETE = '@@configurations/DELETE',
  DELETE_SUCCEEDED = '@@configurations/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@configurations/DELETE_FAILED',
}

export enum ConfigurationFileTypes {
  PROCESSING = 'processing',
  HARDWARE = 'hardware',
}

interface IFetch {
  type: string;
}

export interface IFetchSuccess {
  configFiles: ConfigFiles;
  type: string;
}

export interface ICreateConfigFile {
  filename: string;
  content: string;
  sensorGroupId?: string;
  type: string;
  warehouseId?: string;
  floorplanId?: string;
  version: number;
}

export interface IConfigFileSuccess extends ConfigFile {
  type: string;
}

export interface IUpdateConfigFile {
  id: string;
  filename: string;
  content: string;
  type: string;
}

interface IFetchFailed extends IFailed {
  type: string;
}
interface IFetchFeedbackFailed extends IFeedbackFailed {
  type: string;
}

export interface IDeleteConfigFile extends IFetch {
  id: string;
  intl: IntlShape;
}
export interface IDeleteConfigFileRsp extends IFetch {
  id: string;
}

export const fetchConfigFiles = createAsyncAction(
  ConfigurationsTypes.FETCH,
  ConfigurationsTypes.FETCH_SUCCEEDED,
  ConfigurationsTypes.FETCH_FAILED
)<IFetch, IFetchSuccess, IFetchFailed>();

export const createConfigFile = createAsyncAction(
  ConfigurationsTypes.CREATE,
  ConfigurationsTypes.CREATE_SUCCEEDED,
  ConfigurationsTypes.CREATE_FAILED
)<ICreateConfigFile, IConfigFileSuccess, IFetchFailed>();

export const updateConfigFile = createAsyncAction(
  ConfigurationsTypes.UPDATE,
  ConfigurationsTypes.UPDATE_SUCCEEDED,
  ConfigurationsTypes.UPDATE_FAILED
)<IUpdateConfigFile, IConfigFileSuccess, IFetchFailed>();

export const deleteConfigFile = createAsyncAction(
  ConfigurationsTypes.DELETE,
  ConfigurationsTypes.DELETE_SUCCEEDED,
  ConfigurationsTypes.DELETE_FAILED
)<IDeleteConfigFile, IDeleteConfigFileRsp, IFetchFeedbackFailed>();
