import { Shift, Shifts } from '@models/Shift';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

// Helpers
function normalizeShift(shifts: Shift[]) {
  return convertArrayToIdMap(shifts);
}

export async function createShift(
  name: string,
  employees: string[],
  periods: Record<number, boolean>,
): Promise<Shift> {
  const { data: shift } = await axios.post(
    '/shifts',
    { name, employees, periods }
  );

  return shift;
}

export async function deleteShift(id: string): Promise<void> {
  await axios.delete(`/shifts/delete/id/${id}`);
}

export async function fetchShifts(): Promise<Shifts> {
  const { data } = await axios.get('/shifts');

  return normalizeShift(data);
}

export async function updateShift(
  id: string,
  name: string,
  employees: string[],
  periods: Record<number, boolean>,
): Promise<void> {
  const { data: asset } = await axios.put(
    '/shifts',
    { id, name, employees, periods }
  );

  return asset;
}
