import { createAction } from 'typesafe-actions';

export enum ActionFeedbackTypes {
  CLOSE = '@@actionFeedback/CLOSE',
}
export const closeNotification = createAction(
  ActionFeedbackTypes.CLOSE,
  (uuid: string) => uuid
)();

export default {};
