import { createAction, createAsyncAction } from 'typesafe-actions';

import Zone from '@models/Zone';
import { Coordinate2D } from '../utils';
import { IFailed } from './types';

export enum ZoneTypes {
  CREATE = '@@zones/CREATE',
  CREATE_SUCCEEDED = '@@zones/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@zones/CREATE_FAILED',

  DELETE = '@@zones/DELETE',
  DELETE_SUCCEEDED = '@@zones/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@zones/DELETE_FAILED',

  UPDATE = '@@zones/UPDATE',
  UPDATE_SUCCEEDED = '@@zones/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@zones/UPDATE_FAILED',

  WS_CREATE = '@@zones/WS_CREATE',
  WS_UPDATE = '@@zones/WS_UPDATE',
  WS_DELETE = '@@zones/WS_DELETE',
}

export interface ICreateZone {
  color: string;
  coordinates: Coordinate2D[];
  floorplanId: string;
  name: string;
  type: string;
}

export interface ICreateZoneSucceeded {
  zone: Zone;
}

export interface IDeleteZone {
  id: string;
}

export interface IUpdateZone {
  id: string;
  color: string;
  coordinates: Coordinate2D[];
  name: string;
  type: string;
}

export interface IUpdateZoneSucceeded {
  zone: Zone;
}

export interface IUpdateZoneFailed extends IFailed {
  id: string;
}

export const createZone = createAsyncAction(
  ZoneTypes.CREATE,
  ZoneTypes.CREATE_SUCCEEDED,
  ZoneTypes.CREATE_FAILED
)<ICreateZone, ICreateZoneSucceeded, IFailed>();

export const deleteZone = createAsyncAction(
  ZoneTypes.DELETE,
  ZoneTypes.DELETE_SUCCEEDED,
  ZoneTypes.DELETE_FAILED
)<IDeleteZone, IDeleteZone, IFailed>();

export const updateZone = createAsyncAction(
  ZoneTypes.UPDATE,
  ZoneTypes.UPDATE_SUCCEEDED,
  ZoneTypes.UPDATE_FAILED
)<IUpdateZone, IUpdateZoneSucceeded, IUpdateZoneFailed>();

export const wsCreateZone = createAction(
  ZoneTypes.WS_CREATE,
  (data: IUpdateZoneSucceeded) => data
)();
export const wsUpdateZone = createAction(
  ZoneTypes.WS_UPDATE,
  (data: IUpdateZoneSucceeded) => data
)();
export const wsDeleteZone = createAction(
  ZoneTypes.WS_DELETE,
  (data: IDeleteZone) => data
)();
