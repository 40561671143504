import Scan from '@models/Scan';
import axios from './axios';


export const createScan = async (
  scan: string,
  type: 'location',
  x: number,
  y: number,
  z: number,
): Promise<Scan> => {
  const { data } = await axios.post(
    `/scans`,
    {
      scan,
      type,
      x,
      y,
      z,
    }
  );

  return data;
};
