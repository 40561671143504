import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/maintenances';
import Maintenance from '@models/Maintenance';
import FetchError from '@models/FetchError';

type MaintenanceType = ActionType<typeof types>;

interface IForm {
  error?: FetchError;
  loading: boolean;
  assetId?: string;
}

interface IMaintenancesByAsset {
  loading: boolean;
  error?: any;
  list?: Maintenance[];
}
export interface IMaintenanceState {
  readonly byAsset: Record<string, IMaintenancesByAsset>;
  readonly form: IForm;
}

export const initialState: IMaintenanceState = {
  byAsset: {},
  form: {
    loading: false,
  },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IMaintenanceState = initialState,
  action: MaintenanceType
): IMaintenanceState => {
  switch (action.type) {
    case getType(types.fetchMaintenancesByAsset.request): {
      const { assetId } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchMaintenancesByAsset.success): {
      const { assetId, maintenances } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            ...state.byAsset[assetId],
            error: undefined,
            list: maintenances,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchMaintenancesByAsset.failure): {
      const { error, assetId } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            ...state.byAsset[assetId],
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.createMaintenance.request): {
      return {
        ...state,
        form: {
          error: undefined,
          loading: true,
        },
      };
    }

    case getType(types.createMaintenance.success): {
      const { maintenance } = action.payload;

      return {
        ...state,
        form: {
          error: undefined,
          loading: false,
        },
        byAsset: {
          ...state.byAsset,
          [maintenance.assetId]: {
            ...(state.byAsset[maintenance.assetId] || {}),
            list: (
              (state.byAsset[maintenance.assetId] || {}).list || []
            ).concat([maintenance]),
          },
        },
      };
    }

    case getType(types.createMaintenance.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          error,
          loading: false,
        },
      };
    }

    case getType(types.openMaintenanceForm): {
      const { assetId } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          assetId,
        },
      };
    }

    case getType(types.closeMaintenanceForm): {
      return {
        ...state,
        form: {
          ...state.form,
          assetId: undefined,
        },
      };
    }

    default:
      return state;
  }
};
