import axios from './axios';

import { FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';

export async function fetchOrders(): Promise<Order[]> {
  const { data } = await axios.get('/routing/orders/read-orders');

  return data;
}

export async function fetchOrderItems(orderId: string): Promise<OrderWithItems> {
  const { data } = await axios.post('/routing/actions_orders/get-orders-with-items-and-locations', [orderId]);

  return {
    ...((data[0] || {}).order || {}),
    items: (data[0] || {}).items || [],
  };
}

export async function addOrderToNewBasket(orderId: string): Promise<void> {
  await axios.post('/routing/actions_orders/add-orders-to-new-basket', [
    orderId
  ]);
}

export async function addOrderToCurrentBasket(orderId: string): Promise<void> {
  await axios.post('/routing/actions_orders/add-orders-to-current-basket', [
    orderId
  ]);
}

export async function abandonBasket(basketId: string): Promise<void> {
  await axios.post('/routing/actions_orders/abandon-basket', basketId);
}

export async function checkoutBasket(basketId: string): Promise<void> {
  await axios.post('/routing/actions_orders/checkout-basket-and-assign-to-employee', basketId);
}

export async function fetchCurrentBasket(): Promise<FullBasket | undefined> {
  // const { data } = await axios.get('/routing/actions_orders/get-active-basket');
  const { data } = await axios.get('/routing/actions_orders/get-active-basket-detail');

  return {
    ...(data[0] || {}),
    orders: (data[1] || []).map((o: any) => ({ ...o.order, items: o.items })),
  };
}

export async function removeBasketOrders(basketId: string, orderIDs: string[]): Promise<void> {
  await axios.post(`/routing/actions_orders/remove-orders-from-basket?basket_id=${basketId}`, orderIDs);
}

export async function quickOrder(): Promise<FullOrder> {
  const { data } = await axios.get(`/routing/actions_orders/quick-order`);

  return {
    ...(data.order || {}),
    items: data.items || [],
  };
}

export async function autoLocate(basketId: string): Promise<void> {
  await axios.post('/routing/actions_locations/auto-locate', [
    basketId
  ]);
}

export async function validateItemLocation(barcode: string, pickingSessionId: string): Promise<void> {
  await axios.post('/routing/actions_picking/start-validate-item-location', {
    barcode,
    picking_session_id: pickingSessionId,
  });
}

export async function confirmValidateItemLocation(pickingTaskId: string): Promise<void> {
  await axios.post('/routing/actions_picking/confirm-validate-item-location', {
    picking_task_id: pickingTaskId,
  });
}

export async function validateItemQuantity(barcode: string, pickingSessionId: string, locationId: string): Promise<void> {
  await axios.post('/routing/actions_picking/start-validate-item-quantity', {
    barcode,
    picking_session_id: pickingSessionId,
    location_id: locationId,
  });
}

export async function confirmValidateItemQuantity(pickingTaskId: string, quantity: number): Promise<void> {
  await axios.post('/routing/actions_picking/confirm-validate-item-quantity', {
    picking_task_id: pickingTaskId,
    confirmed_quantity: quantity,
  });
}

export async function startPickingSession(basketId: string): Promise<void> {
  await axios.post(`/routing/actions_picking/start-picking-session?basket_id=${basketId}&picking_mode=manual`, {});
}
