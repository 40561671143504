import { stringify } from 'qs';

import { INotificationId } from '@actions/notifications';
import { Notifications } from '@models/Notification';
import axios from './axios';

export const NOTIFICATIONS_API = {
  getNotifications: '/notifications',
  getNotificationsCount: '/notifications/count',
  putDismiss: '/notifications/dismiss',
  putUnread: '/notifications/unread',
};

export async function fetchNotifications(
  locale: string,
  units: string
): Promise<Notifications> {
  const { data } = await axios.get(NOTIFICATIONS_API.getNotifications, {
    params: {
      locale: locale.replace('-', '_'),
      units,
    },
    paramsSerializer: (params) => stringify(params, { indices: false }),
  });

  return data;
}

export async function fetchNotificationsCount(): Promise<Notifications> {
  const { data } = await axios.get(NOTIFICATIONS_API.getNotificationsCount);

  return data;
}

export async function dismissNotifications(
  ids: INotificationId[],
  locale: string,
  units: string
): Promise<Notifications> {
  const { data } = await axios.put(NOTIFICATIONS_API.putDismiss, ids, {
    params: {
      locale: locale.replace('-', '_'),
      units,
    },
    paramsSerializer: (params) => stringify(params, { indices: false }),
  });

  return data;
}

export async function unreadNotifications(
  ids: INotificationId[],
  locale: string,
  units: string
): Promise<Notifications> {
  const { data } = await axios.put(NOTIFICATIONS_API.putUnread, ids, {
    params: {
      locale: locale.replace('-', '_'),
      units,
    },
    paramsSerializer: (params) => stringify(params, { indices: false }),
  });

  return data;
}
