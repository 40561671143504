import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/maintenances';
import { createMaintenance, fetchMaintenancesByAsset } from '@api/maintenances';
import { treatFetchError } from '@api/utils';
import Maintenance from '@models/Maintenance';

export function* createMaintenanceSaga(
  action: ActionType<typeof types.createMaintenance.request>
) {
  const { assetId, observations, type, intl } = action.payload;

  try {
    const maintenance: Maintenance = yield call(
      createMaintenance,
      assetId,
      observations,
      type
    );

    yield put(types.createMaintenance.success({ intl, maintenance }));
  } catch (error: any) {
    yield put(
      types.createMaintenance.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchMaintenancesByAssetSaga(
  action: ActionType<typeof types.fetchMaintenancesByAsset.request>
) {
  const { assetId } = action.payload;

  try {
    const maintenances: Maintenance[] = yield call(
      fetchMaintenancesByAsset,
      assetId
    );

    yield put(
      types.fetchMaintenancesByAsset.success({ assetId, maintenances })
    );
  } catch (error: any) {
    yield put(
      types.fetchMaintenancesByAsset.failure({
        assetId,
        error: treatFetchError(error),
      })
    );
  }
}
