import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/assets';
import Asset, { Assets } from '@models/Asset';
import AssetTicket, {
  AssetTickets,
  AssetTicketStatus,
} from '@models/AssetTicket';
import { AssetDocuments } from '@models/AssetDocument';
import FetchError from '@models/FetchError';

type Actions = ActionType<typeof types | typeof auth>;

interface IAssetForm {
  active: boolean;
  data?: Asset;
  error?: FetchError;
  loading: boolean;
}
interface IForm {
  error?: FetchError;
  loading: boolean;
}
interface ITicketForm {
  active: boolean;
  error?: FetchError;
  loading: boolean;
}
interface ITicketsState {
  readonly ticket?: AssetTicket;
  readonly tickets: AssetTickets;
  readonly errorTicket?: object;
  readonly error?: object;
  readonly loadingTicket: boolean;
  readonly loading: boolean;
  readonly ticketForm: ITicketForm;
  readonly ticketCommentForm: IForm;
  readonly operationForm: IForm;
  readonly assignForm: IForm;
}
interface IDocumentsState {
  readonly documents: AssetDocuments;
  readonly documentForm: IForm;
  readonly error?: object;
  readonly loading: boolean;
}
export interface IAssetsState {
  readonly assets: Assets;
  readonly documents: IDocumentsState;
  readonly tickets: ITicketsState;
  readonly error?: object;
  readonly form: IAssetForm;
  readonly loading: boolean;
  readonly preCheckForm: IForm;
  readonly assetPreCheckForm: ITicketForm;
  readonly assetMaintenanceForm: ITicketForm;
}

export const initialState: IAssetsState = {
  assets: {},
  documents: {
    documents: {},
    loading: false,
    documentForm: { loading: false },
  },
  tickets: {
    tickets: {},
    ticketForm: {
      active: false,
      loading: false,
    },
    ticketCommentForm: { loading: false },
    operationForm: { loading: false },
    assignForm: { loading: false },
    loading: false,
    loadingTicket: false,
  },
  form: {
    active: false,
    loading: false,
  },
  loading: false,
  preCheckForm: {
    loading: false,
  },
  assetPreCheckForm: {
    active: false,
    loading: false,
  },
  assetMaintenanceForm: {
    active: false,
    loading: false,
  },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAssetsState = initialState,
  action: Actions
): IAssetsState => {
  switch (action.type) {
    case getType(types.createAsset.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createAsset.success): {
      const { asset } = action.payload;

      return {
        ...state,
        assets: {
          ...state.assets,
          [asset.id]: asset,
        },
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.createAsset.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.updateAsset.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateAsset.success): {
      const { asset } = action.payload;

      return {
        ...state,
        assets: {
          ...state.assets,
          [asset.id]: asset,
        },
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.updateAsset.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.updateAssetPreCheck.request):
      return {
        ...state,
        preCheckForm: {
          loading: true,
        },
      };

    case getType(types.updateAssetPreCheck.success): {
      const { asset } = action.payload;

      return {
        ...state,
        assets: {
          ...state.assets,
          [asset.id]: asset,
        },
        preCheckForm: {
          loading: false,
        },
      };
    }

    case getType(types.updateAssetPreCheck.failure): {
      const { error } = action.payload;

      return {
        ...state,
        preCheckForm: { error, loading: false },
      };
    }

    case getType(types.deleteAsset.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteAsset.success):
      return {
        ...state,
        assets: omit(state.assets, action.payload.id),
        error: undefined,
        loading: false,
      };

    case getType(types.deleteAsset.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.fetchAssets.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchAssets.success): {
      const { assets, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        assets,
        error: undefined,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchAssets.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.wsUpdateAsset):
    case getType(types.wsCreateAsset): {
      const { asset } = action.payload;

      return {
        ...state,
        assets: {
          ...state.assets,
          [asset.id]: asset,
        },
      };
    }

    case getType(types.wsDeleteAsset):
      return {
        ...state,
        assets: omit(state.assets, action.payload.id),
      };

    case getType(types.openTicketForm):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketForm: {
            active: true,
            loading: false,
          },
        },
      };

    case getType(types.closeTicketForm):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketForm: {
            active: false,
            loading: false,
          },
        },
      };

    case getType(types.fetchAssetTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          errorTicket: undefined,
          loadingTicket: true,
        },
      };

    case getType(types.fetchAssetTicket.success):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticket: action.payload,
          errorTicket: undefined,
          loadingTicket: false,
        },
      };

    case getType(types.fetchAssetTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          errorTicket: error,
          loadingTicket: false,
        },
      };
    }

    case getType(types.fetchAssetTickets.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchAssetTickets.success):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: action.payload,
          error: undefined,
          loading: false,
        },
      };

    case getType(types.fetchAssetTickets.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createAssetTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketForm: {
            ...state.tickets.ticketForm,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.createAssetTicket.success): {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [action.payload.id]: action.payload,
          },
          ticketForm: {
            active: false,
            loading: false,
          },
        },
      };
    }

    case getType(types.createAssetTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketForm: {
            ...state.tickets.ticketForm,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.createAssetTicketComment.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketCommentForm: {
            ...state.tickets.ticketCommentForm,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.createAssetTicketComment.success): {
      const comments = [...((state.tickets.ticket || {}).comments || [])];
      const index = comments.findIndex((c) => c.id === action.payload.id);

      if (index === -1) {
        comments.push(action.payload);
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticket: {
            ...state.tickets.ticket!,
            comments,
          },
          ticketCommentForm: {
            ...state.tickets.ticketCommentForm,
            loading: false,
          },
        },
      };
    }

    case getType(types.createAssetTicketComment.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketCommentForm: {
            ...state.tickets.ticketCommentForm,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.assignAssetTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          assignForm: {
            ...state.tickets.assignForm,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.assignAssetTicket.success): {
      const { ticketId, accountName, accountImage } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              status: AssetTicketStatus.ASSIGNED,
              assigneeAccountName: accountName,
              assigneeAccountImage: accountImage,
            },
          },
          assignForm: {
            ...state.tickets.assignForm,
            loading: false,
          },
        },
      };
    }

    case getType(types.assignAssetTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          assignForm: {
            ...state.tickets.assignForm,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.changeAssetTicketStatus.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.changeAssetTicketStatus.success): {
      const { ticketId, status } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              status,
            },
          },
          loading: false,
        },
      };
    }

    case getType(types.changeAssetTicketStatus.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          error,
          loading: false,
        },
      };
    }

    case getType(types.archiveAssetTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.archiveAssetTicket.success): {
      const { ticketId } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              archived: true,
            },
          },
          loading: false,
        },
      };
    }

    case getType(types.archiveAssetTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createAssetTicketOperation.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          operationForm: {
            ...state.tickets.operationForm,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.createAssetTicketOperation.success): {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticket: {
            ...(state.tickets.ticket || ({} as AssetTicket)),
            operations: [
              ...((state.tickets.ticket || {}).operations || []),
              action.payload,
            ],
          },
          operationForm: { loading: false },
        },
      };
    }

    case getType(types.createAssetTicketOperation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          operationForm: {
            ...state.tickets.operationForm,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.deleteAssetTicketOperation.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          errorTicket: undefined,
          loadingTicket: true,
        },
      };

    case getType(types.deleteAssetTicketOperation.success): {
      const { tickets } = state;
      const operations = (tickets.ticket || {}).operations || [];
      const index = operations.findIndex((o) => o.id === action.payload.id);

      if (index !== -1) {
        operations.splice(index, 1);
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticket: {
            ...(state.tickets.ticket || ({} as AssetTicket)),
            operations,
          },
          loadingTicket: false,
        },
      };
    }

    case getType(types.deleteAssetTicketOperation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          errorTicket: error,
          loadingTicket: true,
        },
      };
    }

    case getType(types.fetchAssetDocuments.request):
      return {
        ...state,
        documents: {
          ...state.documents,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchAssetDocuments.success):
      return {
        ...state,
        documents: {
          ...state.documents,
          documents: action.payload,
          error: undefined,
          loading: false,
        },
      };

    case getType(types.fetchAssetDocuments.failure): {
      const { error } = action.payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createAssetDocument.request):
      return {
        ...state,
        documents: {
          ...state.documents,
          documentForm: {
            ...state.documents.documentForm,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.createAssetDocument.success): {
      return {
        ...state,
        documents: {
          ...state.documents,
          documents: {
            ...state.documents.documents,
            [action.payload.id]: action.payload,
          },
          documentForm: {
            ...state.documents.documentForm,
            loading: false,
          },
        },
      };
    }

    case getType(types.createAssetDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          documentForm: {
            ...state.documents.documentForm,
            loading: false,
            error,
          },
        },
      };
    }

    case getType(types.deleteAssetDocument.request):
      return {
        ...state,
        documents: {
          ...state.documents,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.deleteAssetDocument.success): {
      const { documents } = state.documents;
      const { id } = action.payload;

      delete documents[id];

      return {
        ...state,
        documents: {
          ...state.documents,
          documents,
          loading: false,
        },
      };
    }

    case getType(types.deleteAssetDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          error,
        },
      };
    }

    case getType(types.wsCreateAssetTicket): {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [action.payload.id]: action.payload,
          },
        },
      };
    }
    case getType(types.wsCreateAssetTicketComment): {
      if (!state.tickets.ticket) {
        return state;
      }

      const comments = [...((state.tickets.ticket || {}).comments || [])];
      const index = comments.findIndex((c) => c.id === action.payload.id);

      if (index === -1) {
        comments.push(action.payload);
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticket: {
            ...state.tickets.ticket,
            comments,
          },
        },
      };
    }

    case getType(types.wsAssignAssetTicket): {
      const { ticketId, accountName, accountImage } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              status: AssetTicketStatus.ASSIGNED,
              assigneeAccountName: accountName,
              assigneeAccountImage: accountImage,
            },
          },
        },
      };
    }

    case getType(types.wsChangeAssetTicketStatus): {
      const { ticketId, status } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              status,
            },
          },
        },
      };
    }

    case getType(types.wsChangeAssetTicketArchive): {
      const { ticketId } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: {
              ...state.tickets.tickets[ticketId],
              archived: true,
            },
          },
        },
      };
    }

    case getType(types.wsCreateAssetDocument):
      return {
        ...state,
        documents: {
          ...state.documents,
          documents: {
            ...state.documents.documents,
            [action.payload.id]: action.payload,
          },
        },
      };

    case getType(types.wsDeleteAssetDocument): {
      const { documents } = state.documents;
      const { id } = action.payload;

      delete documents[id];

      return {
        ...state,
        documents: {
          ...state.documents,
          documents,
        },
      };
    }

    case getType(types.openAssetForm):
      return {
        ...state,
        form: {
          active: true,
          data: action.payload,
          loading: false,
        },
      };

    case getType(types.closeAssetForm):
      return {
        ...state,
        form: {
          active: false,
          loading: false,
        },
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return {
        ...state,
        assets: {},
      };
    }

    default:
      return state;
  }
};
