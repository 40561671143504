import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/organisation';
import FetchError from '@models/FetchError';
import Organisation from '@models/Organisation';

type OrganisationType = ActionType<typeof types>;

export interface IOrganisationState {
  readonly error?: FetchError;
  readonly organisation?: Organisation;
  readonly loading: boolean;
}

export const initialState: IOrganisationState = {
  error: undefined,
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IOrganisationState = initialState,
  action: OrganisationType
): IOrganisationState => {
  switch (action.type) {
    case getType(types.fetchOrganisationInformation.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchOrganisationInformation.success): {
      const { organisation, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        organisation,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchOrganisationInformation.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    default:
      return state;
  }
};
