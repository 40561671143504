import { IUpdateFloorplan } from '@actions/index';
import Floorplan from '@models/Floorplan';
import axios from './axios';
import {
  INormalizedIncompleteFloorplan,
  normalizeFloorplan,
} from './warehousesUtils';

export const FLOORPLAN_API = {
  postFloorplan: '/warehouses/floorplans/',
  putFloorplan: '/warehouses/floorplans/edit',
};

export async function fetchFloorplan(
  id: string
): Promise<INormalizedIncompleteFloorplan> {
  const { data: floorplan } = await axios.get(
    `/warehouses/floorplans/id/${id}`
  );

  return normalizeFloorplan(floorplan);
}

/**
 * Creates a floorplan
 * @return The created floorplan
 */
export async function createFloorplan(
  warehouseId: string,
  { name }: Pick<Floorplan, 'name'>
): Promise<INormalizedIncompleteFloorplan> {
  const { data: floorplan } = await axios.post(FLOORPLAN_API.postFloorplan, {
    id: warehouseId,
    name,
  });

  return normalizeFloorplan(floorplan, warehouseId);
}

/**
 * Updates a floorplan image
 * @return The floorplan's warehouse
 */
export const updateFloorplanImage = async (
  warehouseId: string,
  floorplanId: string,
  image: File
): Promise<INormalizedIncompleteFloorplan> => {
  const formData = new FormData();

  formData.set('id', floorplanId);
  formData.set('file', image);
  const { data: floorplan } = await axios.put(
    '/warehouses/floorplans/image',
    formData
  );

  return normalizeFloorplan(floorplan, warehouseId);
};

/**
 * Updates the floorplan
 * @typedef  {Object}                 floorplanProps
 * @property {Object} enuTransformationMatrix
 * @property {Array} exteriorBoundaries
 * @property {Array} geoMapping
 * @property {number} geodeticCenterLatitude
 * @property {number} geodeticCenterLongitude
 * @property {boolean} hidden
 * @property {string} name
 * @property {Array} obstacles
 * @property {Array} racks
 * @property {number} scale Pixels/meters
 * @property {TransformationMatrix2D} transformationMatrix
 * @property {string} anchorType
 * @property {string} tracking
 * @property {string} connectivity
 * @property {number} targetNumberOfForklifts
 * @property {number} floorplanArea
 * @property {number} aisleLength
 * @property {number} numberOfAisles
 * @property {number} openAreasSpace
 *
 * @param {string}         warehouseId Warehouse Id
 * @param {string}         floorplanId Floorplan Id
 * @param {floorplanProps} properties
 * @return The warehouse the floorplan belongs to
 */
export async function updateFloorplan(
  warehouseId: string,
  floorplanId: string,
  {
    boundaries,
    enuTransformationMatrix,
    exteriorBoundaries,
    geoMapping,
    geodeticCenterLatitude,
    geodeticCenterLongitude,
    itemLocations,
    hidden,
    obstacles,
    racks,
    landmarks,
    name,
    scale,
    transformationMatrix,
    anchorType,
    tracking,
    connectivity,
    targetNumberOfForklifts,
    floorplanArea,
    aisleLength,
    numberOfAisles,
    openAreasSpace,
  }: IUpdateFloorplan['properties']
): Promise<INormalizedIncompleteFloorplan> {
  const { data: floorplan } = await axios.put(FLOORPLAN_API.putFloorplan, {
    boundaries,
    enuTransformationMatrix,
    exteriorBoundaries,
    geoMapping,
    geodeticCenterLatitude,
    geodeticCenterLongitude,
    itemLocations,
    hidden,
    id: floorplanId,
    name,
    obstacles,
    racks,
    landmarks,
    scale,
    transformationMatrix,
    anchorType,
    tracking,
    connectivity,
    targetNumberOfForklifts,
    floorplanArea,
    aisleLength,
    numberOfAisles,
    openAreasSpace,
  });

  return normalizeFloorplan(floorplan, warehouseId);
}

/**
 * Updates the floorplan
 * @param   {string} floorplanId Floorplan Id
 * @returns {void}   Nothing
 */
export const deleteFloorplan = async (floorplanId: string) =>
  axios.delete(`/warehouses/floorplans/delete/id/${floorplanId}`);
