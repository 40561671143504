import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/routing';
import { FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';

type RoutingType = ActionType<typeof types>;

interface OrdersState {
  list: Order[];
  error?: object;
  loading: boolean;
}
interface FullBasketState {
  basket?: FullBasket;
  noBasket: boolean;
  error?: object;
  loading: boolean;
}
interface OrderState {
  order?: OrderWithItems;
  error?: object;
  loading: boolean;
}
interface LoadingState {
  error?: object;
  loading: boolean;
}
interface ConfirmedState {
  error?: object;
  loading: boolean;
  confirmed: boolean;
}
interface QuickOrderState {
  error?: object;
  loading: boolean;
  order?: FullOrder;
}
export interface IRoutingState {
  readonly currentBasket: FullBasketState;
  readonly abandonBasket: LoadingState;
  readonly checkoutBasket: LoadingState;
  readonly removeBasketOrders: LoadingState;
  readonly orders: OrdersState;
  readonly order: OrderState;
  readonly autoLocate: LoadingState;
  readonly quickOrder: QuickOrderState;
  readonly validateItemLocation: LoadingState;
  readonly confirmValidateItemLocation: ConfirmedState;
  readonly validateItemQuantity: LoadingState;
  readonly confirmValidateItemQuantity: ConfirmedState;
  readonly startPickingSession: LoadingState;
}

export const initialState: IRoutingState = {
  currentBasket: {
    noBasket: false,
    loading: false,
  },
  abandonBasket: {
    loading: false,
  },
  checkoutBasket: {
    loading: false,
  },
  removeBasketOrders: {
    loading: false,
  },
  orders: {
    list: [],
    loading: false,
  },
  order: {
    loading: false,
  },
  autoLocate: {
    loading: false,
  },
  quickOrder: {
    loading: false,
  },
  validateItemLocation: {
    loading: false,
  },
  confirmValidateItemLocation: {
    confirmed: false,
    loading: false,
  },
  validateItemQuantity: {
    loading: false,
  },
  confirmValidateItemQuantity: {
    confirmed: false,
    loading: false,
  },
  startPickingSession: {
    loading: false,
  },
};

export default (
  state: IRoutingState = initialState,
  action: RoutingType
): IRoutingState => {
  switch (action.type) {
    case getType(types.fetchOrders.request): {
      const { shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: shallow ? state.orders?.loading : true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrders.success): {
      const { orders, shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          list: orders,
          loading: shallow ? state.orders?.loading : false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrders.failure): {
      const { error, shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: shallow ? state.orders?.loading : false,
          error,
        },
      };
    }

    case getType(types.fetchOrderItems.request): {
      return {
        ...state,
        order: {
          ...state.order,
          order: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrderItems.success): {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          order,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrderItems.failure): {
      const { error } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.request): {
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.success): {
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.addOrderToNewBasket.request): {
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    }

    case getType(types.addOrderToNewBasket.success): {
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
        },
      };
    }

    case getType(types.addOrderToNewBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.abandonBasket.request): {
      return {
        ...state,
        abandonBasket: {
          ...state.abandonBasket,
          loading: true,
        },
      };
    }

    case getType(types.abandonBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          noBasket: true,
          basket: undefined,
        },
        abandonBasket: {
          ...state.abandonBasket,
          loading: false,
        },
      };
    }

    case getType(types.abandonBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        abandonBasket: {
          ...state.abandonBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.checkoutBasket.request): {
      return {
        ...state,
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: true,
        },
      };
    }

    case getType(types.checkoutBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          noBasket: true,
          basket: undefined,
        },
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: false,
        },
      };
    }

    case getType(types.checkoutBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.fetchCurrentBasket.request): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          basket: undefined,
          noBasket: true,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          basket: action.payload,
          noBasket: false,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.removeBasketOrders.request): {
      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.removeBasketOrders.success): {
      const { orderIds } = action.payload;

      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: false,
        },
        currentBasket: {
          ...state.currentBasket,
          basket: {
            ...(state.currentBasket.basket || {} as FullBasket),
            orders: (state.currentBasket.basket?.orders || []).filter((o) => orderIds.indexOf(o.id) === -1),
          },
        },
      };
    }

    case getType(types.removeBasketOrders.failure): {
      const { error } = action.payload;

      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.autoLocate.request): {
      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: true,
        },
      };
    }

    case getType(types.autoLocate.success): {
      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: false,
        },
      };
    }

    case getType(types.autoLocate.failure): {
      const { error } = action.payload;

      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: false,
          error,
        },
      };
    }

    case getType(types.quickOrder.request): {
      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          loading: true,
        },
      };
    }

    case getType(types.quickOrder.success): {
      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          order: action.payload,
          loading: false,
        },
      };
    }

    case getType(types.quickOrder.failure): {
      const { error } = action.payload;

      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateItemLocation.request): {
      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          loading: true,
        },
      };
    }

    case getType(types.validateItemLocation.success): {
      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          loading: false,
        },
      };
    }

    case getType(types.validateItemLocation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          loading: false,
          error,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.request): {
      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: true,
          confirmed: false,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.success): {
      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: false,
          confirmed: true,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateItemQuantity.request): {
      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          loading: true,
        },
      };
    }

    case getType(types.validateItemQuantity.success): {
      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          loading: false,
        },
      };
    }

    case getType(types.validateItemQuantity.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          loading: false,
          error,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.request): {
      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          confirmed: false,
          loading: true,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.success): {
      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          confirmed: true,
          loading: false,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.failure): {
      const { error } = action.payload;

      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          loading: false,
          error,
        },
      };
    }

    case getType(types.startPickingSession.request): {
      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          loading: true,
        },
      };
    }

    case getType(types.startPickingSession.success): {
      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          loading: false,
        },
      };
    }

    case getType(types.startPickingSession.failure): {
      const { error } = action.payload;

      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          loading: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
