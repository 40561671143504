import { stringify } from 'qs';

import Sensor, { Sensors } from '@models/Sensor';
import { SensorGroups } from '@models/SensorGroup';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import SensorsWithStatus from '@models/SensorWithStatus';
import axios from './axios';
import {
  normalizeSensor,
  normalizeSensorData,
  normalizeSensors,
} from './sensorsUtils';
import { ISensorData } from './websocket';

export const fetchFloorplanSensors = async (
  floorplanId: string
): Promise<Sensors> => {
  const { data: sensors } = await axios.get(
    `/sensors/floorplan/${floorplanId}`
  );

  return normalizeSensors(sensors);
};

export const fetchSensorInfo = async (sensorId: string): Promise<Sensor> => {
  const { data: sensor } = await axios.get(`/sensors/id/${sensorId}`);

  return normalizeSensor(sensor);
};

export const fetchSensorGroupsByFloorplan = async (
  floorplanId: string
): Promise<SensorGroups> => {
  const { data: sensorGroups } = await axios.get(
    `/sensors/groups/floorplan/${floorplanId}`
  );

  return sensorGroups;
};

export const fetchFloorplanSensorsStatus = async (
  floorplanId: string
): Promise<SensorsWithStatus> => {
  const { data: sensors } = await axios.get(
    `/sensors/status/floorplan/${floorplanId}`
  );

  return sensors;
};

export const fetchFloorplanSensorGroupStatus = async (
  floorplanId: string
): Promise<SensorGroupWithStatus> => {
  const { data: sensorGroup } = await axios.get(
    `/sensors/group/status/floorplan/${floorplanId}`
  );

  return sensorGroup;
};

export const fetchSensorsData = async (
  floorplanId: string,
  assetIds: string[],
  timestamp: number
): Promise<Record<string, ISensorData[]>> => {
  const { data } = await axios.get(
    `/sensors/data/floorplan/${floorplanId}/min/${timestamp}/max/${
      timestamp + 30 * 60 * 1000
    }`,
    {
      params: { asset: assetIds !== undefined ? assetIds : undefined },
      paramsSerializer: (params) => stringify(params, { indices: false }),
    }
  );

  return normalizeSensorData(data);
};

export const createSensor = async (
  floorplanId: string,
  sensorGroupId: string,
  physicalAddress: string,
  type: 'mobile_app',
  position: [number, number, number]
): Promise<Sensor> => {
  const { data } = await axios.post(
    `/sensors`,
    {
      floorplanId,
      sensorGroupId,
      physicalAddress,
      type,
      position,
    }
  );

  return data;
};
