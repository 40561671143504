import PreCheck, {
  IAssetPreCheck,
  IPreCheckAnswer,
  IPreCheckQuestion,
  PreChecks,
} from '@models/PreCheck';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

// Helpers
function normalizePreCheck(preCheck: PreCheck[]) {
  return convertArrayToIdMap(preCheck);
}

export async function createPreCheck(
  name: string,
  questions: IPreCheckQuestion[]
): Promise<PreCheck> {
  const { data: preChecks } = await axios.post('/prechecks/new', {
    name,
    questions,
  });

  return preChecks;
}

export async function deletePreCheck(preCheckId: string): Promise<void> {
  await axios.delete(`/prechecks/delete/id/${preCheckId}`);
}

export async function editPreCheck(
  id: string,
  name: string,
  questions: IPreCheckQuestion[]
): Promise<void> {
  const { data: preChecks } = await axios.put('/prechecks/edit', {
    id,
    name,
    questions,
  });

  return preChecks;
}

export async function fetchPreCheckList(): Promise<PreChecks> {
  const { data } = await axios.get('/prechecks/');

  return normalizePreCheck(data);
}

export async function fetchPreCheck(id: string): Promise<PreCheck> {
  const { data } = await axios.get(`/prechecks/id/${id}`);

  return data;
}

export async function createPreCheckAnswer(
  id: string,
  assetId: string,
  answers: IPreCheckAnswer[]
): Promise<void> {
  const { data } = await axios.post('/prechecks/answer', {
    assetId,
    answers,
    id,
  });

  return data;
}

export async function fetchPreCheckAnswersByAsset(
  assetId: string
): Promise<IAssetPreCheck[]> {
  const { data } = await axios.get(`/prechecks/answers/asset/${assetId}`);

  return data;
}
