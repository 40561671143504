import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/prechecks';
import PreCheck, { IAssetPreCheck, PreChecks } from '@models/PreCheck';

type PreChecksType = ActionType<typeof types>;

interface IPreCheckList {
  loading: boolean;
  error?: any;
  data?: PreChecks;
}
interface IPreCheck {
  loading: boolean;
  loadingSubmit: boolean;
  error?: any;
  data?: PreCheck;
  assetId?: string;
  preCheckId?: string;
}
interface IPreCheckForm {
  active: boolean;
  data?: PreCheck;
  error?: any;
  loading: boolean;
}
interface IPreChecksByAsset {
  loading: boolean;
  error?: any;
  list?: IAssetPreCheck[];
}
export interface IPreCheckState {
  readonly form: IPreCheckForm;
  readonly list: IPreCheckList;
  readonly precheckAnswerForm: IPreCheck;
  readonly byAsset: Record<string, IPreChecksByAsset>;
}

const initialState: IPreCheckState = {
  byAsset: {},
  form: {
    active: false,
    loading: false,
  },
  list: {
    loading: false,
  },
  precheckAnswerForm: {
    loading: false,
    loadingSubmit: false,
  },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IPreCheckState = initialState,
  action: PreChecksType
): IPreCheckState => {
  switch (action.type) {
    case getType(types.createPreCheck.request):
      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createPreCheck.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload,
          loading: false,
        },
      };

    case getType(types.createPreCheck.success): {
      const preCheck = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          loading: false,
        },
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            [preCheck.id]: preCheck,
          },
        },
      };
    }

    case getType(types.editPreCheck.request):
      return {
        ...state,
        form: {
          active: false,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.editPreCheck.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload,
          loading: false,
        },
      };

    case getType(types.editPreCheck.success): {
      const preCheck = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          loading: false,
        },
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            [preCheck.id]: preCheck,
          },
        },
      };
    }

    case getType(types.deletePreCheck.request):
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case getType(types.deletePreCheck.failure):
      return {
        ...state,
        list: {
          ...state.list,
          error: action.payload,
          loading: false,
        },
      };

    case getType(types.deletePreCheck.success):
      return {
        ...state,
        list: {
          ...state.list,
          data: omit(state.list.data, action.payload.id),
          loading: false,
        },
      };

    case getType(types.fetchPreCheckList.request):
      return {
        ...state,
        list: {
          ...state.list,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchPreCheckList.success): {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(types.fetchPreCheckList.failure): {
      const { error } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          error,
          loading: false,
        },
      };
    }

    case getType(types.fetchPreCheck.request):
      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchPreCheck.success): {
      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          data: action.payload,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(types.fetchPreCheck.failure): {
      const { error } = action.payload;

      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          error,
          loading: false,
        },
      };
    }

    case getType(types.createPreCheckAnswer.request):
      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          error: undefined,
          loadingSubmit: true,
        },
      };

    case getType(types.createPreCheckAnswer.success): {
      const { preCheckAnswer } = action.payload;

      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          error: undefined,
          loadingSubmit: false,
        },
        byAsset: {
          ...state.byAsset,
          [preCheckAnswer.assetId]: {
            ...(state.byAsset[preCheckAnswer.assetId] || {}),
            list: (
              (state.byAsset[preCheckAnswer.assetId] || {}).list || []
            ).concat([preCheckAnswer]),
          },
        },
      };
    }

    case getType(types.createPreCheckAnswer.failure): {
      const { error } = action.payload;

      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          error,
          loadingSubmit: false,
        },
      };
    }

    case getType(types.fetchPreCheckAnswersByAsset.request): {
      const { assetId } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchPreCheckAnswersByAsset.success): {
      const { assetId, preCheckAnswers } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            ...state.byAsset[assetId],
            error: undefined,
            list: preCheckAnswers,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchPreCheckAnswersByAsset.failure): {
      const { error, assetId } = action.payload;

      return {
        ...state,
        byAsset: {
          ...state.byAsset,
          [assetId]: {
            ...state.byAsset[assetId],
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.openPreCheckForm):
      return {
        ...state,
        form: {
          active: true,
          data: action.payload,
          loading: false,
        },
      };

    case getType(types.closePreCheckForm):
      return {
        ...state,
        form: {
          active: false,
          data: undefined,
          loading: false,
        },
      };

    case getType(types.openPreCheckListForm): {
      const { preCheckId, assetId } = action.payload;

      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          assetId,
          preCheckId,
        },
      };
    }

    case getType(types.closePreCheckListForm):
      return {
        ...state,
        precheckAnswerForm: {
          ...state.precheckAnswerForm,
          preCheckId: undefined,
        },
      };

    default:
      return state;
  }
};
