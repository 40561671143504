import { createAction, createAsyncAction } from 'typesafe-actions';

import { IFailed, IFailedWithShallow } from '@actions/types';
import Metric, { MetricAggregation } from '@models/Metric';
import MetricMeta from '@models/MetricMeta';
import { DataRange } from '@models/DataRange';

export enum PlotTypes {
  FETCH_DATARANGE = '@@plots/FETCH_DATARANGE',
  FETCH_DATARANGE_SUCCEEDED = '@@plots/FETCH_DATARANGE_SUCCEEDED',
  FETCH_DATARANGE_FAILED = '@@plots/FETCH_DATARANGE_FAILED',

  FETCH_METRIC = '@@plots/FETCH_METRIC',
  FETCH_METRIC_SUCCEEDED = '@@plots/FETCH_METRIC_SUCCEEDED',
  FETCH_METRIC_FAILED = '@@plots/FETCH_METRIC_FAILED',

  FETCH_METRICS = '@@plots/FETCH_METRICS',
  FETCH_METRICS_SUCCEEDED = '@@plots/FETCH_METRICS_SUCCEEDED',
  FETCH_METRICS_FAILED = '@@plots/FETCH_METRICS_FAILED',

  FETCH_ANALYTICS = '@@plots/FETCH_ANALYTICS',
  FETCH_ANALYTICS_SUCCEEDED = '@@plots/FETCH_ANALYTICS_SUCCEEDED',
  FETCH_ANALYTICS_FAILED = '@@plots/FETCH_ANALYTICS_FAILED',

  TOGGLE_METRIC = '@@plots/TOGGLE_METRIC',
  TOGGLE_METRIC_PLOT = '@@plots/TOGGLE_METRIC_PLOT',
}

export interface IFetch {
  filterId: string;
  warehouseId: string;
  floorplanId: string;
  assetIds?: string[];
  tags?: string[];
  ruleIds?: string[];
  templateIds?: string[];
}

export interface IDataRange {
  fromts: number;
  tots: number;
  lastUpdate: number;
}
export interface IDataRangeResponse extends IFetch {
  dataRange: IDataRange;
  warehouseTz: string;
  shallow?: boolean;
}
export interface IFetchDataRange extends IFetch {
  dataRange?: DataRange;
  warehouseTz: string;
  shallow?: boolean;
}
export interface IDataRangeFailed extends IFailed, IFetch {
  shallow?: boolean;
}

export const fetchDataRange = createAsyncAction(
  PlotTypes.FETCH_DATARANGE,
  PlotTypes.FETCH_DATARANGE_SUCCEEDED,
  PlotTypes.FETCH_DATARANGE_FAILED
)<IFetchDataRange, IDataRangeResponse, IDataRangeFailed>();

export interface FetchMetrics {
  filterId: string;
  section: 'user' | 'diagnostics';

  warehouseId: string;
  floorplanId: string;
  zoneIds?: string[];

  tags?: string[];
  assetIds?: string[];

  templateIds?: string[];
  ruleIds?: string[];

  locale: string;
  units: string;
  shallow?: boolean;
}
interface FetchMetricsSuccess {
  filterId: string;
  metrics: MetricMeta[];
  shallow?: boolean;
}
interface FetchMetricsFailed extends IFailedWithShallow {
  filterId: string;
}

export const fetchMetrics = createAsyncAction(
  PlotTypes.FETCH_METRICS,
  PlotTypes.FETCH_METRICS_SUCCEEDED,
  PlotTypes.FETCH_METRICS_FAILED
)<FetchMetrics, FetchMetricsSuccess, FetchMetricsFailed>();

export interface FetchMetric {
  filterId: string;
  metricId: string;

  warehouseId: string;
  floorplanId: string;
  zoneIds?: string[];

  tags?: string[];
  assetIds?: string[];

  templateIds?: string[];
  ruleIds?: string[];

  from: number;
  to: number;

  aggregations: MetricAggregation[] | undefined;
  returns: string[];
  locale: string;
  units: string;
  shallow?: boolean;
}
interface FetchMetricSuccess {
  filterId: string;
  metricId: string;
  data: Metric;
  shallow?: boolean;
}
interface FetchMetricFailed extends IFailedWithShallow {
  aggregations: MetricAggregation[] | undefined;
  filterId: string;
  metricId: string;
}

export const fetchMetric = createAsyncAction(
  PlotTypes.FETCH_METRIC,
  PlotTypes.FETCH_METRIC_SUCCEEDED,
  PlotTypes.FETCH_METRIC_FAILED
)<FetchMetric, FetchMetricSuccess, FetchMetricFailed>();

export const toggleMetric = createAction(
  PlotTypes.TOGGLE_METRIC,
  (filterId: string, section: string, metricId: string) =>
    ({ filterId, section, metricId })
)();

export interface ToggleMetricPlot {
  filterId: string;
  metricId: string;
  plotType: string;
  id: string;
  result: string;
}
export const toggleMetricPlot = createAction(
  PlotTypes.TOGGLE_METRIC_PLOT,
 (properties: ToggleMetricPlot) => properties
)();
