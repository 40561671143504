export enum AssetTicketStatus {
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  DONE = 'done',
}

export enum AssetTicketTypes {
  BREAKDOWN = 'breakdown',
  MAINTENANCE_NEEDED = 'maintenance_needed',
}

export interface AssetTicketComment {
  id: string;
  ticketId: string;
  comment: string;
  datetime: string;
  accountName: string;
  accountImage: string;
}

export interface AssetTicketOperation {
  id: string;
  ticketId: string;
  name: string;
  description: string;
  cost: number;
  datetime: string;
}

class AssetTicket {
  public id!: string;

  public status!: AssetTicketStatus;

  public type!: AssetTicketTypes;

  public eventDate?: Date;

  public subject!: string;

  public description!: string;

  public workImpairingIssue!: boolean;

  public files?: { file: string }[];

  public comments?: AssetTicketComment[];

  public operations?: AssetTicketOperation[];

  public accountName!: string;

  public accountImage?: string;

  public assigneeAccountName?: string;

  public assigneeAccountImage?: string;

  public archived!: boolean;
}

export type AssetTickets = Record<AssetTicket['id'], AssetTicket>;

export default AssetTicket;
