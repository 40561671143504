import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/reports';
import { fetchReport, fetchROIReport } from '@api/reports';
import { treatFetchError } from '@api/utils';

export function* fetchReportSaga(
  action: ActionType<typeof types.fetchReport.request>
) {
  const {
    filterId,

    assetIds,
    floorplanId,
    warehouseId,
    locale,
    measurementUnits,

    executiveSummary,
    data,
    sensorPlacement,

    endDate,
    startDate,

    intl,
  } = action.payload;

  try {
    const report: object = yield call(
      fetchReport as any,
      filterId,
      floorplanId,
      warehouseId,
      locale,
      measurementUnits,

      assetIds,
      executiveSummary,
      data,
      sensorPlacement,

      endDate,
      startDate
    );

    yield put(types.fetchReport.success({ filterId, report }));
  } catch (error: any) {
    yield put(
      types.fetchReport.failure({
        filterId,
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* fetchROIReportSaga(
  action: ActionType<typeof types.fetchROIReport.request>
) {
  const {
    filterId,
    intl,

    roiData,
  } = action.payload;

  try {
    const report: object = yield call(fetchROIReport, roiData);

    yield put(types.fetchROIReport.success({ filterId, report }));
  } catch (error: any) {
    yield put(
      types.fetchROIReport.failure({
        filterId,
        error: treatFetchError(error),
        intl,
      })
    );
  }
}
