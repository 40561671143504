import { Task, TaskPriority, TaskStatus, TaskType, Tasks } from '@models/Task';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

// Helpers
function normalizeTask(tasks: Task[]) {
  return convertArrayToIdMap(tasks);
}

export async function createTask(
  warehouseId: string,
  floorplanId: string,
  zoneId: string | undefined,
  name: string,
  notes: string | undefined,
  priority: TaskPriority,
  status: TaskStatus,
  type: TaskType,
  dueDate: string,
  pictures: string[],
  employees: string[],
  teams: string[],
  assets: string[],
  subtasks: { active: boolean, name: string }[],
  positionX?: number,
  positionY?: number,
  positionZ?: number,
): Promise<Task> {
  const { data: task } = await axios.post(
    '/tasks',
    {
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes: notes || undefined,
      priority,
      status,
      type,
      dueDate,
      pictures,
      employees,
      teams,
      assets,
      subtasks,
      positionX,
      positionY,
      positionZ,  
    }
  );

  return task;
}

export async function deleteTask(id: string): Promise<void> {
  await axios.delete(`/tasks/delete/id/${id}`);
}

export async function fetchTasks(): Promise<Tasks> {
  const { data } = await axios.get('/tasks');

  return normalizeTask(data);
}

export async function updateTask(
  id: string,
  warehouseId: string,
  floorplanId: string,
  zoneId: string | undefined,
  name: string,
  notes: string | undefined,
  priority: TaskPriority,
  status: TaskStatus,
  type: TaskType,
  dueDate: string,
  pictures: string[],
  employees: string[],
  teams: string[],
  assets: string[],
  subtasks: { active: boolean, name: string }[],
  positionX?: number,
  positionY?: number,
  positionZ?: number,
): Promise<void> {
  const { data: asset } = await axios.put(
    '/tasks',
    {
      id,
      warehouseId,
      floorplanId,
      zoneId,
      name,
      notes: notes || undefined,
      priority,
      status,
      type,
      dueDate,
      pictures,
      employees,
      teams,
      assets,
      subtasks,
      positionX,
      positionY,
      positionZ,
    }
  );

  return asset;
}
