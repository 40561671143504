import { createAsyncAction } from 'typesafe-actions';

import { AssetsEventPeriods } from '@models/EventActivity';
import FetchError from '@models/FetchError';

export enum PlayerTypes {
  FETCH_EVENTSACTIVITY = '@@sensors/FETCH_EVENTSACTIVITY',
  FETCH_EVENTSACTIVITY_SUCCEEDED = '@@sensors/FETCH_EVENTSACTIVITY_SUCCEEDED',
  FETCH_EVENTSACTIVITY_FAILED = '@@sensors/FETCH_EVENTSACTIVITY_FAILED',
}

export interface IEventsFetch {
  filterId: string;

  warehouseId: string;
  floorplanId: string;
  zoneIds?: string[];

  tags?: string[];
  assetIds?: string[];

  templateIds?: string[];
  ruleIds?: string[];

  from: number;
  to: number;

  locale: string;
  units: string;
}

export interface IEventsFetchFailed {
  filterId: string;
  error: FetchError;
}

export interface IEventsFetchResult {
  eventsActivity: IEventsActivity;
  filterId: string;
}

export interface IEventsActivity {
  assets: AssetsEventPeriods;
}

export const fetchEventsActivity = createAsyncAction(
  PlayerTypes.FETCH_EVENTSACTIVITY,
  PlayerTypes.FETCH_EVENTSACTIVITY_SUCCEEDED,
  PlayerTypes.FETCH_EVENTSACTIVITY_FAILED
)<IEventsFetch, IEventsFetchResult, IEventsFetchFailed>();
