import { omitBy } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/floorplans';
import * as warehousesTypes from '@actions/warehouses';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';

type FloorplansAction = ActionType<
  typeof types | typeof auth | typeof warehousesTypes
>;

export interface IFloorplansState {
  readonly error?: object;
  readonly floorplans: IncompleteFloorplans;
  readonly loading: boolean;
}

export const initialState: IFloorplansState = {
  error: undefined,
  floorplans: {},
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IFloorplansState = initialState,
  action: FloorplansAction
): IFloorplansState => {
  switch (action.type) {
    case getType(types.fetchFloorplan.request):
    case getType(types.createFloorplan.request):
    case getType(types.deleteFloorplan.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.wsCreateFloorplan):
    case getType(types.wsUpdateFloorplan): {
      const floorplanId = action.payload.floorplan.id;

      return {
        ...state,
        floorplans: {
          ...state.floorplans,
          [floorplanId]: action.payload.floorplan,
        },
      };
    }

    case getType(types.fetchFloorplan.success):
      return {
        ...state,
        error: undefined,
        floorplans: {
          ...state.floorplans,
          [action.payload.floorplan.id]: action.payload.floorplan,
        },
        loading: false,
      };

    case getType(types.createFloorplan.success): {
      const floorplanId = action.payload.floorplan.id;

      return {
        ...state,
        error: undefined,
        floorplans: {
          ...state.floorplans,
          [floorplanId]: action.payload.floorplan,
        },
        loading: false,
      };
    }

    case getType(types.wsDeleteFloorplan): {
      return {
        ...state,
        floorplans: omitBy(state.floorplans, [
          'id',
          action.payload.floorplanId,
        ]),
      };
    }

    case getType(types.deleteFloorplan.success): {
      return {
        ...state,
        floorplans: omitBy(state.floorplans, [
          'id',
          action.payload.floorplanId,
        ]),
        loading: false,
      };
    }

    case getType(types.fetchFloorplan.failure):
    case getType(types.createFloorplan.failure):
    case getType(types.deleteFloorplan.failure):
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case getType(types.updateFloorplan.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.updateFloorplan.success): {
      const { floorplan, shallow } = action.payload;
      const { loading } = state;

      const floorplanId = floorplan.id;

      return {
        ...state,
        error: undefined,
        floorplans: {
          ...state.floorplans,
          [floorplanId]: action.payload.floorplan,
        },
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.updateFloorplan.failure): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: action.payload.error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(warehousesTypes.deleteWarehouse.success):
      return {
        ...state,
        floorplans: omitBy(
          state.floorplans,
          (f) => f.warehouseId === action.payload.id
        ),
        loading: false,
      };

    case getType(warehousesTypes.updateWarehouse.success): {
      const { floorplans } = action.payload;

      return {
        ...state,
        floorplans: { ...state.floorplans, ...floorplans },
        loading: false,
      };
    }

    case getType(warehousesTypes.fetchWarehouses.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(warehousesTypes.fetchWarehouses.success): {
      const { floorplans, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        floorplans,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(warehousesTypes.fetchWarehouses.failure): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(warehousesTypes.deleteAllWarehouses.success):
      return {
        ...state,
        floorplans: {},
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
