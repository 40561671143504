import { treatFetchError } from '@api/utils';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/routing';
import {
  addOrderToNewBasket,
  addOrderToCurrentBasket,
  abandonBasket,
  checkoutBasket,
  fetchOrders,
  fetchOrderItems,
  fetchCurrentBasket,
  removeBasketOrders,
  autoLocate,
  quickOrder,
  validateItemLocation,
  confirmValidateItemLocation,
  validateItemQuantity,
  confirmValidateItemQuantity,
  startPickingSession,
} from '@api/routing';
import { FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';

export function* fetchOrdersSaga(
  action: ActionType<typeof types.fetchOrders.request>
) {
  const { shallow } = action.payload;

  try {
    const orders: Order[] = yield call(fetchOrders);

    yield put(types.fetchOrders.success({ orders, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchOrders.failure({
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchOrderItemsSaga(
  action: ActionType<typeof types.fetchOrderItems.request>
) {
  const { orderId } = action.payload;

  try {
    const order: OrderWithItems = yield call(fetchOrderItems, orderId);

    yield put(types.fetchOrderItems.success({ order }));
  } catch (error: any) {
    yield put(
      types.fetchOrderItems.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* addOrderToCurrentBasketSaga(
  action: ActionType<typeof types.addOrderToCurrentBasket.request>
) {
  const { orderId } = action.payload;

  try {
    yield call(addOrderToCurrentBasket, orderId);

    yield put(types.addOrderToCurrentBasket.success());
  } catch (error: any) {
    yield put(
      types.addOrderToCurrentBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* addOrderToNewBasketSaga(
  action: ActionType<typeof types.addOrderToNewBasket.request>
) {
  const { orderId } = action.payload;

  try {
    yield call(addOrderToNewBasket, orderId);

    yield put(types.addOrderToNewBasket.success());
  } catch (error: any) {
    yield put(
      types.addOrderToNewBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* abandonBasketSaga(
  action: ActionType<typeof types.abandonBasket.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(abandonBasket, basketId);

    yield put(types.abandonBasket.success());
  } catch (error: any) {
    yield put(
      types.abandonBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* checkoutBasketSaga(
  action: ActionType<typeof types.checkoutBasket.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(checkoutBasket, basketId);

    yield put(types.checkoutBasket.success());
  } catch (error: any) {
    yield put(
      types.checkoutBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchCurrentBasketSaga() {
  try {
    const basket: FullBasket | undefined = yield call(fetchCurrentBasket);

    yield put(types.fetchCurrentBasket.success(basket));
  } catch (error: any) {
    yield put(
      types.fetchCurrentBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* removeBasketOrdersSaga(
  action: ActionType<typeof types.removeBasketOrders.request>
) {
  const { basketId, orderIds } = action.payload;

  try {
    yield call(removeBasketOrders, basketId, orderIds);

    yield put(types.removeBasketOrders.success({ orderIds }));
  } catch (error: any) {
    yield put(
      types.removeBasketOrders.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* autoLocateSaga(
  action: ActionType<typeof types.autoLocate.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(autoLocate, basketId);

    yield put(types.autoLocate.success());
  } catch (error: any) {
    yield put(
      types.autoLocate.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* quickOrderSaga() {
  try {
    const order: FullOrder | undefined = yield call(quickOrder);

    yield put(types.quickOrder.success(order));
  } catch (error: any) {
    yield put(
      types.quickOrder.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* validateItemLocationSaga(
  action: ActionType<typeof types.validateItemLocation.request>
) {
  const { barcode, pickingSessionId } = action.payload;

  try {
    yield call(validateItemLocation, barcode, pickingSessionId);

    yield put(types.validateItemLocation.success());
  } catch (error: any) {
    yield put(
      types.validateItemLocation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* confirmValidateItemLocationSaga(
  action: ActionType<typeof types.confirmValidateItemLocation.request>
) {
  const { pickingTaskId } = action.payload;

  try {
    yield call(confirmValidateItemLocation, pickingTaskId);

    yield put(types.confirmValidateItemLocation.success());
  } catch (error: any) {
    yield put(
      types.confirmValidateItemLocation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* validateItemQuantitySaga(
  action: ActionType<typeof types.validateItemQuantity.request>
) {
  const { barcode, pickingSessionId, locationId } = action.payload;

  try {
    yield call(validateItemQuantity, barcode, pickingSessionId, locationId);

    yield put(types.validateItemQuantity.success());
  } catch (error: any) {
    yield put(
      types.validateItemQuantity.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* confirmValidateItemQuantitySaga(
  action: ActionType<typeof types.confirmValidateItemQuantity.request>
) {
  const { pickingTaskId, quantity } = action.payload;

  try {
    yield call(confirmValidateItemQuantity, pickingTaskId, quantity);

    yield put(types.confirmValidateItemQuantity.success());
  } catch (error: any) {
    yield put(
      types.confirmValidateItemQuantity.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* startPickingSessionSaga(
  action: ActionType<typeof types.startPickingSession.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(startPickingSession, basketId);

    yield put(types.startPickingSession.success());
  } catch (error: any) {
    yield put(
      types.startPickingSession.failure({
        error: treatFetchError(error),
      })
    );
  }
}
