import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/sensors';
import {
  fetchFloorplanSensorGroupStatus,
  fetchFloorplanSensors,
  fetchFloorplanSensorsStatus,
  fetchSensorGroupsByFloorplan,
  fetchSensorInfo,
  fetchSensorsData,
  createSensor,
} from '@api/sensors';
import { treatFetchError } from '@api/utils';
import { ISensorData } from '@api/websocket';
import { Date } from '@dashboard_utils/index';
import Sensor, { Sensors } from '@models/Sensor';
import { SensorGroups } from '@models/SensorGroup';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import { SensorsWithStatus } from '@models/SensorWithStatus';

export function* fetchFloorplanSensorsSaga(
  action: ActionType<typeof types.fetchFloorplanSensors.request>
) {
  const { floorplanId, shallow } = action.payload;

  try {
    const sensors: Sensors = yield call(fetchFloorplanSensors, floorplanId);

    yield put(
      types.fetchFloorplanSensors.success({ floorplanId, sensors, shallow })
    );
  } catch (error: any) {
    yield put(
      types.fetchFloorplanSensors.failure({
        floorplanId,
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchSensorInfoSaga(
  action: ActionType<typeof types.fetchSensorInfo.request>
) {
  const { sensorId } = action.payload;

  try {
    const sensor: Sensor = yield call(fetchSensorInfo, sensorId);

    yield put(types.fetchSensorInfo.success({ sensor }));
  } catch (error: any) {
    yield put(
      types.fetchSensorInfo.failure({ sensorId, error: treatFetchError(error) })
    );
  }
}

export function* fetchSensorGroupsByFloorplanSaga(
  action: ActionType<typeof types.fetchSensorGroupsByFloorplan.request>
) {
  const { floorplanId, shallow } = action.payload;

  try {
    const sensorGroups: SensorGroups = yield call(
      fetchSensorGroupsByFloorplan,
      floorplanId
    );

    yield put(
      types.fetchSensorGroupsByFloorplan.success({
        floorplanId,
        sensorGroups,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchSensorGroupsByFloorplan.failure({
        floorplanId,
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchFloorplanSensorsStatusSaga(
  action: ActionType<typeof types.fetchFloorplanSensorsStatus.request>
) {
  const { floorplanId, shallow } = action.payload;

  try {
    const sensors: SensorsWithStatus = yield call(
      fetchFloorplanSensorsStatus,
      floorplanId
    );

    yield put(
      types.fetchFloorplanSensorsStatus.success({
        floorplanId,
        sensors,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchFloorplanSensorsStatus.failure({
        floorplanId,
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchFloorplanSensorGroupStatusSaga(
  action: ActionType<typeof types.fetchFloorplanSensorGroupStatus.request>
) {
  const { floorplanId, shallow } = action.payload;

  try {
    const sensorGroup: SensorGroupWithStatus = yield call(
      fetchFloorplanSensorGroupStatus,
      floorplanId
    );

    yield put(
      types.fetchFloorplanSensorGroupStatus.success({
        floorplanId,
        sensorGroup,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchFloorplanSensorGroupStatus.failure({
        floorplanId,
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchSensorsDataSaga(
  action: ActionType<typeof types.fetchSensorsData.request>
) {
  const { assetIds, floorplanId, timestamp } = action.payload;

  try {
    const data: Record<string, ISensorData[]> = yield call(
      fetchSensorsData,
      floorplanId,
      assetIds,
      timestamp
    );

    yield put(types.fetchSensorsData.success({ floorplanId, data, timestamp }));
  } catch (error: any) {
    yield put(
      types.fetchSensorsData.failure({
        assetIds,
        error: treatFetchError(error),
        floorplanId,
        timestamp,
        lastErrorTs: Date.now(),
      })
    );
  }
}

export function* createSensorSaga(
  action: ActionType<typeof types.createSensor.request>
) {
  const { floorplanId, sensorGroupId, physicalAddress, type, position } = action.payload;

  try {
    const data: Sensor = yield call(
      createSensor,
      floorplanId,
      sensorGroupId,
      physicalAddress,
      type,
      position,
    );

    yield put(types.createSensor.success({ sensor: data }));
  } catch (error: any) {
    yield put(
      types.createSensor.failure({
        error: treatFetchError(error),
      })
    );
  }
}
