import { AxiosError } from 'axios';
import { Zones } from '@models/Zone';

import FetchError from '@models/FetchError';
import { Coordinate2D } from '../utils';

export interface IAPIZone {
  accountId: string;
  color: string;
  coordinates: Coordinate2D[];
  created: string;
  id: string;
  name: string;
  type: string;
}

/**
 * Converts [elem] to map key => elem
 * @param  {any[]}               array Array
 * @return {Record<string, any>}
 */
export function convertArrayToIdMap<T extends { id: string }>(
  array: T[]
): Record<string, T> {
  const idMap = {} as any;

  array.forEach((elem) => {
    idMap[elem.id] = elem;
  });

  return idMap;
}

export function treatFetchError(error: AxiosError): FetchError {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    return {
      code: error.response.status,
      data: error,
      errorCode: (error.response.data || ({} as any)).code,
      errorMsg: (error.response.data || ({} as any)).message,
    };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return { code: 0, data: error };
  }
  // Something happened in setting up the request that triggered an Error
  return { code: -1, data: error };
}

export function normalizeZones(floorplanId: string, zones?: IAPIZone[]): Zones {
  if (zones === undefined) {
    return {};
  }

  return convertArrayToIdMap(zones.map((z) => ({ ...z, floorplanId })));
}
