import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/tickets';
import {
  createTicket,
  updateTicket,
  archiveTicket,
  deleteTicket,
  fetchTickets,
  createTicketComment,
  deleteTicketComment,
} from '@api/tickets';
import { treatFetchError } from '@api/utils';
import Ticket, { TicketComment, Tickets } from '@models/Ticket';

export function* createTicketSaga(
  action: ActionType<typeof types.createTicket.request>
) {
  const { subject, description, files, intl } = action.payload;

  try {
    const ticket: Ticket = yield call(
      createTicket,
      subject,
      description,
      files
    );

    yield put(types.createTicket.success({ ticket, intl }));
  } catch (error: any) {
    yield put(
      types.createTicket.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* updateTicketSaga(
  action: ActionType<typeof types.updateTicket.request>
) {
  const { id, subject, description, files, intl } = action.payload;

  try {
    const ticket: Ticket = yield call(
      updateTicket,
      id,
      subject,
      description,
      files
    );

    yield put(types.updateTicket.success({ ticket, intl }));
  } catch (error: any) {
    yield put(
      types.updateTicket.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* archiveTicketSaga(
  action: ActionType<typeof types.archiveTicket.request>
) {
  const { id, intl } = action.payload;

  try {
    const ticket: Ticket = yield call(archiveTicket, id);

    yield put(types.archiveTicket.success({ ticket, intl }));
  } catch (error: any) {
    yield put(
      types.archiveTicket.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deleteTicketSaga(
  action: ActionType<typeof types.deleteTicket.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteTicket, id);

    yield put(types.deleteTicket.success({ id, intl }));
  } catch (error: any) {
    yield put(
      types.deleteTicket.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchTicketsSaga(
  action: ActionType<typeof types.fetchTickets.request>
) {
  const { ticketStatus } = action.payload;

  try {
    const tickets: Tickets = yield call(fetchTickets, ticketStatus);

    yield put(types.fetchTickets.success(tickets));
  } catch (error: any) {
    yield put(types.fetchTickets.failure({ error: treatFetchError(error) }));
  }
}

export function* createTicketCommentSaga(
  action: ActionType<typeof types.createTicketComment.request>
) {
  const { ticketId, comment, intl } = action.payload;

  try {
    const c: TicketComment = yield call(createTicketComment, ticketId, comment);

    yield put(types.createTicketComment.success({ comment: c }));
  } catch (error: any) {
    yield put(
      types.createTicketComment.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deleteTicketCommentSaga(
  action: ActionType<typeof types.deleteTicketComment.request>
) {
  const { ticketId, id, intl } = action.payload;

  try {
    yield call(deleteTicketComment, ticketId, id);

    yield put(types.deleteTicketComment.success({ ticketId, id }));
  } catch (error: any) {
    yield put(
      types.deleteTicketComment.failure({ error: treatFetchError(error), intl })
    );
  }
}
