import { find, flatMap, map, merge } from 'lodash';

import { IDrawDefinition } from '@models/types';
import IncompleteFloorplan, {
  IncompleteFloorplans,
} from '@models/IncompleteFloorplan';
import Warehouse, { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import Zone, { Zones } from '@models/Zone';
import { TransformationMatrix2D } from '../utils';
import { convertArrayToIdMap, normalizeZones } from './utils';

export interface IAPIFloorplan {
  id: string;
  image?: string;
  size?: number;
  mimetype?: string;
  encoding?: string;
  scale?: number;
  name: string;
  obstacles: IDrawDefinition[];
  boundaries: IDrawDefinition[];
  racks: IDrawDefinition[];
  created: string;
  accountId: string;
  warehouseId: string;
  transformationMatrix?: TransformationMatrix2D;
  zones?: Zone[];
}

export interface IAPIWarehouse {
  id: string;
  name: string;
  site: string;
  timezone: string;
  country: string;
  metricsTotalPalletsMoved?: number;
  metricsFleetUsedCapacity?: number;
  accountId: string;
  organisationId: string;
  floorplans: IAPIFloorplan[];
  created: string;
}

export interface INormalizedIncompleteFloorplan {
  floorplan: IncompleteFloorplan;
  zones: Zones;
}

export interface INormalizedWarehouse {
  warehouse: Warehouse;
  floorplans: IncompleteFloorplans;
  zones: Zones;
}

export interface INormalizedWarehouses {
  warehouses: Warehouses;
  floorplans: IncompleteFloorplans;
  zones: Zones;
}

/**
 * Converts the IAPIFloorplan to a IncompleteFloorplan
 * @param  {IAPIFloorplan}       floorplan
 * @param  {string}              warehouseId
 * @return {IncompleteFloorplan}
 */
export function normalizeFloorplan(
  floorplan: IAPIFloorplan,
  warehouseId?: string
): INormalizedIncompleteFloorplan {
  const fp = {
    ...floorplan,
    warehouseId: warehouseId || floorplan.warehouseId,
  };
  delete fp.zones;

  return {
    floorplan: fp,
    zones: normalizeZones(floorplan.id, floorplan.zones),
  };
}

/**
 * Converts a warehouse to a INormalizedWarehouse.
 * Does not change the input parameter.
 */
export function normalizeWarehouse(
  warehouse: IAPIWarehouse
): INormalizedWarehouse {
  const normalizedWh = { ...warehouse, floorplans: undefined } as Warehouse;

  const normalizedFloorplans = (warehouse.floorplans || []).map((f) =>
    normalizeFloorplan(f, warehouse.id)
  );

  return {
    floorplans: convertArrayToIdMap(
      normalizedFloorplans.map((f) => f.floorplan)
    ),
    warehouse: normalizedWh,
    zones: merge({}, ...normalizedFloorplans.map((f) => f.zones)),
  };
}

/**
 * Converts warehouses and their floorplans to INormalizedWarehouses.
 * Does not change the input parameter.
 */
export function normalizeWarehouses(
  warehouses: IAPIWarehouse[]
): INormalizedWarehouses {
  const normalizedWhs = map(warehouses, (w) => normalizeWarehouse(w));

  /* eslint-disable @typescript-eslint/indent */
  const fpss: IncompleteFloorplans[] = flatMap<
    INormalizedWarehouse[],
    IncompleteFloorplans
  >(normalizedWhs, (nWh: any): IncompleteFloorplans => nWh.floorplans);
  /* eslint-enable @typescript-eslint/indent */

  const fps: IncompleteFloorplans = merge({}, ...fpss);
  const whs = map(normalizedWhs, (nWh) => nWh.warehouse);

  return {
    floorplans: fps,
    warehouses: convertArrayToIdMap(whs),
    zones: merge({}, ...normalizedWhs.map((nWh) => nWh.zones)),
  };
}
/**
 * A floorplan is only complete when it has an image, scale and boundaries, when
 * obstacles is an array (empty or not) and its sensors have been aligned,
 * which is represented by the presence of a transformation matrix.
 */
export function isFloorplanComplete<
  /* eslint-disable @typescript-eslint/indent */
  T extends Pick<
    IncompleteFloorplan,
    | 'image'
    | 'scale'
    | 'transformationMatrix'
    | 'geoMapping'
    | 'exteriorBoundaries'
    | 'boundaries'
    | 'obstacles'
    | 'racks'
  >
  /* eslint-enable @typescript-eslint/indent */
>({
  image,
  scale,
  transformationMatrix,
  geoMapping,
  exteriorBoundaries,
  boundaries,
  obstacles,
  racks,
}: T): boolean {
  return (
    !!image &&
    !!scale &&
    !!transformationMatrix &&
    !!geoMapping &&
    !!exteriorBoundaries &&
    !!boundaries &&
    !!obstacles &&
    !!racks
  );
}

export function isFloorplanIncomplete<
  /* eslint-disable @typescript-eslint/indent */
  T extends Pick<
    IncompleteFloorplan,
    | 'image'
    | 'scale'
    | 'transformationMatrix'
    | 'geoMapping'
    | 'exteriorBoundaries'
    | 'boundaries'
    | 'obstacles'
    | 'racks'
  >
  /* eslint-enable @typescript-eslint/indent */
>(floorplan: T): boolean {
  return !isFloorplanComplete(floorplan);
}

export function getFirstIncompleteFloorplan(
  warehouse?: WarehouseWithFloorplans
) {
  if (!warehouse) {
    return undefined;
  }

  return find(warehouse.floorplans, isFloorplanIncomplete);
}
