import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/tasks';
import { Task, Tasks } from '@models/Task';
import FetchError from '@models/FetchError';

type Actions = ActionType<typeof types | typeof auth>;

interface ITaskForm {
  active: boolean;
  error?: FetchError;
  data?: Task;
  loading: boolean;
}
export interface ITasksState {
  readonly tasks: Tasks;
  readonly error?: object;
  readonly form: ITaskForm;
  readonly loading: boolean;
}

export const initialState: ITasksState = {
  tasks: {},
  error: undefined,
  form: {
    active: false,
    loading: false,
  },
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ITasksState = initialState,
  action: Actions
): ITasksState => {
  switch (action.type) {
    case getType(types.createTask.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createTask.success): {
      const { task } = action.payload;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [task.id]: task,
        },
        error: undefined,
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.createTask.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.fetchTasks.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchTasks.success): {
      const { tasks, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        tasks,
        error: undefined,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchTasks.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.updateTask.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateTask.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.updateTask.success): {
      const { task } = action.payload;
console.log(task)
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [task.id]: task,
        },
        error: undefined,
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.deleteTask.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteTask.success):
      return {
        ...state,
        tasks: omit(state.tasks, action.payload.id),
        error: undefined,
        loading: false,
      };

    case getType(types.deleteTask.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.openTaskForm):
      return {
        ...state,
        form: {
          ...state.form,
          active: true,
          data: action.payload,
        },
      };

    case getType(types.closeTaskForm):
      return {
        ...state,
        form: {
          ...state.form,
          active: false,
          data: undefined,
        },
      };

    case getType(types.wsUpdateTask):
    case getType(types.wsCreateTask): {
      const { task } = action.payload;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [task.id]: task,
        },
      };
    }

    case getType(types.wsDeleteTask):
      return {
        ...state,
        tasks: omit(state.tasks, action.payload.id),
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
