import { IImport } from '@actions/types';
import OldOrder, { OldOrders } from '@models/Order';
import axios from './axios';
import { convertArrayToIdMap } from './utils';
import { IOrdersEvents } from './websocket';

export const ORDERS_API = {
  getOrdersWithItems: '/orders/withitems',
  postImportOrders: '/orders/import',
};

// Helpers
function normalizeOrder(data: OldOrder[]) {
  return convertArrayToIdMap(data);
}

export async function fetchOrdersWithItems(): Promise<OldOrders> {
  const { data } = await axios.get(ORDERS_API.getOrdersWithItems);

  return normalizeOrder(data);
}

export const fetchOrdersEvents = async (
  floorplanId: string,
): Promise<IOrdersEvents[]> => {
  const { data } = await axios.get(`/orders/events/floorplan/${floorplanId}`);

  return data;
};

export async function importOrders(properties: IImport): Promise<OldOrders> {
  const { data } = await axios.post(
    ORDERS_API.postImportOrders,
    properties.csv,
    {
      headers: { 'Content-Type': 'text/csv' },
    }
  );

  return normalizeOrder(data);
}
