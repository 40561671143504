import { ActionType, getType } from 'typesafe-actions';
import uuid from 'uuid/v4';

import * as app from '@actions/app';
import * as auth from '@actions/authentication';
import * as types from '@actions/filter';
import * as floorPlanTypes from '@actions/floorplans';
import * as plotTypes from '@actions/analytics';
import * as warehouseTypes from '@actions/warehouses';
import { getObjectFromLocalStorage } from '@app/utils/localStorageUtils';
import { Date, timeZone } from '@dashboard_utils/index';

const version = process.env.REACT_APP_VERSION;

export enum LAST {
  'CUSTOMIZED',
  'MIN5',
  'HOUR',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
}

export const getDateFromEnum = (
  last: LAST,
  warehouseTz: string,
  d: Date
): Date => {
  const date = new Date(warehouseTz, d.getTime());

  switch (last) {
    case LAST.CUSTOMIZED:
      date.setFullYear(0);
      break;
    case LAST.DAY:
      date.setDate(date.getDate() - 1);
      break;
    case LAST.HOUR:
      date.setHours(date.getHours() - 1);
      break;
    case LAST.MIN5:
      date.setMinutes(date.getMinutes() - 5);
      break;
    case LAST.MONTH:
      date.setMonth(date.getMonth() - 1);
      break;
    case LAST.WEEK:
      date.setDate(date.getDate() - 7);
      break;
    case LAST.YEAR:
      date.setFullYear(date.getFullYear() - 1);
      break;
    default:
      date.setDate(date.getDate() - 7);
      break;
  }

  return date;
};

/* eslint-disable @typescript-eslint/indent */
type FilterAction = ActionType<
  | typeof types
  | typeof floorPlanTypes
  | typeof plotTypes
  | typeof warehouseTypes
  | typeof app
  | typeof auth
>;
/* eslint-enable @typescript-eslint/indent */

export interface IFilterChange {
  id: string;
  assetIds?: string[];
  tags?: string[];
  eventIds?: string[];
  employeeIds?: string[];
  teamIds?: string[];
  floorplanId?: string;
  ruleIds?: string[];
  templateIds?: string[];
  warehouseId?: string;
  zoneIds?: string[];
  endDate?: Date;
  startDate?: Date;
  last?: LAST;
  filterUpdated?: boolean;
}

export interface IFilter extends IFilterChange {
  filterUpdated: boolean;
  last: LAST;
}
export interface IFilterEntry extends IFilter {
  autoRefresh: boolean;
}
export interface IFilterState {
  filters: Record<string, IFilterEntry>;
}

export const initialState = (): IFilterState => {
  const filters = (getObjectFromLocalStorage(`app_state_${version}_filters`) ||
    {}) as Record<string, IFilterEntry>;

  Object.keys(filters).forEach((filterId) => {
    if (filters[filterId].last === LAST.CUSTOMIZED) {
      if (filters[filterId].startDate) {
        filters[filterId].startDate = new Date(
          // Only exists for proper date parsing on local storage
          // based filter reconstruction
          // @ts-ignore
          filters[filterId].timeZone,
          filters[filterId].startDate,
          timeZone
        );
      }

      if (filters[filterId].endDate) {
        filters[filterId].endDate = new Date(
          // @ts-ignore
          filters[filterId].timeZone,
          filters[filterId].endDate,
          timeZone
        );
      }
    } else {
      filters[filterId].startDate = new Date(timeZone);
      filters[filterId].endDate = new Date(timeZone);
    }
  });

  return { filters };
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IFilterState = initialState(),
  action: FilterAction
): IFilterState => {
  switch (action.type) {
    case getType(types.onChange): {
      const { id } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          [id]: {
            ...(state.filters[id] || {}),
            filterUpdated: false,
            ...action.payload,
          },
        },
      };
    }

    case getType(types.onAutoRefreshChange): {
      const { id } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          [id]: {
            ...(state.filters[id] || {}),
            ...action.payload,
          },
        },
      };
    }

    case getType(types.addFilter): {
      const { filters } = state;

      return {
        ...state,
        filters: {
          ...filters,
          [action.payload.id]: {
            ...action.payload,
            autoRefresh: false,
          },
        },
      };
    }

    case getType(types.deleteFilter): {
      const { filters } = state;

      const newFilters = JSON.parse(JSON.stringify(filters));
      delete newFilters[action.payload.id];
      Object.keys(newFilters).forEach((fId) => {
        newFilters[fId].startDate = filters[fId].startDate;
        newFilters[fId].endDate = filters[fId].endDate;
      });

      return { ...state, filters: newFilters };
    }

    case getType(auth.updateAccountSettings.success): {
      const { accountMeta } = action.payload;

      if (accountMeta.showHiddenFloorplans === true) {
        return state;
      }

      const { filters } = state;

      const newFilters = JSON.parse(JSON.stringify(filters));
      Object.keys(newFilters).forEach((fId) => {
        newFilters[fId].startDate = filters[fId].startDate;
        newFilters[fId].endDate = filters[fId].endDate;
      });

      Object.keys(newFilters).forEach((k) => {
        newFilters[k] = {
          ...newFilters[k],
          assetIds: [],
          endDate: undefined,
          floorplanId: undefined,
          last: LAST.WEEK,
          startDate: undefined,
          warehouseId: undefined,
        };
      });

      return { ...state, filters: newFilters };
    }

    // On warehouse list update we need to check if selected warehouses still exist in the filter list
    case getType(warehouseTypes.fetchWarehouses.success): {
      const { floorplans, warehouses } = action.payload;
      const { filters } = state;

      let newFilters = JSON.parse(JSON.stringify(filters));
      Object.keys(newFilters).forEach((fId) => {
        newFilters[fId].startDate = filters[fId].startDate;
        newFilters[fId].endDate = filters[fId].endDate;
      });

      // Find filter entries where selected warehouse id does not exist in current warehouse list
      const missingWH = Object.keys(filters)
        .filter((k) => k !== 'simulator' && k !== 'popup')
        .find(
          (k) =>
            Object.keys(warehouses).indexOf(filters[k].warehouseId || '') === -1
        );

      /**
       * If there are warehouses and there are not filters or a filter is missing it's warehouse,
       * reset filter entries to the first warehouse entry
       */
      if (
        Object.keys(warehouses).length > 0 &&
        (!Object.keys(filters).length || missingWH)
      ) {
        const warehouseId = Object.keys(warehouses)[0];
        const floorplanId = (
          Object.values(floorplans).filter(
            (fp) => fp.warehouseId === warehouseId
          )[0] || {}
        ).id;

        const id = uuid();
        newFilters = {
          [id]: {
            id,
            assetIds: [],
            autoRefresh: false,
            warehouseId,
            floorplanId,
            filterUpdated: false,
            last: LAST.WEEK,
          },
        };
      }

      return {
        ...state,
        filters: newFilters,
      };
    }

    case getType(warehouseTypes.wsDeleteWarehouse):
    case getType(warehouseTypes.deleteWarehouse.success): {
      const { filters } = state;

      const newFilters = JSON.parse(JSON.stringify(filters));
      Object.keys(filters).forEach((k) => {
        if (newFilters[k].warehouseId === action.payload.id) {
          delete newFilters[k];
        }
      });
      Object.keys(newFilters).forEach((fId) => {
        newFilters[fId].startDate = filters[fId].startDate;
        newFilters[fId].endDate = filters[fId].endDate;
      });

      return { ...state, filters: newFilters };
    }

    case getType(floorPlanTypes.wsDeleteFloorplan):
    case getType(floorPlanTypes.deleteFloorplan.success): {
      const { filters } = state;

      const newFilters = JSON.parse(JSON.stringify(filters));
      Object.keys(newFilters).forEach((fId) => {
        newFilters[fId].startDate = filters[fId].startDate;
        newFilters[fId].endDate = filters[fId].endDate;
      });

      Object.keys(newFilters).forEach((k) => {
        if (newFilters[k].floorplanId === action.payload.floorplanId) {
          newFilters[k] = {
            ...newFilters[k],
            floorplanId: undefined,
            assetIds: [],
            zoneIds: [],
          };
        }
      });

      return { ...state, filters: newFilters };
    }

    case getType(plotTypes.fetchDataRange.success): {
      const { filterId, dataRange, warehouseTz } = action.payload;

      const filter = JSON.parse(JSON.stringify(state.filters[filterId] || {}));
      filter.startDate = (state.filters[filterId] || {}).startDate;
      filter.endDate = (state.filters[filterId] || {}).endDate;

      if (filter.last !== LAST.CUSTOMIZED) {
        const endDate = new Date(
          warehouseTz,
          dataRange.tots || new Date(warehouseTz, 0)
        );

        let startDate = getDateFromEnum(filter.last, warehouseTz, endDate);
        if (startDate.getTime() < dataRange.fromts) {
          startDate = new Date(
            warehouseTz,
            dataRange.fromts || new Date(warehouseTz, 0)
          );
        }
        filter.startDate = startDate;
        filter.endDate = endDate;
      }

      // Mark filter as updated (data range fetched)
      filter.filterUpdated = true;

      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: filter,
        },
      };
    }

    case getType(plotTypes.fetchDataRange.failure): {
      const { filterId } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            filterUpdated: true,
          },
        },
      };
    }

    case getType(app.toggleZoneMetrics): {
      const { id, filterId } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          popup: {
            ...(state.filters[filterId || ''] || {}),
            zoneIds: id ? [id] : [],
          },
        },
      };
    }

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState() };
    }

    default:
      return state;
  }
};
