import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/player';
import { fetchEventsActivity } from '@api/player';
import { treatFetchError } from '@api/utils';

export function* fetchEventsActivitySaga(
  action: ActionType<typeof types.fetchEventsActivity.request>
) {
  const {
    filterId,

    warehouseId,
    floorplanId,
    zoneIds,

    tags,
    assetIds,

    templateIds,
    ruleIds,

    from,
    to,

    locale,
    units,
  } = action.payload;

  try {
    const eventsActivity: types.IEventsActivity = yield call(
      fetchEventsActivity,

      warehouseId,
      floorplanId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      from,
      to,

      locale,
      units
    );

    yield put(types.fetchEventsActivity.success({ filterId, eventsActivity }));
  } catch (error: any) {
    yield put(
      types.fetchEventsActivity.failure({
        filterId,
        error: treatFetchError(error),
      })
    );
  }
}

export default {};
