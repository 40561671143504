import { createAsyncAction } from 'typesafe-actions';

import Organisation from '@models/Organisation';
import { IFailedWithShallow } from './types';

export enum OrganisationTypes {
  FETCH_INFO = '@@organisation/FETCH_INFO',
  FETCH_INFO_SUCCEEDED = '@@organisation/FETCH_INFO_SUCCEEDED',
  FETCH_INFO_FAILED = '@@organisation/FETCH_INFO_FAILED',
}

export interface IFetchOrganisationInformation {
  shallow?: boolean;
}
interface IFetchOrganisationInformationSuccess {
  shallow?: boolean;
  organisation: Organisation;
}

export const fetchOrganisationInformation = createAsyncAction(
  OrganisationTypes.FETCH_INFO,
  OrganisationTypes.FETCH_INFO_SUCCEEDED,
  OrganisationTypes.FETCH_INFO_FAILED
  // eslint-disable-next-line prettier/prettier
)<IFetchOrganisationInformation, IFetchOrganisationInformationSuccess, IFailedWithShallow>();
