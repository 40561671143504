import Plan, { Plans } from '@models/Plan';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export function normalizePlans(plans: Plan[]): Plans {
  return convertArrayToIdMap(plans);
}

export const PLANS_API = {
  postPlan: '/plans/new/',
  putPlan: '/plans/edit/',
};

export async function createPlan(
  name: string,
  floorplanId: string,
  plan: object
): Promise<Plan> {
  const { data } = await axios.post(PLANS_API.postPlan, {
    name,
    floorplanId,
    plan,
  });

  return data;
}

export async function updatePlan(
  id: string,
  name: string,
  floorplanId: string,
  plan: object
): Promise<Plan> {
  const { data } = await axios.put(PLANS_API.putPlan, {
    id,
    name,
    floorplanId,
    plan,
  });

  return data;
}

export async function deletePlan(id: string): Promise<void> {
  await axios.delete(`/plans/delete/id/${id}`);
}

export async function fetchPlans(floorplanId: string): Promise<Plans> {
  const { data } = await axios.get(`/plans/floorplan/${floorplanId}`);

  return normalizePlans(data);
}
