import { IEventsActivity } from '@actions/index';
import axios from './axios';

export const PLAYER_API = {
  postEventsActivity: '/analytics/data/activityperiods/',
};

export async function fetchEventsActivity(
  warehouseId: string | undefined,
  floorplanId: string | undefined,
  zoneIds: string[] | undefined,

  tags: string[] | undefined,
  assetIds: string[] | undefined,

  templateIds: string[] | undefined,
  ruleIds: string[] | undefined,

  from: number,
  to: number,

  locale: string,
  units: string
): Promise<IEventsActivity> {
  const { data } = await axios.post(PLAYER_API.postEventsActivity, {
    locale,
    filters: {
      warehouse: warehouseId ? [warehouseId] : [],
      floorplan: floorplanId ? [floorplanId] : [],
      zone: zoneIds || [],

      tag: tags || [],
      asset: assetIds || [],

      template: templateIds || [],
      rule: ruleIds || [],

      fromts: from,
      tots: to,
    },
    units,
  });

  return { assets: data };
}

export default {};
