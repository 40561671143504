import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/prechecks';
import {
  createPreCheck,
  createPreCheckAnswer,
  deletePreCheck,
  editPreCheck,
  fetchPreCheck,
  fetchPreCheckAnswersByAsset,
  fetchPreCheckList,
} from '@api/prechecks';
import PreCheck, { IAssetPreCheck, PreChecks } from '@models/PreCheck';

export function* createPreCheckSaga(
  action: ActionType<typeof types.createPreCheck.request>
) {
  const { name, questions } = action.payload;

  try {
    const preCheck: PreCheck = yield call(createPreCheck, name, questions);

    yield put(types.createPreCheck.success(preCheck));
  } catch (error: any) {
    yield put(types.createPreCheck.failure(error));
  }
}

export function* createPreCheckAnswerSaga(
  action: ActionType<typeof types.createPreCheckAnswer.request>
) {
  const { id, assetId, answers, intl } = action.payload;

  try {
    const preCheckAnswer: IAssetPreCheck = yield call(
      createPreCheckAnswer,
      id,
      assetId,
      answers
    );

    yield put(types.createPreCheckAnswer.success({ preCheckAnswer, intl }));
  } catch (error: any) {
    yield put(types.createPreCheckAnswer.failure(error));
  }
}

export function* editPreCheckSaga(
  action: ActionType<typeof types.editPreCheck.request>
) {
  const { id, name, questions } = action.payload;

  try {
    const preCheck: PreCheck = yield call(editPreCheck, id, name, questions);

    yield put(types.editPreCheck.success(preCheck));
  } catch (error: any) {
    yield put(types.editPreCheck.failure(error));
  }
}

export function* deletePreCheckSaga(
  action: ActionType<typeof types.deletePreCheck.request>
) {
  const { id } = action.payload;

  try {
    yield call(deletePreCheck, id);

    yield put(types.deletePreCheck.success(action.payload));
  } catch (error: any) {
    yield put(types.deletePreCheck.failure(error));
  }
}

export function* fetchPreCheckListSaga() {
  try {
    const preChecks: PreChecks = yield call(fetchPreCheckList);

    yield put(types.fetchPreCheckList.success(preChecks));
  } catch (error: any) {
    yield put(types.fetchPreCheckList.failure(error));
  }
}

export function* fetchPreCheckSaga(
  action: ActionType<typeof types.fetchPreCheck.request>
) {
  const { id } = action.payload;

  try {
    const preCheck: PreCheck = yield call(fetchPreCheck, id);

    yield put(types.fetchPreCheck.success(preCheck));
  } catch (error: any) {
    yield put(types.fetchPreCheck.failure(error));
  }
}

export function* fetchPreCheckAnswersByAssetSaga(
  action: ActionType<typeof types.fetchPreCheckAnswersByAsset.request>
) {
  const { assetId } = action.payload;

  try {
    const preCheckAnswers: IAssetPreCheck[] = yield call(
      fetchPreCheckAnswersByAsset,
      assetId
    );

    yield put(
      types.fetchPreCheckAnswersByAsset.success({ assetId, preCheckAnswers })
    );
  } catch (error: any) {
    yield put(types.fetchPreCheckAnswersByAsset.failure(error));
  }
}
