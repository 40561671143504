import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/simulations';
import FetchError from '@models/FetchError';
import { ISimulationData, ISimulatorConfig, Simulations } from '@models/Simulation';

type Actions = ActionType<typeof types | typeof auth>;

interface ISimulationsForm {
  readonly error?: FetchError;
  readonly loading: boolean;
}
export interface IIntervalState {
  erroredAttempts?: number;
  lastErrorTs?: number;
  loading: boolean;
}
interface IPlayerState {
  data: Record<string, ISimulationData[]>;
  error?: FetchError;
  loading: boolean;
  intervals: Record<string, IIntervalState>;
}
interface IRoutingState {
  paths: {
    data?: { path: [number, number][], orders: [number, number][] };
    error?: FetchError;
    loading: boolean;
  };
  config: {
    assetId?: string;
    data: ISimulatorConfig;
    error?: FetchError;
    loading: boolean;
  };
}
export interface ISimulationsState {
  readonly error?: FetchError;
  readonly form: ISimulationsForm;
  readonly loading: boolean;
  readonly player: Record<string, IPlayerState>;
  readonly simulations: Simulations;
  readonly byFloorplan: Record<string, Simulations>;
  readonly routing: Record<string, IRoutingState>;
}

export const initialState: ISimulationsState = {
  byFloorplan: {},
  error: undefined,
  form: { loading: false },
  loading: false,
  player: {},
  simulations: {},
  routing: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ISimulationsState = initialState,
  action: Actions
): ISimulationsState => {
  switch (action.type) {
    case getType(types.fetchSimulation.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchSimulation.success): {
      return {
        ...state,
        error: undefined,
        loading: false,
        simulations: {
          ...state.simulations,
          [action.payload.id]: action.payload,
        },
      };
    }

    case getType(types.fetchSimulation.failure):
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case getType(types.fetchSimulations.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchSimulations.success): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        byFloorplan: {
          ...state.byFloorplan,
          [action.payload.floorplanId]: {
            ...state.byFloorplan[action.payload.floorplanId],
            ...action.payload.simulations,
          },
        },
        error: undefined,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchSimulations.failure): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: action.payload.error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchSimulationData.request): {
      const { id, timestamp } = action.payload;

      return {
        ...state,
        player: {
          [id]: {
            ...(state.player[id] || {}),
            error: undefined,
            intervals: {
              ...((state.player[id] || {}).intervals || {}),
              [timestamp]: {
                ...(((state.player[id] || {}).intervals || {})[timestamp] ||
                  {}),
                loading: true,
              },
            },
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchSimulationData.success): {
      const { id, data, timestamp } = action.payload;

      const stateData = (state.player[id] || {}).data || {};
      Object.keys(data).forEach((k: any) => {
        stateData[k] = data[k];
      });

      return {
        ...state,
        error: undefined,
        loading: false,
        player: {
          [id]: {
            ...(state.player[id] || {}),
            data: stateData,
            error: undefined,
            intervals: {
              ...((state.player[id] || {}).intervals || {}),
              [timestamp]: {
                ...(((state.player[id] || {}).intervals || {})[timestamp] ||
                  {}),
                loading: false,
              },
            },
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchSimulationData.failure): {
      const { id, error, timestamp, lastErrorTs } = action.payload;

      return {
        ...state,
        player: {
          [id]: {
            ...(state.player[id] || {}),
            error,
            intervals: {
              ...((state.player[id] || {}).intervals || {}),
              [timestamp]: {
                ...(((state.player[id] || {}).intervals || {})[timestamp] ||
                  {}),
                erroredAttempts:
                  ((((state.player[id] || {}).intervals || {})[timestamp] || {})
                    .erroredAttempts || 0) + 1,
                lastErrorTs,
                loading: false,
              },
            },
            loading: false,
          },
        },
      };
    }

    case getType(types.onMyAssetChange): {
      const { floorplanId, assetId } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            config: {
              ...(state.routing[floorplanId] || {}).config,
              assetId,
            },
          },
        },
      };
    }

    case getType(types.fetchRoutingData.request): {
      const { floorplanId } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            config: {
              ...(state.routing[floorplanId] || {}).config,
              error: undefined,
              loading: true,
            },
          },
        },
      };
    }

    case getType(types.fetchRoutingData.success): {
      const { floorplanId, data } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            config: {
              ...(state.routing[floorplanId] || {}).config,
              data,
              error: undefined,
              loading: false,
            },
          },
        },
      };
    }

    case getType(types.fetchRoutingData.failure): {
      const { floorplanId, error } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            config: {
              ...(state.routing[floorplanId] || {}).config,
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(types.createRoutingPaths.request): {
      const { floorplanId } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            paths: {
              ...(state.routing[floorplanId] || {}).paths,
              data: undefined,
              error: undefined,
              loading: true,
            },
          },
        },
      };
    }
    case getType(types.createRoutingPaths.success): {
      const { floorplanId, data } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            paths: {
              ...(state.routing[floorplanId] || {}).paths,
              data,
              error: undefined,
              loading: false,
            },
          },
        },
      };
    }

    case getType(types.createRoutingPaths.failure): {
      const { floorplanId, error } = action.payload;

      return {
        ...state,
        routing: {
          ...state.routing,
          [floorplanId]: {
            ...(state.routing[floorplanId] || {}),
            paths: {
              ...(state.routing[floorplanId] || {}).paths,
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(types.createSimulation.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createSimulation.success): {
      return {
        ...state,
        byFloorplan: {
          ...state.byFloorplan,
          [action.payload.originalFloorplanId]: {
            ...state.byFloorplan[action.payload.originalFloorplanId],
            [action.payload.id]: action.payload,
          },
        },
        form: {
          error: undefined,
          loading: false,
        },
        simulations: {
          ...state.simulations,
          [action.payload.id]: action.payload,
        },
      };
    }

    case getType(types.createSimulation.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: action.payload.error,
          loading: false,
        },
      };

    case getType(types.deleteSimulation.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteSimulation.success): {
      const { simulations, byFloorplan } = state;

      Object.keys(byFloorplan).forEach((id) => {
        Object.keys(byFloorplan[id])
          .filter((simulationId) => simulationId === action.payload.id)
          .forEach((simulationId) => {
            delete byFloorplan[id][simulationId];
          });
      });
      delete simulations[action.payload.id];

      return {
        ...state,
        byFloorplan,
        error: undefined,
        loading: false,
        simulations,
      };
    }

    case getType(types.deleteSimulation.failure):
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case getType(types.wsCreateSimulation): {
      return {
        ...state,
        byFloorplan: {
          ...state.byFloorplan,
          [action.payload.originalFloorplanId]: {
            ...(state.byFloorplan[action.payload.originalFloorplanId] || {}),
            [action.payload.id]: action.payload,
          },
        },
        simulations: {
          ...state.simulations,
          [action.payload.id]: action.payload,
        },
      };
    }

    case getType(types.wsSimulationStatusUpdate): {
      let { simulations } = state;
      const { byFloorplan } = state;

      if (simulations[action.payload.id]) {
        simulations = {
          ...simulations,
          [action.payload.id]: {
            ...simulations[action.payload.id],
            progressPercentage: action.payload.progressPercentage,
            status: action.payload.status,
            statusCode: action.payload.statusCode,
          },
        };
      }

      Object.keys(byFloorplan).forEach((floorplan) => {
        if (byFloorplan[floorplan][action.payload.id]) {
          byFloorplan[floorplan] = {
            ...byFloorplan[floorplan],
            [action.payload.id]: {
              ...byFloorplan[floorplan][action.payload.id],
              progressPercentage: action.payload.progressPercentage,
              status: action.payload.status,
              statusCode: action.payload.statusCode,
            },
          };
        }
      });

      return { ...state, byFloorplan, simulations };
    }

    case getType(types.wsDeleteSimulation): {
      const { simulations } = state;
      delete simulations[action.payload.id];

      return {
        ...state,
        simulations,
      };
    }

    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
