import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { Shift, Shifts } from '@models/Shift';
import { IFailedWithShallow, IFeedbackFailed } from './types';

export enum ShiftsTypes {
  DELETE = '@@shifts/DELETE',
  DELETE_SUCCEEDED = '@@shifts/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@shifts/DELETE_FAILED',

  FETCH = '@@shifts/FETCH',
  FETCH_SUCCEEDED = '@@shifts/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@shifts/FETCH_FAILED',

  CREATE = '@@shifts/CREATE',
  CREATE_SUCCEEDED = '@@shifts/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@shifts/CREATE_FAILED',

  UPDATE = '@@shifts/UPDATE',
  UPDATE_SUCCEEDED = '@@shifts/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@shifts/UPDATE_FAILED',

  WS_CREATE = '@@shifts/WS_CREATE',
  WS_UPDATE = '@@shifts/WS_UPDATE',
  WS_DELETE = '@@shifts/WS_DELETE',
}

export interface ICreateShift {
  name: string;
  employees: string[];
  periods: Record<number, boolean>;
  intl: IntlShape;
}

export interface IUpdateShift extends ICreateShift {
  id: string;
}

export interface IDeleteShift {
  id: string;
  intl: IntlShape;
}

export interface IShiftSuccess {
  shift: Shift;
  intl: IntlShape;
}

export const deleteShift = createAsyncAction(
  ShiftsTypes.DELETE,
  ShiftsTypes.DELETE_SUCCEEDED,
  ShiftsTypes.DELETE_FAILED
)<IDeleteShift, IDeleteShift, IFeedbackFailed>();

export const createShift = createAsyncAction(
  ShiftsTypes.CREATE,
  ShiftsTypes.CREATE_SUCCEEDED,
  ShiftsTypes.CREATE_FAILED
)<ICreateShift, IShiftSuccess, IFeedbackFailed>();

export interface IFetchShifts {
  shallow?: boolean;
}
export interface IFetchShiftsSuccess {
  shifts: Shifts;
  shallow?: boolean;
}
export const fetchShifts = createAsyncAction(
  ShiftsTypes.FETCH,
  ShiftsTypes.FETCH_SUCCEEDED,
  ShiftsTypes.FETCH_FAILED
)<IFetchShifts, IFetchShiftsSuccess, IFailedWithShallow>();

export const updateShift = createAsyncAction(
  ShiftsTypes.UPDATE,
  ShiftsTypes.UPDATE_SUCCEEDED,
  ShiftsTypes.UPDATE_FAILED
)<IUpdateShift, IShiftSuccess, IFeedbackFailed>();

interface IWSShiftUpdated {
  shift: Shift;
}
interface IWSShiftDeleted {
  id: string;
}

export const wsCreateShift = createAction(
  ShiftsTypes.WS_CREATE,
  (data: IWSShiftUpdated) => data
)();
export const wsUpdateShift = createAction(
  ShiftsTypes.WS_UPDATE,
  (data: IWSShiftUpdated) => data
)();
export const wsDeleteShift = createAction(
  ShiftsTypes.WS_DELETE,
  (data: IWSShiftDeleted) => data
)();
