import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/shifts';
import { Shifts } from '@models/Shift';
import FetchError from '@models/FetchError';

type Actions = ActionType<typeof types | typeof auth>;

interface IShiftForm {
  active: boolean;
  error?: FetchError;
  loading: boolean;
}
export interface IShiftsState {
  readonly shifts: Shifts;
  readonly error?: object;
  readonly form: IShiftForm;
  readonly loading: boolean;
}

export const initialState: IShiftsState = {
  shifts: {},
  error: undefined,
  form: {
    active: false,
    loading: false,
  },
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IShiftsState = initialState,
  action: Actions
): IShiftsState => {
  switch (action.type) {
    case getType(types.createShift.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createShift.success): {
      const { shift } = action.payload;

      return {
        ...state,
        shifts: {
          ...state.shifts,
          [shift.id]: shift,
        },
        error: undefined,
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.createShift.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.fetchShifts.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchShifts.success): {
      const { shifts, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        shifts,
        error: undefined,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchShifts.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.updateShift.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateShift.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.updateShift.success): {
      const { shift } = action.payload;

      return {
        ...state,
        shifts: {
          ...state.shifts,
          [shift.id]: shift,
        },
        error: undefined,
        form: {
          active: false,
          loading: false,
        },
      };
    }

    case getType(types.deleteShift.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteShift.success):
      return {
        ...state,
        shifts: omit(state.shifts, action.payload.id),
        error: undefined,
        loading: false,
      };

    case getType(types.deleteShift.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.wsUpdateShift):
    case getType(types.wsCreateShift): {
      const { shift } = action.payload;

      return {
        ...state,
        shifts: {
          ...state.shifts,
          [shift.id]: shift,
        },
      };
    }

    case getType(types.wsDeleteShift):
      return {
        ...state,
        shifts: omit(state.shifts, action.payload.id),
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
