import { createAction } from 'typesafe-actions';

import { IFilter, IFilterChange } from '@reducers/filter';

export enum FilterTypes {
  ONCHANGE = '@@filter/ONCHANGE',
  ONAUTOREFRESHCHANGE = '@@filter/ONAUTOREFRESHCHANGE',
  ADD_FILTER = '@@filter/ADD_FILTER',
  DELETE_FILTER = '@@filter/DELETE_FILTER',
}

export const onChange = createAction(
  FilterTypes.ONCHANGE,
  (filters: IFilterChange) => filters
)();

export const onAutoRefreshChange = createAction(
  FilterTypes.ONAUTOREFRESHCHANGE,
  (id: string, autoRefresh: boolean) =>
    ({ id, autoRefresh })
)();

export const addFilter = createAction(
  FilterTypes.ADD_FILTER,
  (filter: IFilter) => filter
)();

export const deleteFilter = createAction(
  FilterTypes.DELETE_FILTER,
  (filter: { id: string }) => filter
)();
