import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/zones';
import { treatFetchError } from '@api/utils';
import { createZone, deleteZone, updateZone } from '@api/zones';
import Zone from '@models/Zone';

export function* createZoneSaga(
  action: ActionType<typeof types.createZone.request>
) {
  try {
    const zone: Zone = yield call(createZone, action.payload);

    yield put(types.createZone.success({ zone }));
  } catch (error: any) {
    yield put(types.createZone.failure({ error: treatFetchError(error) }));
  }
}

export function* updateZoneSaga(
  action: ActionType<typeof types.updateZone.request>
) {
  try {
    const zone: Zone = yield call(updateZone, action.payload);

    yield put(types.updateZone.success({ zone }));
  } catch (error: any) {
    yield put(
      types.updateZone.failure({
        id: action.payload.id,
        error: treatFetchError(error),
      })
    );
  }
}

export function* deleteZoneSaga(
  action: ActionType<typeof types.deleteZone.request>
) {
  try {
    yield call(deleteZone, action.payload);

    yield put(types.deleteZone.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteZone.failure({ error: treatFetchError(error) }));
  }
}
