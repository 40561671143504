import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/floorplans';
import {
  createFloorplan,
  deleteFloorplan,
  fetchFloorplan,
  updateFloorplan,
  updateFloorplanImage,
} from '@api/floorplans';
import { treatFetchError } from '@api/utils';
import { INormalizedIncompleteFloorplan } from '@api/warehousesUtils';

export function* fetchFloorplanSaga(
  action: ActionType<typeof types.fetchFloorplan.request>
) {
  try {
    const { id } = action.payload;

    const floorplan: INormalizedIncompleteFloorplan = yield call(
      fetchFloorplan,
      id
    );

    yield put(types.fetchFloorplan.success(floorplan));
  } catch (error: any) {
    yield put(types.fetchFloorplan.failure({ error: treatFetchError(error) }));
  }
}

export function* createFloorplanSaga(
  action: ActionType<typeof types.createFloorplan.request>
) {
  const { intl, warehouseId, properties } = action.payload;
  const { image, name, ...props } = properties;

  try {
    let { floorplan, zones }: INormalizedIncompleteFloorplan = yield call(
      createFloorplan,
      warehouseId,
      { name }
    );

    const floorplanId = floorplan.id;

    try {
      if (image) {
        const normalizedFloorplan: INormalizedIncompleteFloorplan = yield call(
          updateFloorplanImage,
          warehouseId,
          floorplanId,
          image
        );

        floorplan = normalizedFloorplan.floorplan;
        zones = normalizedFloorplan.zones;
      }

      if (props.transformationMatrix) {
        const normalizedFloorplan: INormalizedIncompleteFloorplan = yield call(
          updateFloorplan,
          warehouseId,
          floorplanId,
          props
        );

        floorplan = normalizedFloorplan.floorplan;
        zones = normalizedFloorplan.zones;
      }

      yield put(
        types.createFloorplan.success({
          floorplan,
          warehouseId,
          zones,
        })
      );
    } catch (error: any) {
      yield call(deleteFloorplan, floorplanId);

      yield put(
        types.createFloorplan.failure({ error: treatFetchError(error), intl })
      );
    }
  } catch (error: any) {
    yield put(
      types.createFloorplan.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* updateFloorplanSaga(
  action: ActionType<typeof types.updateFloorplan.request>
) {
  const { warehouseId, floorplanId, properties, intl, shallow } = action.payload;
  const { image } = properties;

  try {
    let { floorplan, zones }: INormalizedIncompleteFloorplan = yield call(
      updateFloorplan,
      warehouseId,
      floorplanId,
      properties
    );

    if (image) {
      const normalizedFloorplan: INormalizedIncompleteFloorplan = yield call(
        updateFloorplanImage,
        warehouseId,
        floorplanId,
        image
      );

      floorplan = normalizedFloorplan.floorplan;
      zones = normalizedFloorplan.zones;
    }

    yield put(
      types.updateFloorplan.success({ warehouseId, floorplan, zones, intl, shallow })
    );
  } catch (error: any) {
    yield put(
      types.updateFloorplan.failure({ error: treatFetchError(error), intl, shallow })
    );
  }
}

export function* deleteFloorplanSaga(
  action: ActionType<typeof types.deleteFloorplan.request>
) {
  const { floorplanId, warehouseId, intl } = action.payload;

  try {
    yield call(deleteFloorplan, floorplanId);

    yield put(types.deleteFloorplan.success({ floorplanId, warehouseId }));
  } catch (error: any) {
    yield put(
      types.deleteFloorplan.failure({ error: treatFetchError(error), intl })
    );
  }
}
