import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/scans';

type ScansAction = ActionType<typeof types | typeof auth>;

export interface IScansState {
  readonly scan: {
    error?: object;
    loading: boolean;
  };
}

export const initialState: IScansState = {
  scan: { loading: false },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IScansState = initialState,
  action: ScansAction
): IScansState => {
  switch (action.type) {
    case getType(types.createScan.request): {
      return {
        ...state,
        scan: {
          error: undefined,
          loading: true,
        },
      };
    }

    case getType(types.createScan.success): {
      return {
        ...state,
        scan: {
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(types.createScan.failure): {
      const { error } = action.payload;

      return {
        ...state,
        scan: {
          error,
          loading: false,
        },
      };
    }

    case getType(types.wsCreateScan): {
      return {
        ...state,
      };
    }
    
    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
