import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/teams';
import {
  createTeam,
  deleteTeam,
  fetchTeams,
  updateTeam,
} from '@api/teams';
import { treatFetchError } from '@api/utils';
import { Team, Teams } from '@models/Team';

export function* deleteTeamSaga(
  action: ActionType<typeof types.deleteTeam.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteTeam, id);

    yield put(types.deleteTeam.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteTeam.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* createTeamSaga(
  action: ActionType<typeof types.createTeam.request>
) {
  const { intl, name, employees } = action.payload;

  try {
    const team: Team = yield call(
      createTeam,
      name,
      employees
    );

    yield put(types.createTeam.success({ team, intl }));
  } catch (error: any) {
    yield put(
      types.createTeam.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchTeamsSaga(
  action: ActionType<typeof types.fetchTeams.request>
) {
  const { shallow } = action.payload;

  try {
    const teams: Teams = yield call(fetchTeams);

    yield put(types.fetchTeams.success({ teams, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchTeams.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* updateTeamSaga(
  action: ActionType<typeof types.updateTeam.request>
) {
  const { id, intl, name, employees } = action.payload;

  try {
    const team: Team = yield call(
      updateTeam,
      id,
      name,
      employees,
    );

    yield put(types.updateTeam.success({ team, intl }));
  } catch (error: any) {
    yield put(
      types.updateTeam.failure({ error: treatFetchError(error), intl })
    );
  }
}
