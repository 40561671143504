import { AssignAssetTicketSuccess } from '@actions/index';
import Asset, { Assets } from '@models/Asset';
import AssetTicket, {
  AssetTickets,
  AssetTicketComment,
  AssetTicketStatus,
  AssetTicketOperation,
} from '@models/AssetTicket';
import AssetDocument, {
  AssetDocuments,
  AssetDocumentTypes,
} from '@models/AssetDocument';
import { IncompleteSensorAssociation } from '@models/IncompleteSensorAssociation';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export const ASSET_API = {
  getAssets: '/assets/',
  postAsset: '/assets/new',
  postAssetTicket: '/assets/tickets/new',
  postAssetTicketComment: '/assets/tickets/comments/new',
  postAssetTicketOperation: '/assets/tickets/operations/new',
  postAssetDocument: '/assets/documents/new',
  putAsset: '/assets/edit',
  putAssetTicketAssign: '/assets/tickets/assign',
  putAssetTicketStatus: '/assets/tickets/status',
  putArchiveAssetTicket: '/assets/tickets/archive',
};

function assetToFormData(
  color: string,
  file: File | undefined,
  image: string | undefined,
  floorplanId: string,
  id: string,
  name: string,
  sensorAssociations: IncompleteSensorAssociation[] | undefined,
  tags: string[],
  type: string,
  warehouseId: string
): FormData {
  const formData = new FormData();

  formData.append('color', color);
  formData.append('floorplanId', floorplanId);
  if (id !== '') {
    formData.append('id', id);
  }
  if (file !== undefined) {
    formData.append('image', file);
  } else if (image !== undefined) {
    formData.append('image', image);
  } else {
    formData.append('image', 'null');
  }
  formData.append('name', name);
  formData.append('sensorAssociations', JSON.stringify(sensorAssociations));
  formData.append('tags', JSON.stringify(tags));
  formData.append('type', type);
  formData.append('warehouseId', warehouseId);

  return formData;
}

export async function createAsset(
  color: string,
  file: File | undefined,
  floorplanId: string,
  name: string,
  sensorAssociations: IncompleteSensorAssociation[] | undefined,
  tags: string[],
  type: string,
  warehouseId: string
): Promise<Asset> {
  const { data: asset } = await axios.post(
    ASSET_API.postAsset,
    assetToFormData(
      color,
      file,
      undefined,
      floorplanId,
      '',
      name,
      sensorAssociations,
      tags,
      type,
      warehouseId
    )
  );

  return asset;
}

export async function updateAssetPreCheck(
  id: string,
  preCheckId: string | null
): Promise<void> {
  const { data: asset } = await axios.put(ASSET_API.putAsset, {
    id,
    preCheckId,
  });

  return asset;
}

export async function updateAsset(
  color: string,
  file: File | undefined,
  image: string | undefined,
  floorplanId: string,
  id: string,
  name: string,
  sensorAssociations: IncompleteSensorAssociation[] | undefined,
  tags: string[],
  type: string,
  warehouseId: string
): Promise<void> {
  const { data: asset } = await axios.put(
    ASSET_API.putAsset,
    assetToFormData(
      color,
      file,
      image,
      floorplanId,
      id,
      name,
      sensorAssociations,
      tags,
      type,
      warehouseId
    )
  );

  return asset;
}

export async function fetchAssets(): Promise<Assets> {
  const { data } = await axios.get(ASSET_API.getAssets);

  return convertArrayToIdMap(data);
}

export async function deleteAsset(assetId: string): Promise<void> {
  await axios.delete(`/assets/delete/id/${assetId}`);
}

export async function fetchAssetTicket(ticketId: string): Promise<AssetTicket> {
  const { data } = await axios.get(`/assets/tickets/id/${ticketId}`);

  return data;
}

export async function fetchAssetTickets(
  assetId: string
): Promise<AssetTickets> {
  const { data } = await axios.get(`/assets/tickets/byasset/id/${assetId}`);

  return convertArrayToIdMap(data);
}

export async function fetchAssetDocuments(
  ticketId: string
): Promise<AssetDocuments> {
  const { data } = await axios.get(`/assets/documents/byasset/id/${ticketId}`);

  return convertArrayToIdMap(data);
}

export async function createAssetTicket(
  assetId: string,
  type: string,
  eventDate: Date | undefined,
  subject: string,
  description: string,
  workImpairingIssue: boolean,
  files: File[]
): Promise<AssetTicket> {
  const formData = new FormData();
  formData.append('assetId', assetId);
  formData.append('type', type);
  if (eventDate) {
    formData.append('eventDate', eventDate.toISOString());
  }
  formData.append('subject', subject);
  formData.append('description', description);
  formData.append('workImpairingIssue', String(workImpairingIssue));
  files.forEach((file) => formData.append('files[]', file));

  const { data: ticket } = await axios.post(
    ASSET_API.postAssetTicket,
    formData
  );

  return ticket;
}

export async function createAssetTicketComment(
  ticketId: string,
  comment: string
): Promise<AssetTicketComment> {
  const { data: com } = await axios.post(ASSET_API.postAssetTicketComment, {
    ticketId,
    comment,
  });

  return com;
}

export async function assignAssetTicket(
  ticketId: string,
  accountId: string
): Promise<AssignAssetTicketSuccess> {
  const { data: assign } = await axios.put(ASSET_API.putAssetTicketAssign, {
    ticketId,
    accountId,
  });

  return assign;
}

export async function changeAssetTicketStatus(
  ticketId: string,
  status: AssetTicketStatus
): Promise<void> {
  await axios.put(ASSET_API.putAssetTicketStatus, {
    ticketId,
    status,
  });
}

export async function archiveAssetTicket(ticketId: string): Promise<void> {
  await axios.put(ASSET_API.putArchiveAssetTicket, { ticketId });
}

export async function createAssetTicketOperation(
  ticketId: string,
  name: string,
  description: string,
  cost: number
): Promise<AssetTicketOperation> {
  const { data: operation } = await axios.post(
    ASSET_API.postAssetTicketOperation,
    {
      ticketId,
      name,
      description,
      cost,
    }
  );

  return operation;
}

export async function deleteAssetTicketOperation(id: string): Promise<void> {
  await axios.delete(`/assets/tickets/operations/delete/id/${id}`);
}

export async function createAssetDocument(
  assetId: string,
  type: AssetDocumentTypes,
  name: string,
  file: File
): Promise<AssetDocument> {
  const formData = new FormData();
  formData.append('assetId', assetId);
  formData.append('type', type);
  formData.append('name', name);
  formData.append('file', file);

  const { data: document } = await axios.post(
    ASSET_API.postAssetDocument,
    formData
  );

  return document;
}

export async function deleteAssetDocument(id: string): Promise<void> {
  await axios.delete(`/assets/documents/delete/id/${id}`);
}
