import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { Team, Teams } from '@models/Team';
import { IFailedWithShallow, IFeedbackFailed } from './types';

export enum TeamsTypes {
  DELETE = '@@teams/DELETE',
  DELETE_SUCCEEDED = '@@teams/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@teams/DELETE_FAILED',

  FETCH = '@@teams/FETCH',
  FETCH_SUCCEEDED = '@@teams/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@teams/FETCH_FAILED',

  CREATE = '@@teams/CREATE',
  CREATE_SUCCEEDED = '@@teams/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@teams/CREATE_FAILED',

  UPDATE = '@@teams/UPDATE',
  UPDATE_SUCCEEDED = '@@teams/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@teams/UPDATE_FAILED',

  WS_CREATE = '@@teams/WS_CREATE',
  WS_UPDATE = '@@teams/WS_UPDATE',
  WS_DELETE = '@@teams/WS_DELETE',

  OPENFORM = '@@teams/OPENFORM',
  CLOSEFORM = '@@teams/CLOSEFORM',
}

export interface ICreateTeam {
  name: string;
  employees: string[];
  intl: IntlShape;
}

export interface IEditTeam extends ICreateTeam {
  id: string;
}

export interface IDeleteTeam {
  id: string;
  intl: IntlShape;
}

export interface ITeamSuccess {
  team: Team;
  intl: IntlShape;
}

export const deleteTeam = createAsyncAction(
  TeamsTypes.DELETE,
  TeamsTypes.DELETE_SUCCEEDED,
  TeamsTypes.DELETE_FAILED
)<IDeleteTeam, IDeleteTeam, IFeedbackFailed>();

export const createTeam = createAsyncAction(
  TeamsTypes.CREATE,
  TeamsTypes.CREATE_SUCCEEDED,
  TeamsTypes.CREATE_FAILED
)<ICreateTeam, ITeamSuccess, IFeedbackFailed>();

export interface IFetchTeams {
  shallow?: boolean;
}
export interface IFetchTeamsSuccess {
  teams: Teams;
  shallow?: boolean;
}
export const fetchTeams = createAsyncAction(
  TeamsTypes.FETCH,
  TeamsTypes.FETCH_SUCCEEDED,
  TeamsTypes.FETCH_FAILED
)<IFetchTeams, IFetchTeamsSuccess, IFailedWithShallow>();

export const updateTeam = createAsyncAction(
  TeamsTypes.UPDATE,
  TeamsTypes.UPDATE_SUCCEEDED,
  TeamsTypes.UPDATE_FAILED
)<IEditTeam, ITeamSuccess, IFeedbackFailed>();

export const openTeamForm = createAction(
  TeamsTypes.OPENFORM,
  (data?: Team) => data
)();
export const closeTeamForm = createAction(
  TeamsTypes.CLOSEFORM,
)();

interface IWSTeamUpdated {
  team: Team;
}
interface IWSTeamDeleted {
  id: string;
}

export const wsCreateTeam = createAction(
  TeamsTypes.WS_CREATE,
  (data: IWSTeamUpdated) => data
)();
export const wsUpdateTeam = createAction(
  TeamsTypes.WS_UPDATE,
  (data: IWSTeamUpdated) => data
)();
export const wsDeleteTeam = createAction(
  TeamsTypes.WS_DELETE,
  (data: IWSTeamDeleted) => data
)();
