import { createAction, createAsyncAction } from 'typesafe-actions';

import { ISensorData } from '@api/websocket';
import FetchError from '@models/FetchError';
import Sensor, { Sensors } from '@models/Sensor';
import { SensorGroups } from '@models/SensorGroup';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import { SensorsWithStatus } from '@models/SensorWithStatus';

export enum SensorsTypes {
  FETCH_FLOORPLAN_SENSORS = '@@sensors/FETCH_FLOORPLAN_SENSORS',
  FETCH_FLOORPLAN_SENSORS_SUCCEEDED = '@@sensors/FETCH_FLOORPLAN_SENSORS_SUCCEEDED',
  FETCH_FLOORPLAN_SENSORS_FAILED = '@@sensors/FETCH_FLOORPLAN_SENSORS_FAILED',

  FETCH_SENSORINFO = '@@sensors/FETCH_SENSORINFO',
  FETCH_SENSORINFO_SUCCEEDED = '@@sensors/FETCH_SENSORINFO_SUCCEEDED',
  FETCH_SENSORINFO_FAILED = '@@sensors/FETCH_SENSORINFO_FAILED',

  FETCH_SENSORGROUPS_BYFLOORPLAN = '@@sensors/FETCH_SENSORGROUPS_BYFLOORPLAN',
  FETCH_SENSORGROUPS_BYFLOORPLAN_SUCCEEDED = '@@sensors/FETCH_SENSORGROUPS_BYFLOORPLAN_SUCCEEDED',
  FETCH_SENSORGROUPS_BYFLOORPLAN_FAILED = '@@sensors/FETCH_SENSORGROUPS_BYFLOORPLAN_FAILED',

  FETCH_FLOORPLANSENSORSSTATUS = '@@sensors/FETCH_FLOORPLANSENSORSSTATUS',
  FETCH_FLOORPLANSENSORSSTATUS_SUCCEEDED = '@@sensors/FETCH_FLOORPLANSENSORSSTATUS_SUCCEEDED',
  FETCH_FLOORPLANSENSORSSTATUS_FAILED = '@@sensors/FETCH_FLOORPLANSENSORSSTATUS_FAILED',

  FETCH_FLOORPLANSENSORGROUPSTATUS = '@@sensors/FETCH_FLOORPLANSENSORGROUPSTATUS',
  FETCH_FLOORPLANSENSORGROUPSTATUS_SUCCEEDED = '@@sensors/FETCH_FLOORPLANSENSORGROUPSTATUS_SUCCEEDED',
  FETCH_FLOORPLANSENSORGROUPSTATUS_FAILED = '@@sensors/FETCH_FLOORPLANSENSORGROUPSTATUS_FAILED',

  FETCH_SENSORSDATARANGE = '@@sensors/FETCH_SENSORSDATARANGE',
  FETCH_SENSORSDATARANGE_SUCCEEDED = '@@sensors/FETCH_SENSORSDATARANGE_SUCCEEDED',
  FETCH_SENSORSDATARANGE_FAILED = '@@sensors/FETCH_SENSORSDATARANGE_FAILED',

  FETCH_SENSORSDATA = '@@sensors/FETCH_SENSORSDATA',
  FETCH_SENSORSDATA_SUCCEEDED = '@@sensors/FETCH_SENSORSDATA_SUCCEEDED',
  FETCH_SENSORSDATA_FAILED = '@@sensors/FETCH_SENSORSDATA_FAILED',

  CREATE = '@@sensors/CREATE',
  CREATE_SUCCEEDED = '@@sensors/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@sensors/CREATE_FAILED',
  
  WS_CREATE = '@@sensors/WS_CREATE',

  SEND_GPS = '@@sensors/SEND_GPS',
  SEND_GPS_SUCCEEDED = '@@sensors/SEND_GPS_SUCCEEDED',
  SEND_GPS_FAILED = '@@sensors/SEND_GPS_FAILED',
}

export interface IFetchFloorplanSensors {
  floorplanId: string;
  shallow?: boolean;
}

export interface IFetchFloorplanSensorsSucceeded {
  floorplanId: string;
  sensors: Sensors;
  shallow?: boolean;
}

export interface IFetchFloorplanSensorsFailed {
  error: FetchError;
  floorplanId: string;
  shallow?: boolean;
}

interface IWSSensorCreated {
  sensor: Sensor;
}

/* eslint-disable @typescript-eslint/indent */
export const fetchFloorplanSensors = createAsyncAction(
  SensorsTypes.FETCH_FLOORPLAN_SENSORS,
  SensorsTypes.FETCH_FLOORPLAN_SENSORS_SUCCEEDED,
  SensorsTypes.FETCH_FLOORPLAN_SENSORS_FAILED
)<
  IFetchFloorplanSensors,
  IFetchFloorplanSensorsSucceeded,
  IFetchFloorplanSensorsFailed
>();
/* eslint-enable @typescript-eslint/indent */

export interface IFetchSensorGroupsSucceeded {
  floorplanId: string;
  sensorGroups: SensorGroups;
  shallow?: boolean;
}

/* eslint-disable @typescript-eslint/indent */
export const fetchSensorGroupsByFloorplan = createAsyncAction(
  SensorsTypes.FETCH_SENSORGROUPS_BYFLOORPLAN,
  SensorsTypes.FETCH_SENSORGROUPS_BYFLOORPLAN_SUCCEEDED,
  SensorsTypes.FETCH_SENSORGROUPS_BYFLOORPLAN_FAILED
)<
  IFetchFloorplanSensors,
  IFetchSensorGroupsSucceeded,
  IFetchFloorplanSensorsFailed
>();
/* eslint-enable @typescript-eslint/indent */

export interface IFetchSensorInfo {
  sensorId: string;
}

export interface IFetchSensorInfoSucceeded {
  sensor: Sensor;
}

export interface IFetchSensorInfoFailed {
  error: object;
  sensorId: string;
}

export const fetchSensorInfo = createAsyncAction(
  SensorsTypes.FETCH_SENSORINFO,
  SensorsTypes.FETCH_SENSORINFO_SUCCEEDED,
  SensorsTypes.FETCH_SENSORINFO_FAILED
)<IFetchSensorInfo, IFetchSensorInfoSucceeded, IFetchSensorInfoFailed>();

export interface IFetchSensorsStatusSucceeded {
  floorplanId: string;
  sensors: SensorsWithStatus;
  shallow?: boolean;
}

/* eslint-disable @typescript-eslint/indent */
export const fetchFloorplanSensorsStatus = createAsyncAction(
  SensorsTypes.FETCH_FLOORPLANSENSORSSTATUS,
  SensorsTypes.FETCH_FLOORPLANSENSORSSTATUS_SUCCEEDED,
  SensorsTypes.FETCH_FLOORPLANSENSORSSTATUS_FAILED
)<
  IFetchFloorplanSensors,
  IFetchSensorsStatusSucceeded,
  IFetchFloorplanSensorsFailed
>();
/* eslint-enable @typescript-eslint/indent */

export interface IFetchSensorGroupStatusSucceeded {
  floorplanId: string;
  sensorGroup: SensorGroupWithStatus;
  shallow?: boolean;
}

/* eslint-disable @typescript-eslint/indent */
export const fetchFloorplanSensorGroupStatus = createAsyncAction(
  SensorsTypes.FETCH_FLOORPLANSENSORGROUPSTATUS,
  SensorsTypes.FETCH_FLOORPLANSENSORGROUPSTATUS_SUCCEEDED,
  SensorsTypes.FETCH_FLOORPLANSENSORGROUPSTATUS_FAILED
)<
  IFetchFloorplanSensors,
  IFetchSensorGroupStatusSucceeded,
  IFetchFloorplanSensorsFailed
>();
/* eslint-enable @typescript-eslint/indent */

export interface IFetchSensorsData extends IFetchFloorplanSensors {
  assetIds: string[];
  timestamp: number;
}

export interface IFetchSensorsDataSucceeded {
  floorplanId: string;
  data: Record<string, ISensorData[]>;
  timestamp: number;
}

export interface IFetchSensorsDataFailed extends IFetchFloorplanSensorsFailed {
  assetIds: string[];
  timestamp: number;
  lastErrorTs: number;
}

export const fetchSensorsData = createAsyncAction(
  SensorsTypes.FETCH_SENSORSDATA,
  SensorsTypes.FETCH_SENSORSDATA_SUCCEEDED,
  SensorsTypes.FETCH_SENSORSDATA_FAILED
)<IFetchSensorsData, IFetchSensorsDataSucceeded, IFetchSensorsDataFailed>();


export interface ICreateSensor {
  floorplanId: string;
  sensorGroupId: string;
  type: 'mobile_app';
  physicalAddress: string;
  position: [number, number, number];
}

export interface ICreateSensorSuccess {
  sensor: Sensor;
}

export interface ICreateSensorFailed {
  error: object;
}

export const createSensor = createAsyncAction(
  SensorsTypes.CREATE,
  SensorsTypes.CREATE_SUCCEEDED,
  SensorsTypes.CREATE_FAILED
)<ICreateSensor, ICreateSensorSuccess, ICreateSensorFailed>();

export const wsCreateSensor = createAction(
  SensorsTypes.WS_CREATE,
  (data: IWSSensorCreated) => data
)();
