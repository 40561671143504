import { IAgentInfo } from '@actions/simulations';
import { ISimulationData, ISimulatorConfig, Simulation, Simulations } from '@models/Simulation';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export const SIMULATIONS_API = {
  postSimulation: '/simulations',
  putSimulationsStop: '/simulations/stop',
};

// Helpers
function normalizeSimulations(simulations: Simulation[]) {
  return convertArrayToIdMap(simulations);
}
export function normalizeSimulationData(
  sensorData: ISimulationData[]
): Record<string, ISimulationData[]> {
  const jsonData = {} as Record<string, ISimulationData[]>;

  for (let i = 0; i < sensorData.length; i += 1) {
    if (jsonData[sensorData[i].ts] === undefined) {
      jsonData[sensorData[i].ts] = [];
    }

    jsonData[sensorData[i].ts] = jsonData[sensorData[i].ts].concat(
      sensorData[i]
    );
  }

  return jsonData;
}

export async function fetchSimulation(id: string): Promise<Simulations> {
  const { data } = await axios.get(`/simulations/id/${id}`);

  return data;
}

export async function fetchSimulations(
  floorplanId: string
): Promise<Simulations> {
  const { data } = await axios.get(`/simulations/floorplan/${floorplanId}`);

  return normalizeSimulations(data);
}

export async function fetchRoutingData(
  floorplanId: string
): Promise<ISimulatorConfig> {
  const { data } = await axios.get(`/routings/floorplan/${floorplanId}`);

  return data;
}

export async function createRoutingPaths(
  floorplanId: string,
  position: [number, number],
  points: [number, number][]
): Promise<[number, number][]> {
  const { data } = await axios.post(
    '/routings/paths',
    { floorplanId, position, points }
  );

  return data;
}

export async function createSimulation(
  floorplanId: string,
  name: string,
  agents: IAgentInfo[],
  orderVolume: number,
  length: number,
  router: string | undefined,
  routerDynamic: boolean | undefined,
  modelFloorplanId: string,
  inputCsv: string | undefined,
  startTs: number,
  shifts: Record<number, boolean>[],
): Promise<Simulation> {
  const { data: simulation } = await axios.post(
    SIMULATIONS_API.postSimulation,
    {
      agents,
      floorplanId,
      length,
      name,
      orderVolume,
      router,
      routerDynamic,
      modelFloorplanId,
      inputCsv,
      startTs,
      shifts,
    }
  );

  return simulation;
}

export const deleteSimulation = async (id: string) =>
  axios.delete(`/simulations/delete/id/${id}`);

export const stopSimulation = async (id: string) => {
  await axios.put(SIMULATIONS_API.putSimulationsStop, { id });
};

export async function fetchSimulationData(
  id: string,
  timestamp: number
): Promise<Record<string, ISimulationData[]>> {
  const { data } = await axios.get(
    `/simulations/data/id/${id}/min/${timestamp}/max/${
      timestamp + 30 * 60 * 1000
    }`
  );

  return normalizeSimulationData(data);
}
