import ChatMessage from '@models/ChatMessage';
import axios from './axios';

export async function fetchMessages(accountId: string): Promise<ChatMessage[]> {
  const { data } = await axios.get(`/chats/messages/account/${accountId}`);

  return data;
}

export async function createMessage(
  toAccountId: string | undefined,
  message: string,
  broadcast = false,
): Promise<ChatMessage> {
  const { data } = await axios.post(
    '/chats/message',
    {
      toAccountId,
      message,
      broadcast,
    }
  );

  return data;
}
