import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { ActionType } from 'typesafe-actions';

import { clearLogin } from '@actions/index';
import { timeZone } from '@dashboard_utils/index';
import appReducer, { IAppState } from '@reducers/index';
import sagas from '@sagas/index';
import { saveObjectToLocalStorage } from '@app/utils/localStorageUtils';

const version = process.env.REACT_APP_VERSION || 0;

const rootReducer = (state: any, action: ActionType<any>): IAppState =>
  // if (action.type === '@@authentication/ACCOUNT_SUCCEEDED')
  appReducer(state, action);

const sagaMiddleware = createSagaMiddleware();

let middleware = applyMiddleware(sagaMiddleware);
if (process.env.NODE_ENV === 'development') {
  middleware = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(createLogger() as any)
  );
}

const store = createStore(rootReducer, middleware);
store.subscribe(() => {
  const state = store.getState();
  const { filters } = state.filter;

  const storageFilters = JSON.parse(JSON.stringify(filters));
  Object.keys(storageFilters).forEach((filterId) => {
    const activeTz = filters[filterId].startDate
      ? filters[filterId].startDate?.timeZone || timeZone
      : timeZone;

    storageFilters[filterId].timeZone = activeTz;
  });

  saveObjectToLocalStorage(`app_state_${version}_filters`, filters);
  saveObjectToLocalStorage(
    `app_state_${version}_plots_activeMetrics`,
    state.analytics.activeMetrics
  );
});
sagaMiddleware.run(sagas);

export const forceClearLogin = () => {
  store.dispatch(clearLogin());
};

export default store;
