import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/organisation';
import { fetchOrganisationInformation } from '@api/organisation';
import { treatFetchError } from '@api/utils';
import Organisation from '@models/Organisation';

export function* fetchOrganisationInformationSaga(
  action: ActionType<typeof types.fetchOrganisationInformation.request>
) {
  const { shallow } = action.payload;

  try {
    const organisation: Organisation = yield call(fetchOrganisationInformation);

    yield put(
      types.fetchOrganisationInformation.success({ organisation, shallow })
    );
  } catch (error: any) {
    yield put(
      types.fetchOrganisationInformation.failure({
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export default {};
