import Organisation from '@models/Organisation';
import axios from './axios';

export const ORGANISATION_API = {
  getOrganisationInfo: '/organisations/information',
};

export async function fetchOrganisationInformation(): Promise<Organisation> {
  const { data } = await axios.get(ORGANISATION_API.getOrganisationInfo);

  return data;
}
