import Account, { IAccountMeta } from '@models/Account';
import { AccountsMeta } from '@models/AccountMeta';
import { IAccountsState } from '@reducers/accounts';
import { IAuthenticationState } from '@reducers/authentication';
import {
  getMeasurementUnitsFromLocale,
  getNavigatorLanguage,
} from '@app/utils/accountUtils';

export function getAccountMeta(account?: Account): IAccountMeta {
  const organisationId = (account || {}).organisationId || '';

  return ((account || {}).organisations || {})[organisationId] || {};
}

export function hasPermission(account: Account, permission: string): boolean {
  const accountMeta = getAccountMeta(account);

  return (accountMeta.permissions || []).indexOf(permission) !== -1;
}

export function getAccountMeasurementUnits(
  state: IAuthenticationState
): string {
  const accountMeta = getAccountMeta(state.account);

  return accountMeta.measurementUnits
    ? accountMeta.measurementUnits
    : getMeasurementUnitsFromLocale();
}
export function getAccountLocale(state: IAuthenticationState): string {
  const accountMeta = getAccountMeta(state.account);

  return accountMeta.locale ? accountMeta.locale : getNavigatorLanguage();
}

export function getAvailablePermissions(state: IAccountsState): string[] {
  return state.permissions || [];
}
export function isAvailablePermissionsLoading(state: IAccountsState): boolean {
  return state.permissionsLoading || false;
}

export function getAccounts(state: IAccountsState): AccountsMeta {
  return state.accounts || {};
}
export function isAccountsLoading(state: IAccountsState): boolean {
  return state.loading || false;
}

export function isAccountSettingsBasicLoading(
  state: IAuthenticationState
): boolean {
  return state.settings.basic.loading || false;
}
