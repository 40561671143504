import { IDataRange } from '@actions/index';
import { Date } from '@dashboard_utils/index';
import MetricMeta, { DefaultMetricAggregation } from '@models/MetricMeta';
import { MetricAggregation } from '@models/Metric';
import axios from './axios';

export const PLOTS_API = {
  postAnalyticsDataRange: '/analytics/datarange/',
};

export async function fetchDataRange(
  warehouseId: string,
  floorplanId: string,
  assetIds: string[] | undefined,
  tags: string[] | undefined
): Promise<IDataRange> {
  const ts = Date.now();

  const response = await axios.post(PLOTS_API.postAnalyticsDataRange, {
    filters: {
      asset: assetIds || [],
      floorplan: [floorplanId],
      tag: tags || [],
      warehouse: [warehouseId],
    },
  });

  return { ...(response.data || {}), lastUpdate: ts };
}

export async function fetchMetrics(
  section: 'user' | 'diagnostics',

  warehouseId: string | undefined,
  floorplanId: string | undefined,
  zoneIds: string[] | undefined,

  tags: string[] | undefined,
  assetIds: string[] | undefined,

  templateIds: string[] | undefined,
  ruleIds: string[] | undefined,

  locale: string,
  units: string
): Promise<MetricMeta[]> {
  const { data } = await axios.post(`/analytics/metrics/find/${section}/`, {
    locale,
    filters: {
      warehouse: warehouseId ? [warehouseId] : [],
      floorplan: floorplanId ? [floorplanId] : [],
      zone: zoneIds || [],

      tag: tags || [],
      asset: assetIds || [],
      /*
      template: templateIds || [],
      rule: ruleIds || [],
      */
      fromts: 0,
      tots: Date.now(),
    },
    units,
  });

  const parser = (m: any) => {
    const aggregationsOptions: Record<string, string[]> = {};
    const defaultAggregations: DefaultMetricAggregation[] = [];

    Object.keys(m.aggregations_options || {}).forEach((k) => {
      aggregationsOptions[k] =
        typeof m.aggregations_options[k] === 'string'
          ? JSON.parse(m.aggregations_options[k].replace(/'/g, '"'))
          : m.aggregations_options[k];
    });
    (m.default_aggregations || []).forEach((aggregation: any) => {
      defaultAggregations.push({
        level1: aggregation.level_1,
        level1Option: aggregation.level_1_option,
        level2: aggregation.level_2,
        level2Option: aggregation.level_2_option,
        level3: aggregation.level_3,
        level3Option: aggregation.level_3_option,
      });
    });

    return {
      ...m,
      aggregationsOptions,
      defaultAggregations,
    };
  };

  return data.map(parser);
}

const matchAggregation = (aggregation1: any, aggregation2: MetricAggregation) =>
  (aggregation1.level_1 || '') === (aggregation2.level1 || '') &&
  (aggregation1.level_1_option || '') === (aggregation2.level1Option || '') &&
  (aggregation1.level_2 || '') === (aggregation2.level2 || '') &&
  (aggregation1.level_2_option || '') === (aggregation2.level2Option || '') &&
  (aggregation1.level_3 || '') === (aggregation2.level3 || '') &&
  (aggregation1.level_3_option || '') === (aggregation2.level3Option || '');

export async function fetchMetric(
  metricId: string,

  warehouseId: string | undefined,
  floorplanId: string | undefined,
  zoneIds: string[] | undefined,

  tags: string[] | undefined,
  assetIds: string[] | undefined,

  templateIds: string[] | undefined,
  ruleIds: string[] | undefined,

  from: number,
  to: number,
  aggregations: MetricAggregation[] | undefined,
  returns: string[],
  locale: string,
  units: string
): Promise<MetricMeta[]> {
  let tAggregations: any[] | undefined;
  if (aggregations && aggregations.length) {
    tAggregations = aggregations.map((aggregation) => ({
      // eslint-disable-next-line camelcase
      level_1: aggregation.level1,
      // eslint-disable-next-line camelcase
      level_1_option: aggregation.level1Option,
      // eslint-disable-next-line camelcase
      level_2: aggregation.level2,
      // eslint-disable-next-line camelcase
      level_2_option: aggregation.level2Option,
      // eslint-disable-next-line camelcase
      level_3: aggregation.level3,
      // eslint-disable-next-line camelcase
      level_3_option: aggregation.level3Option,
    }));
  }

  const { data } = await axios.post(`/analytics/metrics/${metricId}/`, {
    locale,
    aggregations: tAggregations,
    filters: {
      warehouse: warehouseId ? [warehouseId] : [],
      floorplan: floorplanId ? [floorplanId] : [],
      zone: zoneIds || [],

      tag: tags || [],
      asset: assetIds || [],

      template: templateIds || [],
      rule: ruleIds || [],

      fromts: from,
      tots: to,
    },
    results: returns,
    units,
  });

  const aggrs: Record<string, any> = {};
  // Always includes aggregations in the request, for 204 treatment
  (aggregations || []).forEach((a) => {
    aggrs[a.id] = { table: undefined, plot: undefined };
  });

  if (data.aggregations) {
    (data.aggregations || []).forEach((d: any) => {
      const aggregationIndex = (aggregations || []).findIndex((agg) =>
        matchAggregation(d.aggregation || {}, agg)
      );

      if (aggregationIndex !== -1) {
        const aggregation = aggregations![aggregationIndex];
        aggregations!.splice(aggregationIndex, 1);
        aggrs[aggregation.id] = { ...d, ...aggregation };
      }
    });
  }

  return { ...data, aggregations: aggrs };
}
