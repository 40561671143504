import { IImport } from '@actions/types';
import { Item, Items } from '@models/Item';
import { StocksWithLocations } from '@models/Stocks';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export const ITEMS_API = {
  getItemsWithLocations: '/items/withlocations/',
  postImportItems: '/items/import',
  postImportStocks: '/stocks/import',
};

// Helpers
function normalizeItem(data: Item[]) {
  return convertArrayToIdMap(data);
}

export async function fetchItemsWithLocations(): Promise<Items> {
  const { data } = await axios.get(ITEMS_API.getItemsWithLocations);

  return normalizeItem(data);
}

export async function importItems(properties: IImport): Promise<Items> {
  const { data } = await axios.post(ITEMS_API.postImportItems, properties.csv, {
    headers: { 'Content-Type': 'text/csv' },
  });

  return normalizeItem(data);
}

export async function importStocks(
  properties: IImport
): Promise<StocksWithLocations> {
  const { data } = await axios.post(
    ITEMS_API.postImportStocks,
    properties.csv,
    {
      headers: { 'Content-Type': 'text/csv' },
    }
  );

  return data;
}
