import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/notifications';
import {
  dismissNotifications,
  fetchNotifications,
  fetchNotificationsCount,
  unreadNotifications,
} from '@api/notifications';
import { treatFetchError } from '@api/utils';

export function* dismissNotificationsSaga(
  action: ActionType<typeof types.dismissNotifications.request>
): Generator<any, any, any> {
  const { ids, locale, units } = action.payload;

  try {
    const notifications = yield call(dismissNotifications, ids, locale, units);

    yield put(types.dismissNotifications.success(notifications));
  } catch (error: any) {
    yield put(
      types.dismissNotifications.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchNotificationsCountSaga(): Generator<any, any, any> {
  try {
    const count = yield call(fetchNotificationsCount);

    yield put(types.fetchNotificationsCount.success(count));
  } catch (error: any) {
    yield put(
      types.fetchNotificationsCount.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchNotificationsSaga(
  action: ActionType<typeof types.fetchNotifications.request>
): Generator<any, any, any> {
  const { locale, units } = action.payload;

  try {
    const notifications = yield call(fetchNotifications, locale, units);

    yield put(types.fetchNotifications.success(notifications));
  } catch (error: any) {
    yield put(
      types.fetchNotifications.failure({ error: treatFetchError(error) })
    );
  }
}

export function* unreadNotificationsSaga(
  action: ActionType<typeof types.unreadNotifications.request>
): Generator<any, any, any> {
  const { ids, locale, units } = action.payload;

  try {
    const notifications = yield call(unreadNotifications, ids, locale, units);

    yield put(types.unreadNotifications.success(notifications));
  } catch (error: any) {
    yield put(
      types.unreadNotifications.failure({ error: treatFetchError(error) })
    );
  }
}
