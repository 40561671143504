import { Rule, Rules } from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';
import { stringify } from 'qs';

import { Notification } from '@models/Notification';
import axios from './axios';
import { normalizeRules, normalizeTemplates } from './rulesUtils';

export const RULES_API = {
  putActivate: '/rules/activate',
  putDeactivate: '/rules/deactivate',
  postRule: '/rules/new/',
  putRule: '/rules/edit/',
  postTemplates: '/analytics/rules/templates/',
  getRules: '/rules/',
};

export async function activateRule(id: string): Promise<void> {
  const { data } = await axios.put(RULES_API.putActivate, { id });

  return data;
}

export async function deactivateRule(id: string): Promise<void> {
  const { data } = await axios.put(RULES_API.putDeactivate, { id });

  return data;
}

export async function createRule(
  name: string,
  notifyEmail: boolean,
  notifyMobile: boolean,
  notifyWhatsapp: boolean,
  options: object,
  templateId: string
): Promise<Rule> {
  const { data } = await axios.post(RULES_API.postRule, {
    name,
    notifyEmail,
    notifyMobile,
    notifyWhatsapp,
    templateId,
    ...options,
  });

  return data;
}

export async function updateRule(
  id: string,
  name: string,
  notifyEmail: boolean,
  notifyMobile: boolean,
  notifyWhatsapp: boolean,
  options: object,
  templateId: string
): Promise<Rule> {
  const { data } = await axios.put(RULES_API.putRule, {
    id,
    name,
    notifyEmail,
    notifyMobile,
    notifyWhatsapp,
    templateId,
    ...options,
  });

  return data;
}

export async function deleteRule(id: string): Promise<void> {
  await axios.delete(`/rules/delete/id/${id}`);
}

export async function fetchTemplates(
  locale: string,
  units: string
): Promise<RuleTemplates> {
  const { data } = await axios.post(RULES_API.postTemplates, {
    locale: locale.replace('-', '_'),
    units,
  });

  return normalizeTemplates(data);
}

export async function fetchRules(): Promise<Rules> {
  const { data } = await axios.get(RULES_API.getRules);

  return normalizeRules(data);
}

export async function fetchRuleAlerts(
  floorplanId: string,
  locale: string,
  ruleIds: string[],
  templateIds: string[],
  units: string,
  startTs?: number,
  endTs?: number
): Promise<Notification[]> {
  const { data } = await axios.get(
    `/notifications/rules/alerts/floorplan/${floorplanId}`,
    {
      params: {
        locale: locale.replace('-', '_'),
        units,
        rule: ruleIds,
        template: templateIds,
        startts: startTs,
        endts: endTs,
      },
      paramsSerializer: (params) => stringify(params, { indices: false }),
    }
  );

  return data;
}
