import { IFCMDeviceInfo } from '@actions/authentication';
import Account, { IAccountMeta, ReportConfig } from '@models/Account';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export const AUTHENTICATION_API = {
  getAccount: '/account',
  postVerifyAccount: '/accounts/verify',
  postPasswordRecovery: '/accounts/password-recovery',
  postPasswordReset: '/accounts/password-reset',
  postSession: '/sessions',
  postSessionLogout: '/sessions/logout',
  putSessionMode: '/sessions/mode',
  putAccountSettings: '/account/settings',
  putAccountPassword: '/account/password',
  putAccountContacts: '/account/contacts',
  putAccountReport: '/account/report',

  postOrganisation: '/organisations',
};

export const accountVerification = async (
  email: string,
  verification: string
) =>
  axios.post(AUTHENTICATION_API.postVerifyAccount, {
    email,
    verificationId: verification,
  });

export const accountSetup = async (
  email: string,
  password: string,
  verification: string
) =>
  axios.post('/accounts/setup', {
    email,
    password,
    verificationId: verification,
  });

export async function fetchAccount(): Promise<Account> {
  /**
   * Pragma header so that IE redirects after logout
   * (otherwise will respond from cache, opening dashboard without an active login)
   */
  const { data } = await axios.get(AUTHENTICATION_API.getAccount, {
    headers: { Pragma: 'no-cache' },
  });

  data.organisations = convertArrayToIdMap(data.organisations);

  return data;
}

export const passwordRecovery = async (email: string) =>
  axios.post(AUTHENTICATION_API.postPasswordRecovery, { email });

export const passwordReset = async (
  email: string,
  code: string,
  password: string
) =>
  axios.post(AUTHENTICATION_API.postPasswordReset, {
    email,
    code,
    password,
  });

export const login = async (
  email: string,
  password: string,
  fcmDeviceInfo?: IFCMDeviceInfo
) =>
  axios.post(AUTHENTICATION_API.postSession, {
    email,
    fcmDeviceInfo,
    password,
  });

export const changeMode = async (organisationId: string) =>
  axios.put(AUTHENTICATION_API.putSessionMode, { organisationId });

export const logout = async () => {
  return axios.post(AUTHENTICATION_API.postSessionLogout);
};

export async function updateAccountSettings(properties: {
  image?: string;
  file?: File;
  enableNotifications?: boolean;
  locale?: string;
  measurementUnits?: string;
  showHiddenFloorplans?: boolean;
}): Promise<IAccountMeta> {
  const {
    image,
    file,
    enableNotifications,
    locale,
    measurementUnits,
    showHiddenFloorplans,
  } = properties;

  const formData = new FormData();
  if (file !== undefined) {
    formData.append('image', file);
  } else if (image !== undefined) {
    formData.append('image', image);
  } else {
    formData.append('image', 'null');
  }
  formData.append('enableNotifications', String(enableNotifications || false));
  if (locale) {
    formData.append('locale', locale);
  }
  if (measurementUnits) {
    formData.append('measurementUnits', measurementUnits);
  }
  formData.append(
    'showHiddenFloorplans',
    String(showHiddenFloorplans || false)
  );

  const { data: account } = await axios.put(
    AUTHENTICATION_API.putAccountSettings,
    formData
  );

  return account;
}

export async function updateAccountPassword(
  currentPassword: string,
  password: string
): Promise<Account> {
  const { data } = await axios.put(AUTHENTICATION_API.putAccountPassword, {
    currentPassword,
    password,
  });

  return data;
}

export async function updateAccountContacts(properties: {
  contactsEmail?: string;
  contactsMobile?: string;
  contactsWhatsapp?: string;
}): Promise<IAccountMeta> {
  const { data } = await axios.put(
    AUTHENTICATION_API.putAccountContacts,
    properties
  );

  return data;
}

export async function updateAccountReport(
  reportConfigs: ReportConfig[]
): Promise<IAccountMeta> {
  const { data } = await axios.put(AUTHENTICATION_API.putAccountReport, {
    reportConfigs,
  });

  return data;
}

export async function deleteAccount(): Promise<void> {
  await axios.delete('/account');
}

export const signUp = async (
  name: string,
  company: string,
  email: string,
  password: string
) =>
  axios.post(AUTHENTICATION_API.postOrganisation, {
    account: {
      email,
      name,
      password,
    },
    organisation: {
      name: company,
    },
  });
