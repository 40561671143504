import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import AccountMeta, { AccountsMeta } from '@models/AccountMeta';
import { IFailed, IFailedWithShallow, IFeedbackFailed } from './types';

export enum AccountsTypes {
  FETCH_AVAILABLE_PERMISSIONS = '@@accounts/FETCH_AVAILABLE_PERMISSIONS',
  FETCH_AVAILABLE_PERMISSIONS_SUCCEEDED = '@@accounts/FETCH_AVAILABLE_PERMISSIONS_SUCCEEDED',
  FETCH_AVAILABLE_PERMISSIONS_FAILED = '@@accounts/FETCH_AVAILABLE_PERMISSIONS_FAILED',

  FETCH = '@@accounts/FETCH',
  FETCH_SUCCEEDED = '@@accounts/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@accounts/FETCH_FAILED',

  CREATE_ACCOUNT = '@@accounts/CREATE_ACCOUNT',
  CREATE_ACCOUNT_SUCCEEDED = '@@accounts/CREATE_ACCOUNT_SUCCEEDED',
  CREATE_ACCOUNT_FAILED = '@@accounts/CREATE_ACCOUNT_FAILED',

  CREATE_ACCOUNT_INVITE = '@@accounts/CREATE_ACCOUNT_INVITE',
  CREATE_ACCOUNT_INVITE_SUCCEEDED = '@@accounts/CREATE_ACCOUNT_INVITE_SUCCEEDED',
  CREATE_ACCOUNT_INVITE_FAILED = '@@accounts/CREATE_ACCOUNT_INVITE_FAILED',

  ACTIVATEACCOUNT = '@@accounts/ACTIVATEACCOUNT',
  ACTIVATEACCOUNT_SUCCEEDED = '@@accounts/ACTIVATEACCOUNT_SUCCEEDED',
  ACTIVATEACCOUNT_FAILED = '@@accounts/ACTIVATEACCOUNT_FAILED',

  CHANGE_ACCOUNT_PASSWORD = '@@accounts/CHANGE_ACCOUNT_PASSWORD',
  CHANGE_ACCOUNT_PASSWORD_SUCCEEDED = '@@accounts/CHANGE_ACCOUNT_PASSWORD_SUCCEEDED',
  CHANGE_ACCOUNT_PASSWORD_FAILED = '@@accounts/CHANGE_ACCOUNT_PASSWORD_FAILED',

  UPDATE_ACCOUNT_PERMISSIONS = '@@accounts/UPDATE_ACCOUNT_PERMISSIONS',
  UPDATE_ACCOUNT_PERMISSIONS_SUCCEEDED = '@@accounts/UPDATE_ACCOUNT_PERMISSIONS_SUCCEEDED',
  UPDATE_ACCOUNT_PERMISSIONS_FAILED = '@@accounts/UPDATE_ACCOUNT_PERMISSIONS_FAILED',

  OPENFORM = '@@accounts/OPENFORM',
  CLOSEFORM = '@@accounts/CLOSEFORM',
  OPENINVITEFORM = '@@accounts/OPENINVITEFORM',
  CLOSEINVITEFORM = '@@accounts/CLOSEINVITEFORM',
  WS_ALIVE = '@@accounts/WS_ALIVE',
}

export interface IPermissionsSucceed {
  permissions: string[];
}

export const fetchAvailablePermissions = createAsyncAction(
  AccountsTypes.FETCH_AVAILABLE_PERMISSIONS,
  AccountsTypes.FETCH_AVAILABLE_PERMISSIONS_SUCCEEDED,
  AccountsTypes.FETCH_AVAILABLE_PERMISSIONS_FAILED
)<void, IPermissionsSucceed, IFailed>();

export interface ISucceed {
  accounts: AccountsMeta;
  shallow?: boolean;
}

export interface IFetchAccounts {
  shallow?: boolean;
}

export const fetchAccounts = createAsyncAction(
  AccountsTypes.FETCH,
  AccountsTypes.FETCH_SUCCEEDED,
  AccountsTypes.FETCH_FAILED
)<IFetchAccounts, ISucceed, IFailedWithShallow>();

export interface ICreateAccount {
  name: string;
  email: string;
  password: string;
  permissions: string[];
  operator: boolean;
  intl: IntlShape;
}

export interface ICreateAccountSuccess {
  account: AccountMeta;
  intl: IntlShape;
}

export const createAccount = createAsyncAction(
  AccountsTypes.CREATE_ACCOUNT,
  AccountsTypes.CREATE_ACCOUNT_SUCCEEDED,
  AccountsTypes.CREATE_ACCOUNT_FAILED
)<ICreateAccount, ICreateAccountSuccess, IFeedbackFailed>();

export interface ICreateAccountInvite {
  name: string;
  email: string;
  permissions: string[];
  operator: boolean;
  intl: IntlShape;
}

export const createAccountInvite = createAsyncAction(
  AccountsTypes.CREATE_ACCOUNT_INVITE,
  AccountsTypes.CREATE_ACCOUNT_INVITE_SUCCEEDED,
  AccountsTypes.CREATE_ACCOUNT_INVITE_FAILED
)<ICreateAccountInvite, ICreateAccountSuccess, IFeedbackFailed>();

export interface IActivateAccount {
  id: string;
  active: boolean;
  intl: IntlShape;
}

export const activateAccount = createAsyncAction(
  AccountsTypes.ACTIVATEACCOUNT,
  AccountsTypes.ACTIVATEACCOUNT_SUCCEEDED,
  AccountsTypes.ACTIVATEACCOUNT_FAILED
)<IActivateAccount, IActivateAccount, IFeedbackFailed>();

export interface IChangeAccountPassword {
  id: string;
  password: string;
  intl: IntlShape;
}

export const changeAccountPassword = createAsyncAction(
  AccountsTypes.CHANGE_ACCOUNT_PASSWORD,
  AccountsTypes.CHANGE_ACCOUNT_PASSWORD_SUCCEEDED,
  AccountsTypes.CHANGE_ACCOUNT_PASSWORD_FAILED
)<IChangeAccountPassword, { intl: IntlShape }, IFeedbackFailed>();

export interface IUpdateAccountPermissions {
  id: string;
  permissions: string[];
  intl: IntlShape;
}

export const updateAccountPermissions = createAsyncAction(
  AccountsTypes.UPDATE_ACCOUNT_PERMISSIONS,
  AccountsTypes.UPDATE_ACCOUNT_PERMISSIONS_SUCCEEDED,
  AccountsTypes.UPDATE_ACCOUNT_PERMISSIONS_FAILED
)<IUpdateAccountPermissions, IUpdateAccountPermissions, IFeedbackFailed>();

export const openAccountForm = createAction(
  AccountsTypes.OPENFORM,
)();
export const closeAccountForm = createAction(
  AccountsTypes.CLOSEFORM,
)();

export const openInviteForm = createAction(
  AccountsTypes.OPENINVITEFORM,
)();
export const closeInviteForm = createAction(
  AccountsTypes.CLOSEINVITEFORM,
)();

export const wsChatAlive = createAction(
  AccountsTypes.WS_ALIVE,
  (data: { accountId: string }) => data
)();
