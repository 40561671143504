import { defineMessages } from 'react-intl';
import { ActionType, getType } from 'typesafe-actions';
import uuid from 'uuid/v4';

import * as accounts from '@actions/accounts';
import * as type from '@actions/actionFeedback';
import * as assets from '@actions/assets';
import * as auth from '@actions/authentication';
import * as configurations from '@actions/configurations';
import * as diagrams from '@actions/diagrams';
import * as floorplans from '@actions/floorplans';
import * as maintenances from '@actions/maintenances';
import * as prechecks from '@actions/prechecks';
import * as reports from '@actions/reports';
import * as simulations from '@actions/simulations';
import * as tickets from '@actions/tickets';
import * as warehouses from '@actions/warehouses';
import { Date, timeZone } from '@dashboard_utils/index';

/* eslint-disable @typescript-eslint/indent */
type FeedbackAction = ActionType<
  | typeof accounts
  | typeof auth
  | typeof assets
  | typeof configurations
  | typeof diagrams
  | typeof floorplans
  | typeof maintenances
  | typeof prechecks
  | typeof reports
  | typeof simulations
  | typeof type
  | typeof tickets
  | typeof warehouses
>;
/* eslint-enable @typescript-eslint/indent */

export enum IActionFeedbackType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface IActionFeedback {
  id: string;
  type: IActionFeedbackType;
  message: string;
  datetime: Date;
}

export interface IActionFeedbackState {
  readonly messages: Record<string, IActionFeedback>;
}

export const initialState: IActionFeedbackState = {
  messages: {},
};

/**
 * @description Generates a new feedback message for IActionFeedbackState
 * @param       {IActionFeedbackState} state       IActionFeedbackState
 * @param       {IActionFeedbackType}  messageType Message type
 * @param       {string}               msg         Message text
 */
const generateMessage = (
  state: IActionFeedbackState,
  messageType: IActionFeedbackType,
  message: string
): IActionFeedbackState => {
  const id: string = uuid();

  return {
    ...state,
    messages: {
      ...state.messages,
      [id]: {
        datetime: new Date(timeZone),
        id,
        message,
        type: messageType,
      },
    },
  };
};

export const messages = defineMessages({
  genericErrorMsg: {
    defaultMessage:
      'Sorry, we were unable to complete this action. Please, try again.',
    id: 'dashboard.feedback.error.generic',
  },
  genericSuccessMsg: {
    defaultMessage: 'Changes successfully saved!',
    id: 'dashboard.feedback.success.generic',
  },
  permissionErrorMsg: {
    defaultMessage: 'Sorry, your account does not have permissions to do that.',
    id: 'dashboard.feedback.error.permissions',
  },
});

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IActionFeedbackState = initialState,
  action: FeedbackAction
): IActionFeedbackState => {
  switch (action.type) {
    case getType(warehouses.createWarehouse.success):
    case getType(warehouses.updateWarehouse.success):
    case getType(accounts.changeAccountPassword.success):
    case getType(auth.updateAccountSettings.success):
    case getType(auth.updateAccountPassword.success):
    case getType(auth.updateAccountReport.success):
    case getType(floorplans.updateFloorplan.success):
    case getType(prechecks.createPreCheckAnswer.success):
    case getType(tickets.createTicket.success):
    case getType(tickets.updateTicket.success):
    case getType(tickets.archiveTicket.success):
    case getType(tickets.deleteTicket.success):
    case getType(maintenances.createMaintenance.success): {
      const { intl } = action.payload;

      return generateMessage(
        state,
        IActionFeedbackType.SUCCESS,
        intl.formatMessage(messages.genericSuccessMsg)
      );
    }

    case getType(accounts.activateAccount.failure):
    case getType(accounts.createAccount.failure):
    case getType(accounts.createAccountInvite.failure):
    case getType(accounts.changeAccountPassword.failure):
    case getType(accounts.updateAccountPermissions.failure):
    case getType(assets.createAsset.failure):
    case getType(assets.updateAsset.failure):
    case getType(assets.deleteAsset.failure):
    case getType(assets.deleteAssetDocument.failure):
    case getType(auth.updateAccountSettings.failure):
    case getType(auth.updateAccountPassword.failure):
    case getType(auth.deleteAccount.failure):
    case getType(auth.updateAccountReport.failure):
    case getType(configurations.deleteConfigFile.failure):
    case getType(diagrams.createDiagram.failure):
    case getType(diagrams.updateDiagram.failure):
    case getType(diagrams.deleteDiagram.failure):
    case getType(warehouses.createWarehouse.failure):
    case getType(warehouses.updateWarehouse.failure):
    case getType(warehouses.deleteWarehouse.failure):
    case getType(floorplans.createFloorplan.failure):
    case getType(floorplans.updateFloorplan.failure):
    case getType(reports.fetchReport.failure):
    case getType(reports.fetchROIReport.failure):
    case getType(simulations.stopSimulation.failure):
    case getType(floorplans.deleteFloorplan.failure):
    case getType(prechecks.createPreCheckAnswer.failure):
    case getType(tickets.createTicket.failure):
    case getType(tickets.updateTicket.failure):
    case getType(tickets.archiveTicket.failure):
    case getType(tickets.deleteTicket.failure):
    case getType(tickets.createTicketComment.failure):
    case getType(tickets.deleteTicketComment.failure):
    case getType(maintenances.createMaintenance.failure): {
      const { intl, error } = action.payload;

      // Treated errors
      if (error.errorCode === 'WrongPassword') {
        return state;
      }

      if (error.errorCode === 'RequiresPermission') {
        return generateMessage(
          state,
          IActionFeedbackType.ERROR,
          intl.formatMessage(messages.permissionErrorMsg)
        );
      }

      return generateMessage(
        state,
        IActionFeedbackType.ERROR,
        intl.formatMessage(messages.genericErrorMsg)
      );
    }

    case getType(type.closeNotification): {
      const id = action.payload;
      const msgs = state.messages;
      // eslint-disable-next-line no-param-reassign
      delete state.messages[id];

      return {
        ...state,
        messages: { ...msgs },
      };
    }

    default:
      return state;
  }
};
