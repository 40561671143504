import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/assets';
import {
  createAsset,
  deleteAsset,
  updateAsset,
  updateAssetPreCheck,
  fetchAssets,
  fetchAssetTicket,
  fetchAssetTickets,
  fetchAssetDocuments,
  createAssetTicket,
  createAssetTicketComment,
  assignAssetTicket,
  createAssetTicketOperation,
  deleteAssetTicketOperation,
  changeAssetTicketStatus,
  archiveAssetTicket,
  createAssetDocument,
  deleteAssetDocument,
} from '@api/assets';
import { treatFetchError } from '@api/utils';
import Asset, { Assets } from '@models/Asset';
import AssetDocument, { AssetDocuments } from '@models/AssetDocument';
import AssetTicket, {
  AssetTickets,
  AssetTicketComment,
  AssetTicketOperation,
} from '@models/AssetTicket';

export function* deleteAssetSaga(
  action: ActionType<typeof types.deleteAsset.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteAsset, id);

    yield put(types.deleteAsset.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteAsset.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* createAssetSaga(
  action: ActionType<typeof types.createAsset.request>
) {
  const {
    color,
    file,
    floorplanId,
    intl,
    name,
    sensorAssociations,
    tags,
    type,
  } = action.payload;

  try {
    const asset: Asset = yield call(
      createAsset as any,
      color,
      file,
      floorplanId,
      name,
      sensorAssociations,
      tags,
      type
    );

    yield put(types.createAsset.success({ asset, intl }));
  } catch (error: any) {
    yield put(
      types.createAsset.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* updateAssetSaga(
  action: ActionType<typeof types.updateAsset.request>
) {
  const {
    color,
    file,
    floorplanId,
    id,
    image,
    intl,
    name,
    sensorAssociations,
    tags,
    type,
  } = action.payload;

  try {
    const asset: Asset = yield call(
      updateAsset as any,
      color,
      file,
      image,
      floorplanId,
      id,
      name,
      sensorAssociations,
      tags,
      type
    );

    yield put(types.updateAsset.success({ asset, intl }));
  } catch (error: any) {
    yield put(
      types.updateAsset.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* updateAssetPreCheckSaga(
  action: ActionType<typeof types.updateAssetPreCheck.request>
) {
  const { id, intl, preCheckId } = action.payload;

  try {
    const asset: Asset = yield call(updateAssetPreCheck, id, preCheckId);

    yield put(types.updateAssetPreCheck.success({ asset, intl }));
  } catch (error: any) {
    yield put(
      types.updateAssetPreCheck.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchAssetsSaga(
  action: ActionType<typeof types.fetchAssets.request>
) {
  const { shallow } = action.payload;

  try {
    const assets: Assets = yield call(fetchAssets);

    yield put(types.fetchAssets.success({ assets, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchAssets.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* fetchAssetTicketSaga(
  action: ActionType<typeof types.fetchAssetTicket.request>
) {
  const { ticketId } = action.payload;

  try {
    const assetTickets: AssetTicket = yield call(fetchAssetTicket, ticketId);

    yield put(types.fetchAssetTicket.success(assetTickets));
  } catch (error: any) {
    yield put(
      types.fetchAssetTicket.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchAssetTicketsSaga(
  action: ActionType<typeof types.fetchAssetTickets.request>
) {
  const { assetId } = action.payload;

  try {
    const assetTickets: AssetTickets = yield call(fetchAssetTickets, assetId);

    yield put(types.fetchAssetTickets.success(assetTickets));
  } catch (error: any) {
    yield put(
      types.fetchAssetTickets.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchAssetDocumentsSaga(
  action: ActionType<typeof types.fetchAssetDocuments.request>
) {
  const { assetId } = action.payload;

  try {
    const assetDocuments: AssetDocuments = yield call(
      fetchAssetDocuments,
      assetId
    );

    yield put(types.fetchAssetDocuments.success(assetDocuments));
  } catch (error: any) {
    yield put(
      types.fetchAssetDocuments.failure({ error: treatFetchError(error) })
    );
  }
}

export function* createAssetTicketSaga(
  action: ActionType<typeof types.createAssetTicket.request>
) {
  const {
    assetId,
    type,
    eventDate,
    subject,
    description,
    workImpairingIssue,
    files,
  } = action.payload;

  try {
    const ticket: AssetTicket = yield call(
      createAssetTicket,
      assetId,
      type,
      eventDate,
      subject,
      description,
      workImpairingIssue,
      files
    );

    yield put(types.createAssetTicket.success(ticket));
  } catch (error: any) {
    yield put(
      types.createAssetTicket.failure({ error: treatFetchError(error) })
    );
  }
}

export function* createAssetTicketCommentSaga(
  action: ActionType<typeof types.createAssetTicketComment.request>
) {
  const { ticketId, comment } = action.payload;

  try {
    const data: AssetTicketComment = yield call(
      createAssetTicketComment,
      ticketId,
      comment
    );

    yield put(types.createAssetTicketComment.success(data));
  } catch (error: any) {
    yield put(
      types.createAssetTicketComment.failure({ error: treatFetchError(error) })
    );
  }
}

export function* assignAssetTicketSaga(
  action: ActionType<typeof types.assignAssetTicket.request>
) {
  const { ticketId, accountId } = action.payload;

  try {
    const data: types.AssignAssetTicketSuccess = yield call(
      assignAssetTicket,
      ticketId,
      accountId
    );

    yield put(types.assignAssetTicket.success(data));
  } catch (error: any) {
    yield put(
      types.assignAssetTicket.failure({ error: treatFetchError(error) })
    );
  }
}

export function* changeAssetTicketStatusSaga(
  action: ActionType<typeof types.changeAssetTicketStatus.request>
) {
  const { ticketId, status } = action.payload;

  try {
    yield call(changeAssetTicketStatus, ticketId, status);

    yield put(types.changeAssetTicketStatus.success({ ticketId, status }));
  } catch (error: any) {
    yield put(
      types.changeAssetTicketStatus.failure({ error: treatFetchError(error) })
    );
  }
}

export function* archiveAssetTicketSaga(
  action: ActionType<typeof types.archiveAssetTicket.request>
) {
  const { ticketId } = action.payload;

  try {
    yield call(archiveAssetTicket, ticketId);

    yield put(types.archiveAssetTicket.success({ ticketId }));
  } catch (error: any) {
    yield put(
      types.archiveAssetTicket.failure({ error: treatFetchError(error) })
    );
  }
}

export function* createAssetTicketOperationSaga(
  action: ActionType<typeof types.createAssetTicketOperation.request>
) {
  const { ticketId, name, description, cost } = action.payload;

  try {
    const data: AssetTicketOperation = yield call(
      createAssetTicketOperation,
      ticketId,
      name,
      description,
      cost
    );

    yield put(types.createAssetTicketOperation.success(data));
  } catch (error: any) {
    yield put(
      types.createAssetTicketOperation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* deleteAssetTicketOperationSaga(
  action: ActionType<typeof types.deleteAssetTicketOperation.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteAssetTicketOperation, id);

    yield put(types.deleteAssetTicketOperation.success({ id }));
  } catch (error: any) {
    yield put(
      types.deleteAssetTicketOperation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* createAssetDocumentSaga(
  action: ActionType<typeof types.createAssetDocument.request>
) {
  const { assetId, type, name, file } = action.payload;

  try {
    const document: AssetDocument = yield call(
      createAssetDocument,
      assetId,
      type,
      name,
      file
    );

    yield put(types.createAssetDocument.success(document));
  } catch (error: any) {
    yield put(
      types.createAssetDocument.failure({ error: treatFetchError(error) })
    );
  }
}

export function* deleteAssetDocumentSaga(
  action: ActionType<typeof types.deleteAssetDocument.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteAssetDocument, id);

    yield put(types.deleteAssetDocument.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteAssetDocument.failure({ error: treatFetchError(error), intl })
    );
  }
}
