import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Warehouse, { Warehouses } from '@models/Warehouse';
import { Zones } from '@models/Zone';
import { IFailedWithShallow, IFeedbackFailed } from './types';

export enum WarehouseTypes {
  FETCH = '@@warehouses/FETCH',
  FETCH_SUCCEEDED = '@@warehouses/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@warehouses/FETCH_FAILED',

  CREATE = '@@warehouses/CREATE',
  CREATE_SUCCEEDED = '@@warehouses/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@warehouses/CREATE_FAILED',

  UPDATE = '@@warehouses/UPDATE',
  UPDATE_SUCCEEDED = '@@warehouses/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@warehouses/UPDATE_FAILED',

  DELETE = '@@warehouses/DELETE',
  DELETE_SUCCEEDED = '@@warehouses/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@warehouses/DELETE_FAILED',

  DELETE_ALL = '@@warehouses/DELETE_ALL',
  DELETE_ALL_SUCCEEDED = '@@warehouses/DELETE_ALL_SUCCEEDED',
  DELETE_ALL_FAILED = '@@warehouses/DELETE_ALL_FAILED',

  WS_CREATE = '@@warehouses/WS_CREATE',
  WS_UPDATE = '@@warehouses/WS_UPDATE',
  WS_DELETE = '@@warehouses/WS_DELETE',
}

export interface ICreateWarehouse {
  intl: IntlShape;
  name: string;
  site: string;
  timezone: string;
  country: string;
  metricsTotalPalletsMoved?: number;
  metricsFleetUsedCapacity?: number;
  numberOfShifts?: number;
  shiftHours?: number;
  workingDays?: number;
  targetForkliftType?: keyof typeof ForkliftTypes;
}

interface IWarehouseUpdated {
  floorplans: IncompleteFloorplans;
  intl: IntlShape;
  warehouse: Warehouse;
  zones: Zones;
}

export interface IUpdateWarehouse extends ICreateWarehouse {
  id: string;
}

export interface IDeleteWarehouse {
  id: string;
  intl: IntlShape;
}

interface IDeleteAllWarehouses {
  ids: string[];
  intl: IntlShape;
}

interface IWSWarehouseUpdated {
  warehouse: Warehouse;
}
interface IWSWarehouseDelete {
  id: string;
}

export const createWarehouse = createAsyncAction(
  WarehouseTypes.CREATE,
  WarehouseTypes.CREATE_SUCCEEDED,
  WarehouseTypes.CREATE_FAILED
)<ICreateWarehouse, IWarehouseUpdated, IFeedbackFailed>();

export const updateWarehouse = createAsyncAction(
  WarehouseTypes.UPDATE,
  WarehouseTypes.UPDATE_SUCCEEDED,
  WarehouseTypes.UPDATE_FAILED
)<IUpdateWarehouse, IWarehouseUpdated, IFeedbackFailed>();

export interface IFetchWarehouses {
  shallow?: boolean;
}
interface IFetchWarehousesSuccess {
  warehouses: Warehouses;
  floorplans: IncompleteFloorplans;
  zones: Zones;
  shallow?: boolean;
}
export const fetchWarehouses = createAsyncAction(
  WarehouseTypes.FETCH,
  WarehouseTypes.FETCH_SUCCEEDED,
  WarehouseTypes.FETCH_FAILED
)<IFetchWarehouses, IFetchWarehousesSuccess, IFailedWithShallow>();

export const deleteWarehouse = createAsyncAction(
  WarehouseTypes.DELETE,
  WarehouseTypes.DELETE_SUCCEEDED,
  WarehouseTypes.DELETE_FAILED
)<IDeleteWarehouse, { id: string }, IFeedbackFailed>();

export const deleteAllWarehouses = createAsyncAction(
  WarehouseTypes.DELETE_ALL,
  WarehouseTypes.DELETE_ALL_SUCCEEDED,
  WarehouseTypes.DELETE_ALL_FAILED
)<IDeleteAllWarehouses, void, IFeedbackFailed>();

export const wsCreateWarehouse = createAction(
  WarehouseTypes.WS_CREATE,
  (data: IWSWarehouseUpdated) => data
)();
export const wsUpdateWarehouse = createAction(
  WarehouseTypes.WS_UPDATE,
  (data: IWSWarehouseUpdated) => data
)();
export const wsDeleteWarehouse = createAction(
  WarehouseTypes.WS_DELETE,
  (data: IWSWarehouseDelete) => data
)();
