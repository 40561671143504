import { createAction, createAsyncAction } from 'typesafe-actions';

import ChatMessage from '@models/ChatMessage';
import { IFailed } from './types';

export enum ChatsTypes {
  FETCH_MESSAGES = '@@chats/FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCEEDED = '@@chats/FETCH_MESSAGES_SUCCEEDED',
  FETCH_MESSAGES_FAILED = '@@chats/FETCH_MESSAGES_FAILED',

  CREATE_MESSAGE = '@@chats/CREATE_MESSAGE',
  CREATE_MESSAGE_SUCCEEDED = '@@chats/CREATE_MESSAGE_SUCCEEDED',
  CREATE_MESSAGE_FAILED = '@@chats/CREATE_MESSAGE_FAILED',

  WS_CREATE = '@@chats/WS_CREATE',
}

export interface IFetchMessages {
  toAccountId: string;
}

interface IFetchMessagesSuccess {
  toAccountId: string;
  messages: ChatMessage[];
}

export interface ICreateMessage {
  toAccountId?: string;
  message: string;
  broadcast?: boolean;
}

interface ICreateMessageSuccess {
  toAccountId?: string;
  message: ChatMessage;
  broadcast: boolean;
}

interface IChatError extends IFailed {
  toAccountId?: string;
}

export const fetchMessages = createAsyncAction(
  ChatsTypes.FETCH_MESSAGES,
  ChatsTypes.FETCH_MESSAGES_SUCCEEDED,
  ChatsTypes.FETCH_MESSAGES_FAILED
)<IFetchMessages, IFetchMessagesSuccess, IChatError>();

export const createMessage = createAsyncAction(
  ChatsTypes.CREATE_MESSAGE,
  ChatsTypes.CREATE_MESSAGE_SUCCEEDED,
  ChatsTypes.CREATE_MESSAGE_FAILED
)<ICreateMessage, ICreateMessageSuccess, IChatError>();

export const wsChatMessage = createAction(
  ChatsTypes.WS_CREATE,
  (data: ChatMessage) => data
)();
