import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/chats';
import {
  createMessage,
  fetchMessages,
} from '@api/chats';
import { treatFetchError } from '@api/utils';
import ChatMessage from '@models/ChatMessage';

export function* fetchMessagesSaga(
  action: ActionType<typeof types.fetchMessages.request>
) {
  const { toAccountId } = action.payload;
  try {
    const messages: ChatMessage[] = yield call(fetchMessages, toAccountId);

    yield put(types.fetchMessages.success({ toAccountId, messages }));
  } catch (error: any) {
    yield put(
      types.fetchMessages.failure({ error: treatFetchError(error), toAccountId })
    );
  }
}

export function* createMessageSaga(
  action: ActionType<typeof types.createMessage.request>
) {
  const { toAccountId, message, broadcast } = action.payload;

  try {
    const messageData: ChatMessage = yield call(createMessage, toAccountId, message, broadcast);

    yield put(
      types.createMessage.success({
        toAccountId,
        message: messageData,
        broadcast: broadcast || false,
      })
    );
  } catch (error: any) {
    yield put(
      types.createMessage.failure({ error: treatFetchError(error), toAccountId })
    );
  }
}
