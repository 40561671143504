import { ICreateZone, IDeleteZone, IUpdateZone } from '@actions/index';
import Zone from '@models/Zone';
import axios from './axios';

export const ZONE_API = {
  postZone: '/warehouses/floorplans/zones',
  putZone: '/warehouses/floorplans/zones/edit',
};

/**
 * @return {Zone} Created Zone
 */
export const createZone = async ({
  color,
  coordinates,
  name,
  type,
  floorplanId,
}: ICreateZone): Promise<Zone> => {
  const { data: zone } = await axios.post(ZONE_API.postZone, {
    color,
    coordinates,
    floorplanId,
    name,
    type,
  });

  return zone;
};

/**
 * @return {Object} Error object or undefined
 */
export const deleteZone = async ({
  id,
}: IDeleteZone): Promise<object | undefined> => {
  await axios.delete(`/warehouses/floorplans/zones/delete/id/${id}`);

  return { id };
};

/**
 * @return {Zone} Updated Zone
 */
export const updateZone = async ({
  id,
  color,
  coordinates,
  name,
  type,
}: IUpdateZone): Promise<Zone> => {
  const { data: zone } = await axios.put(ZONE_API.putZone, {
    color,
    coordinates,
    id,
    name,
    type,
  });

  return zone;
};
