import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { Task, TaskPriority, TaskStatus, TaskType, Tasks } from '@models/Task';
import { IFailedWithShallow, IFeedbackFailed } from './types';

export enum TasksTypes {
  DELETE = '@@tasks/DELETE',
  DELETE_SUCCEEDED = '@@tasks/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@tasks/DELETE_FAILED',

  FETCH = '@@tasks/FETCH',
  FETCH_SUCCEEDED = '@@tasks/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@tasks/FETCH_FAILED',

  CREATE = '@@tasks/CREATE',
  CREATE_SUCCEEDED = '@@tasks/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@tasks/CREATE_FAILED',

  UPDATE = '@@tasks/UPDATE',
  UPDATE_SUCCEEDED = '@@tasks/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@tasks/UPDATE_FAILED',

  WS_CREATE = '@@tasks/WS_CREATE',
  WS_UPDATE = '@@tasks/WS_UPDATE',
  WS_DELETE = '@@tasks/WS_DELETE',

  OPENFORM = '@@tasks/OPENFORM',
  CLOSEFORM = '@@tasks/CLOSEFORM',
}

export interface ICreateTask {
  warehouseId: string;
  floorplanId: string;
  zoneId?: string;
  name: string;
  notes?: string;
  priority: TaskPriority;
  status: TaskStatus;
  type: TaskType;
  employees: string[];
  teams: string[];
  assets: string[];
  subtasks: { active: boolean, name: string }[];
  positionX?: number;
  positionY?: number;
  positionZ?: number;
  dueDate: string;
  pictures: string[];
  intl: IntlShape;
}

export interface IUpdateTask extends ICreateTask {
  id: string;
}

export interface IDeleteTask {
  id: string;
  intl: IntlShape;
}

export interface ITaskSuccess {
  task: Task;
  intl: IntlShape;
}

export const deleteTask = createAsyncAction(
  TasksTypes.DELETE,
  TasksTypes.DELETE_SUCCEEDED,
  TasksTypes.DELETE_FAILED
)<IDeleteTask, IDeleteTask, IFeedbackFailed>();

export const createTask = createAsyncAction(
  TasksTypes.CREATE,
  TasksTypes.CREATE_SUCCEEDED,
  TasksTypes.CREATE_FAILED
)<ICreateTask, ITaskSuccess, IFeedbackFailed>();

export interface IFetchTasks {
  shallow?: boolean;
}
export interface IFetchTasksSuccess {
  tasks: Tasks;
  shallow?: boolean;
}
export const fetchTasks = createAsyncAction(
  TasksTypes.FETCH,
  TasksTypes.FETCH_SUCCEEDED,
  TasksTypes.FETCH_FAILED
)<IFetchTasks, IFetchTasksSuccess, IFailedWithShallow>();

export const updateTask = createAsyncAction(
  TasksTypes.UPDATE,
  TasksTypes.UPDATE_SUCCEEDED,
  TasksTypes.UPDATE_FAILED
)<IUpdateTask, ITaskSuccess, IFeedbackFailed>();

export const openTaskForm = createAction(
  TasksTypes.OPENFORM,
  (data?: Task) => data
)();
export const closeTaskForm = createAction(
  TasksTypes.CLOSEFORM,
)();

interface IWSTaskUpdated {
  task: Task;
}
interface IWSTaskDeleted {
  id: string;
}

export const wsCreateTask = createAction(
  TasksTypes.WS_CREATE,
  (data: IWSTaskUpdated) => data
)();
export const wsUpdateTask = createAction(
  TasksTypes.WS_UPDATE,
  (data: IWSTaskUpdated) => data
)();
export const wsDeleteTask = createAction(
  TasksTypes.WS_DELETE,
  (data: IWSTaskDeleted) => data
)();
