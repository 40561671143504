import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as floorplansTypes from '@actions/floorplans';
import * as warehousesTypes from '@actions/warehouses';
import * as types from '@actions/zones';
import * as auth from '@actions/authentication';
import Zone from '@models/Zone';

type Actions = ActionType<
  typeof types | typeof warehousesTypes | typeof floorplansTypes | typeof auth
>;

export interface IZonesState {
  // Zone id -> Zone
  readonly zones: Record<string, Zone>;
  readonly error?: object;
  readonly loading: boolean;
}

export const initialState: IZonesState = {
  error: undefined,
  loading: false,
  zones: {},
};

export default (
  state: IZonesState = initialState,
  action: Actions
): IZonesState => {
  switch (action.type) {
    case getType(types.deleteZone.request):
    case getType(types.createZone.request):
    case getType(types.updateZone.request):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case getType(types.deleteZone.failure):
    case getType(types.createZone.failure):
    case getType(types.updateZone.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case getType(types.createZone.success):
    case getType(types.updateZone.success): {
      const { zone } = action.payload;
      const zoneId = zone.id;

      return {
        ...state,
        error: undefined,
        loading: false,
        zones: {
          ...state.zones,
          [zoneId]: zone,
        },
      };
    }

    case getType(types.deleteZone.success):
      return {
        ...state,
        error: undefined,
        loading: false,
        zones: omit(state.zones, action.payload.id),
      };

    case getType(types.wsCreateZone):
    case getType(types.wsUpdateZone): {
      const { zone } = action.payload;
      const zoneId = zone.id;

      return {
        ...state,
        zones: {
          ...state.zones,
          [zoneId]: zone,
        },
      };
    }

    case getType(warehousesTypes.deleteAllWarehouses.success):
      return {
        ...state,
        zones: {},
      };

    case getType(warehousesTypes.fetchWarehouses.success):
      return {
        ...state,
        zones: action.payload.zones,
      };

    case getType(floorplansTypes.fetchFloorplan.success):
      return {
        ...state,
        zones: {
          ...state.zones,
          ...action.payload.zones,
        },
      };

    case getType(types.wsDeleteZone):
      return {
        ...state,
        zones: omit(state.zones, action.payload.id),
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
