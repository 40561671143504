import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/orders';
import FetchError from '@models/FetchError';
import { OldOrders } from '@models/Order';
import { IOrdersEvents } from '@api/websocket';

type OrdersType = ActionType<typeof types>;

interface IImportForm {
  open: boolean;
  loading: boolean;
  error?: FetchError;
}
interface IOrdersEventsState {
  data: IOrdersEvents[];
  error?: object;
  loading: boolean;
}
export interface IOrdersState {
  readonly error?: FetchError;
  readonly orders?: OldOrders;
  readonly loading: boolean;
  readonly importOrdersForm: IImportForm;
  readonly ordersEvents: Record<string, IOrdersEventsState>;
}

export const initialState: IOrdersState = {
  ordersEvents: {},
  importOrdersForm: {
    loading: false,
    open: false,
  },
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IOrdersState = initialState,
  action: OrdersType
): IOrdersState => {
  switch (action.type) {
    case getType(types.fetchOrdersWithItems.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchOrdersWithItems.success): {
      const { orders, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? false : loading,
        orders,
      };
    }

    case getType(types.fetchOrdersWithItems.failure): {
      const { error, shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchOrdersEvents.request): {
      const { floorplanId } = action.payload;

      return {
        ...state,
        ordersEvents: {
          ...state.ordersEvents,
          [floorplanId]: {
            ...(state.ordersEvents[floorplanId] || {}),
            loading: true,
            error: undefined,
          },
        },
      };
    }

    case getType(types.fetchOrdersEvents.success): {
      const { floorplanId, data } = action.payload;

      return {
        ...state,
        ordersEvents: {
          ...state.ordersEvents,
          [floorplanId]: {
            ...state.ordersEvents[floorplanId],
            data,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchOrdersEvents.failure): {
      const { error, floorplanId } = action.payload;

      return {
        ...state,
        ordersEvents: {
          ...state.ordersEvents,
          [floorplanId]: {
            ...state.ordersEvents[floorplanId],
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.importOrders.request):
      return {
        ...state,
        importOrdersForm: {
          ...state.importOrdersForm,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.importOrders.success): {
      const orders = action.payload;

      return {
        ...state,
        importOrdersForm: {
          ...state.importOrdersForm,
          loading: false,
          open: false,
        },
        orders: {
          ...state.orders,
          ...orders,
        },
      };
    }

    case getType(types.importOrders.failure): {
      const { error } = action.payload;

      return {
        ...state,
        importOrdersForm: {
          ...state.importOrdersForm,
          error,
          loading: false,
        },
      };
    }

    case getType(types.openImportOrdersForm):
      return {
        ...state,
        importOrdersForm: {
          loading: false,
          open: true,
        },
      };

    case getType(types.closeImportOrdersForm):
      return {
        ...state,
        importOrdersForm: {
          ...state.importOrdersForm,
          open: false,
        },
      };

    default:
      return state;
  }
};
