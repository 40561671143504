import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { Employee, Employees } from '@models/Employee';
import { IncompleteAssetAssociation } from '@models/IncompleteAssetAssociation';
import { IFailedWithShallow, IFeedbackFailed } from './types';

export enum EmployeesTypes {
  DELETE = '@@employees/DELETE',
  DELETE_SUCCEEDED = '@@employees/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@employees/DELETE_FAILED',

  FETCH = '@@employees/FETCH',
  FETCH_SUCCEEDED = '@@employees/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@employees/FETCH_FAILED',

  CREATE = '@@employees/CREATE',
  CREATE_SUCCEEDED = '@@employees/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@employees/CREATE_FAILED',

  UPDATE = '@@employees/UPDATE',
  UPDATE_SUCCEEDED = '@@employees/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@employees/UPDATE_FAILED',

  WS_CREATE = '@@employees/WS_CREATE',
  WS_UPDATE = '@@employees/WS_UPDATE',
  WS_DELETE = '@@employees/WS_DELETE',

  OPENFORM = '@@employees/OPENFORM',
  CLOSEFORM = '@@employees/CLOSEFORM',
}

export interface ICreateEmployee {
  name: string;
  email: string;
  password: string;
  file?: File;
  assetAssociations?: IncompleteAssetAssociation[];
  intl: IntlShape;
}

export interface IEditEmployee extends ICreateEmployee {
  id: string;
  image?: string;
}

export interface IDeleteEmployee {
  id: string;
  intl: IntlShape;
}

export interface IEmployeeSuccess {
  employee: Employee;
  intl: IntlShape;
}

export const deleteEmployee = createAsyncAction(
  EmployeesTypes.DELETE,
  EmployeesTypes.DELETE_SUCCEEDED,
  EmployeesTypes.DELETE_FAILED
)<IDeleteEmployee, IDeleteEmployee, IFeedbackFailed>();

export const createEmployee = createAsyncAction(
  EmployeesTypes.CREATE,
  EmployeesTypes.CREATE_SUCCEEDED,
  EmployeesTypes.CREATE_FAILED
)<ICreateEmployee, IEmployeeSuccess, IFeedbackFailed>();

export interface IFetchEmployees {
  shallow?: boolean;
}
export interface IFetchEmployeesSuccess {
  employees: Employees;
  shallow?: boolean;
}
export const fetchEmployees = createAsyncAction(
  EmployeesTypes.FETCH,
  EmployeesTypes.FETCH_SUCCEEDED,
  EmployeesTypes.FETCH_FAILED
)<IFetchEmployees, IFetchEmployeesSuccess, IFailedWithShallow>();

export const updateEmployee = createAsyncAction(
  EmployeesTypes.UPDATE,
  EmployeesTypes.UPDATE_SUCCEEDED,
  EmployeesTypes.UPDATE_FAILED
)<IEditEmployee, IEmployeeSuccess, IFeedbackFailed>();

export const openEmployeeForm = createAction(
  EmployeesTypes.OPENFORM,
  (data?: Employee) => data
)();
export const closeEmployeeForm = createAction(
  EmployeesTypes.CLOSEFORM,
)();

interface IWSEmployeeUpdated {
  employee: Employee;
}
interface IWSEmployeeDeleted {
  id: string;
}

export const wsCreateEmployee = createAction(
  EmployeesTypes.WS_CREATE,
  (data: IWSEmployeeUpdated) => data
)();
export const wsUpdateEmployee = createAction(
  EmployeesTypes.WS_UPDATE,
  (data: IWSEmployeeUpdated) => data
)();
export const wsDeleteEmployee = createAction(
  EmployeesTypes.WS_DELETE,
  (data: IWSEmployeeDeleted) => data
)();
