import { Team, Teams } from '@models/Team';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

// Helpers
function normalizeTeam(teams: Team[]) {
  return convertArrayToIdMap(teams);
}

export async function createTeam(
  name: string,
  employees: string[],
): Promise<Team> {
  const { data: team } = await axios.post(
    '/teams',
    { name, employees }
  );

  return team;
}

export async function deleteTeam(id: string): Promise<void> {
  await axios.delete(`/teams/delete/id/${id}`);
}

export async function fetchTeams(): Promise<Teams> {
  const { data } = await axios.get('/teams');

  return normalizeTeam(data);
}

export async function updateTeam(
  id: string,
  name: string,
  employees: string[],
): Promise<void> {
  const { data: team } = await axios.put(
    '/teams',
    { id, name, employees }
  );

  return team;
}
