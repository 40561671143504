import Sensor, { Sensors } from '@models/Sensor';
import { convertArrayToIdMap } from './utils';
import { ISensorData } from './websocket';

export const normalizeSensors = (sensors: object[]): Sensors =>
  convertArrayToIdMap(sensors as Sensor[]);

export const normalizeSensor = (sensor: object): Sensor => sensor as Sensor;

/**
 * @description Transforms an array of sensor data in a JSON object with ts keys
 * @param       {ISensorData}                   sensorData API sensor data
 * @returns     {Record<string, ISensorData[]>} command    command, list
 */
export const normalizeSensorData = (
  sensorData: ISensorData[]
): Record<string, ISensorData[]> => {
  const jsonData = {} as Record<string, ISensorData[]>;

  for (let i = 0; i < sensorData.length; i += 1) {
    if (jsonData[sensorData[i].ts] === undefined) {
      jsonData[sensorData[i].ts] = [];
    }

    jsonData[sensorData[i].ts] = jsonData[sensorData[i].ts].concat(
      sensorData[i]
    );
  }

  return jsonData;
};
