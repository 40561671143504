import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/items';
import FetchError from '@models/FetchError';
import { Items } from '@models/Item';

type ItemsType = ActionType<typeof types>;

interface IImportForm {
  open: boolean;
  loading: boolean;
  error?: FetchError;
}
export interface IItemsState {
  readonly error?: FetchError;
  readonly items?: Items;
  readonly loading: boolean;
  readonly importItemsForm: IImportForm;
  readonly importStocksForm: IImportForm;
}

export const initialState: IItemsState = {
  importItemsForm: {
    loading: false,
    open: false,
  },
  importStocksForm: {
    loading: false,
    open: false,
  },
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IItemsState = initialState,
  action: ItemsType
): IItemsState => {
  switch (action.type) {
    case getType(types.fetchItemsWithLocations.request): {
      const { shallow } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        loading: shallow !== true ? true : loading,
      };
    }

    case getType(types.fetchItemsWithLocations.success): {
      const { shallow, items } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error: undefined,
        items,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.fetchItemsWithLocations.failure): {
      const { shallow, error } = action.payload;
      const { loading } = state;

      return {
        ...state,
        error,
        loading: shallow !== true ? false : loading,
      };
    }

    case getType(types.importItems.request):
      return {
        ...state,
        importItemsForm: {
          ...state.importItemsForm,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.importItems.success): {
      const { items } = state;

      return {
        ...state,
        importItemsForm: {
          ...state.importItemsForm,
          loading: false,
          open: false,
        },
        items: {
          ...(items || {}),
          ...action.payload,
        },
      };
    }

    case getType(types.importItems.failure): {
      const { error } = action.payload;

      return {
        ...state,
        importItemsForm: {
          ...state.importItemsForm,
          error,
          loading: false,
        },
      };
    }

    case getType(types.openImportItemsForm):
      return {
        ...state,
        importItemsForm: {
          loading: false,
          open: true,
        },
      };

    case getType(types.closeImportItemsForm):
      return {
        ...state,
        importItemsForm: {
          ...state.importItemsForm,
          open: false,
        },
      };

    case getType(types.importStocks.request):
      return {
        ...state,
        importStocksForm: {
          ...state.importStocksForm,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.importStocks.success): {
      const { items } = state;
      const stocks = action.payload;

      if (items) {
        for (let s = 0; s < stocks.length; s += 1) {
          let exist = false;

          const locations = (items[stocks[s].id] || {}).locations || [];
          for (let l = 0; l < locations.length; l += 1) {
            if (locations[l].location === stocks[s].location) {
              locations[l].quantity = stocks[s].quantity;

              exist = true;

              break;
            }
          }

          if (exist === false) {
            if (!(items[stocks[s].id] || {}).locations) {
              (items[stocks[s].id] || {}).locations = [];
            }

            ((items[stocks[s].id] || {}).locations || []).push(stocks[s]);
          }
        }
      }

      return {
        ...state,
        importStocksForm: {
          ...state.importStocksForm,
          loading: false,
          open: false,
        },
        items,
      };
    }

    case getType(types.importStocks.failure): {
      const { error } = action.payload;

      return {
        ...state,
        importStocksForm: {
          ...state.importStocksForm,
          error,
          loading: false,
        },
      };
    }

    case getType(types.openImportStocksForm):
      return {
        ...state,
        importStocksForm: {
          loading: false,
          open: true,
        },
      };

    case getType(types.closeImportStocksForm):
      return {
        ...state,
        importStocksForm: {
          ...state.importStocksForm,
          open: false,
        },
      };

    default:
      return state;
  }
};
