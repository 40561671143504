import { ConfigFile, ConfigFiles } from '@models/ConfigFile';
import axios from './axios';

export const CONFIGURATIONS_API = {
  postConfigurationFile: '/configurations/files/new',
  putConfigurationFile: '/configurations/files/edit',
};

export async function fetchConfigFiles(type: string): Promise<ConfigFiles> {
  const { data } = await axios.get(`/configurations/files/type/${type}`);

  return data;
}

export async function createConfigFile(
  type: string,
  filename: string,
  content: string,
  warehouseId?: string,
  floorplanId?: string,
  sensorGroupId?: string
): Promise<ConfigFile> {
  const { data: file } = await axios.post(
    CONFIGURATIONS_API.postConfigurationFile,
    {
      content,
      filename,
      floorplanId,
      sensorGroupId,
      type,
      warehouseId,
    }
  );

  return file;
}

export async function updateConfigFile(
  id: string,
  filename: string,
  content: string
): Promise<ConfigFile> {
  const { data: file } = await axios.put(
    CONFIGURATIONS_API.putConfigurationFile,
    {
      content,
      filename,
      id,
    }
  );

  return file;
}

export async function deleteConfigFile(id: string): Promise<void> {
  await axios.delete(`/configurations/files/delete/id/${id}`);
}
