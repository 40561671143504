import { ExtendedFile } from '@actions/tickets';
import Ticket, { TicketComment, Tickets } from '@models/Ticket';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export async function createTicket(
  subject: string,
  description: string,
  files: ExtendedFile[]
): Promise<Ticket> {
  const formData = new FormData();

  formData.append('subject', subject);
  formData.append('description', description);
  files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { data: ticket } = await axios.post('/tickets/new', formData);

  return ticket;
}

export async function updateTicket(
  id: string,
  subject: string,
  description: string,
  files: ExtendedFile[]
): Promise<Ticket> {
  const formData = new FormData();

  formData.append('id', id);
  formData.append('subject', subject);
  formData.append('description', description);
  files.forEach((file) => {
    if (!file.id) {
      formData.append('files[]', file);
    } else {
      formData.append('files[]', file.id);
    }
  });

  const { data: ticket } = await axios.put('/tickets/edit', formData);

  return ticket;
}

export async function archiveTicket(id: string): Promise<Ticket> {
  const { data: ticket } = await axios.put('/tickets/archive', { id });

  return ticket;
}

export async function deleteTicket(id: string): Promise<void> {
  await axios.delete(`/tickets/delete/id/${id}`);
}

export async function fetchTickets(
  ticketStatus: 'open' | 'archived'
): Promise<Tickets> {
  const { data } = await axios.get(`/tickets/status/${ticketStatus}`);

  return convertArrayToIdMap(
    data.map((d: any) => ({
      ...d,
      comments: convertArrayToIdMap(d.comments || []),
    }))
  );
}

export async function createTicketComment(
  ticketId: string,
  comment: string
): Promise<TicketComment> {
  const { data: ticket } = await axios.post('/tickets/comments/new', {
    ticketId,
    comment,
  });

  return ticket;
}

export async function deleteTicketComment(
  ticketId: string,
  id: string
): Promise<void> {
  await axios.delete(`/tickets/id/${ticketId}/comments/delete/id/${id}`);
}
