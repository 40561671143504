import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import { AssetTypes } from '@models/Asset';
import FetchError from '@models/FetchError';
import {
  ISimulationData,
  ISimulationWithConfig,
  ISimulatorConfig,
  Simulation,
  Simulations,
  SimulationStatus,
  SimulationStatusCodes,
} from '@models/Simulation';
import { IFailed, IFailedWithShallow, IFeedbackFailed } from './types';

export enum SimulationsTypes {
  CREATE = '@@simulations/CREATE',
  CREATE_SUCCEEDED = '@@simulations/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@simulations/CREATE_FAILED',

  DELETE = '@@simulations/DELETE',
  DELETE_SUCCEEDED = '@@simulations/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@simulations/DELETE_FAILED',

  STOP = '@@simulations/STOP',
  STOP_SUCCEEDED = '@@simulations/STOP_SUCCEEDED',
  STOP_FAILED = '@@simulations/STOP_FAILED',

  FETCH_SIMULATION = '@@simulations/FETCH_SIMULATION',
  FETCH_SIMULATION_SUCCEEDED = '@@simulations/FETCH_SIMULATION_SUCCEEDED',
  FETCH_SIMULATION_FAILED = '@@simulations/FETCH_SIMULATION_FAILED',

  FETCH_SIMULATION_DATA = '@@simulations/FETCH_SIMULATION_DATA',
  FETCH_SIMULATION_DATA_SUCCEEDED = '@@simulations/FETCH_SIMULATION_DATA_SUCCEEDED',
  FETCH_SIMULATION_DATA_FAILED = '@@simulations/FETCH_SIMULATION_DATA_FAILED',

  MY_ASSET_CHANGE = '@@simulations/MY_ASSET_CHANGE',

  FETCH_ROUTING = '@@simulations/FETCH_ROUTING',
  FETCH_ROUTING_SUCCEEDED = '@@simulations/FETCH_ROUTING_SUCCEEDED',
  FETCH_ROUTING_FAILED = '@@simulations/FETCH_ROUTING_FAILED',

  CREATE_ROUTING_PATHS = '@@simulations/CREATE_ROUTING_PATHS',
  CREATE_ROUTING_PATHS_SUCCEEDED = '@@simulations/CREATE_ROUTING_PATHS_SUCCEEDED',
  CREATE_ROUTING_PATHS_FAILED = '@@simulations/CREATE_ROUTING_PATHS_FAILED',

  FETCH_SIMULATIONS = '@@simulations/FETCH_SIMULATIONS',
  FETCH_SIMULATIONS_SUCCEEDED = '@@simulations/FETCH_SIMULATIONS_SUCCEEDED',
  FETCH_SIMULATIONS_FAILED = '@@simulations/FETCH_SIMULATIONS_FAILED',

  WS_CREATE = '@@simulations/WS_CREATE',
  WS_DELETE = '@@simulations/WS_DELETE',
  WS_UPDATE_STATUS = '@@simulations/WS_UPDATE_STATUS',
}

interface IFetchSimulation {
  id: string;
}
export interface IAgentInfo {
  assetId?: string;
  type?: AssetTypes;
  capacity?: number;
  shift: number;
}
export interface ICreateSimulation {
  agents: IAgentInfo[];
  floorplanId: string;
  modelFloorplanId: string;
  length: number;
  name: string;
  orderVolume: number;
  router: string | undefined;
  routerDynamic: boolean | undefined;
  inputCsv?: string;
  startTs: number;
  shifts: Record<number, boolean>[];
}
interface IDeleteSimulation {
  id: string;
}
export interface IStopSimulation extends IDeleteSimulation {
  intl: IntlShape;
}
interface IWSSimulationStatusUpdate {
  id: string;
  status: SimulationStatus;
  statusCode: SimulationStatusCodes;
  progressPercentage?: number;
}
export interface IFetchSimulationRun {
  simulationId: string;
  id: string;
}
interface IFetchSimulationData extends IFetchSimulation {
  timestamp: number;
}
interface IFetchSimulationDataSuccess extends IFetchSimulationData {
  data: Record<string, ISimulationData[]>;
}
interface IFetchSimulationDataError extends IFetchSimulationData {
  lastErrorTs: number;
  error: FetchError;
}

export const fetchSimulation = createAsyncAction(
  SimulationsTypes.FETCH_SIMULATION,
  SimulationsTypes.FETCH_SIMULATION_SUCCEEDED,
  SimulationsTypes.FETCH_SIMULATION_FAILED
)<IFetchSimulation, ISimulationWithConfig, IFailed>();

export interface IFetchSimulations {
  floorplanId: string;
  shallow?: boolean;
}
interface IFetchSimulationsSuccess extends IFetchSimulations {
  simulations: Simulations;
}
export const fetchSimulations = createAsyncAction(
  SimulationsTypes.FETCH_SIMULATIONS,
  SimulationsTypes.FETCH_SIMULATIONS_SUCCEEDED,
  SimulationsTypes.FETCH_SIMULATIONS_FAILED
)<IFetchSimulations, IFetchSimulationsSuccess, IFailedWithShallow>();

/* eslint-disable @typescript-eslint/indent */
export const fetchSimulationData = createAsyncAction(
  SimulationsTypes.FETCH_SIMULATION_DATA,
  SimulationsTypes.FETCH_SIMULATION_DATA_SUCCEEDED,
  SimulationsTypes.FETCH_SIMULATION_DATA_FAILED
)<
  IFetchSimulationData,
  IFetchSimulationDataSuccess,
  IFetchSimulationDataError
>();
/* eslint-enable @typescript-eslint/indent */

export const onMyAssetChange   = createAction(
  SimulationsTypes.MY_ASSET_CHANGE,
  (floorplanId: string, assetId: string) =>
    ({ floorplanId, assetId })
)();

export interface IFetchRouting {
  floorplanId: string;
}
interface IFetchRoutingSuccess extends IFetchRouting {
  data: ISimulatorConfig;
}
interface IFetchRoutingFailed extends IFetchRouting {
  error: FetchError;
}
export const fetchRoutingData = createAsyncAction(
  SimulationsTypes.FETCH_ROUTING,
  SimulationsTypes.FETCH_ROUTING_SUCCEEDED,
  SimulationsTypes.FETCH_ROUTING_FAILED
)<IFetchRouting, IFetchRoutingSuccess, IFetchRoutingFailed>();

export interface ICreateRoutingPaths {
  floorplanId: string;
  position: [number, number];
  points: [number, number][];
}
interface ICreateRoutingPathsSuccess {
  floorplanId: string;
  data: { path: [number, number][], orders: [number, number][] };
}
interface ICreateRoutingPathsFailed {
  floorplanId: string;
  error: FetchError;
}
export const createRoutingPaths = createAsyncAction(
  SimulationsTypes.CREATE_ROUTING_PATHS,
  SimulationsTypes.CREATE_ROUTING_PATHS_SUCCEEDED,
  SimulationsTypes.CREATE_ROUTING_PATHS_FAILED
)<ICreateRoutingPaths, ICreateRoutingPathsSuccess, ICreateRoutingPathsFailed>();

export const createSimulation = createAsyncAction(
  SimulationsTypes.CREATE,
  SimulationsTypes.CREATE_SUCCEEDED,
  SimulationsTypes.CREATE_FAILED
)<ICreateSimulation, Simulation, IFailed>();

export const stopSimulation = createAsyncAction(
  SimulationsTypes.STOP,
  SimulationsTypes.STOP_SUCCEEDED,
  SimulationsTypes.STOP_FAILED
)<IStopSimulation, IDeleteSimulation, IFeedbackFailed>();

export const deleteSimulation = createAsyncAction(
  SimulationsTypes.DELETE,
  SimulationsTypes.DELETE_SUCCEEDED,
  SimulationsTypes.DELETE_FAILED
)<IDeleteSimulation, IDeleteSimulation, IFailed>();

export const wsCreateSimulation = createAction(
  SimulationsTypes.WS_CREATE,
  (data: Simulation) => data
)();
export const wsSimulationStatusUpdate = createAction(
  SimulationsTypes.WS_UPDATE_STATUS,
  (data: IWSSimulationStatusUpdate) => data
)();
export const wsDeleteSimulation = createAction(
  SimulationsTypes.WS_DELETE,
  (data: IDeleteSimulation) => data
)();
