import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Diagram, { Diagrams } from '@models/Diagram';
import { IFailed, IFeedbackFailed } from './types';

export enum DiagramsTypes {
  FETCH = '@@diagrams/FETCH',
  FETCH_SUCCEEDED = '@@diagrams/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@diagrams/FETCH_FAILED',

  CREATE = '@@diagrams/CREATE',
  CREATE_SUCCEEDED = '@@diagrams/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@diagrams/CREATE_FAILED',

  UPDATE = '@@diagrams/UPDATE',
  UPDATE_SUCCEEDED = '@@diagrams/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@diagrams/UPDATE_FAILED',

  DELETE = '@@diagrams/DELETE',
  DELETE_SUCCEEDED = '@@diagrams/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@diagrams/DELETE_FAILED',

  WS_CREATE = '@@diagrams/WS_CREATE',
  WS_UPDATE = '@@diagrams/WS_UPDATE',
  WS_DELETE = '@@diagrams/WS_DELETE',
}

interface IFetchSuccess {
  diagrams: Diagrams;
}

export const fetchDiagrams = createAsyncAction(
  DiagramsTypes.FETCH,
  DiagramsTypes.FETCH_SUCCEEDED,
  DiagramsTypes.FETCH_FAILED
)<void, IFetchSuccess, IFailed>();

export interface ICreateDiagram {
  intl: IntlShape;

  name: string;
  diagram: object;
}
export const createDiagram = createAsyncAction(
  DiagramsTypes.CREATE,
  DiagramsTypes.CREATE_SUCCEEDED,
  DiagramsTypes.CREATE_FAILED
)<ICreateDiagram, Diagram, IFeedbackFailed>();

export interface IUpdateDiagram extends ICreateDiagram {
  id: string;
}

export const updateDiagram = createAsyncAction(
  DiagramsTypes.UPDATE,
  DiagramsTypes.UPDATE_SUCCEEDED,
  DiagramsTypes.UPDATE_FAILED
)<IUpdateDiagram, Diagram, IFeedbackFailed>();

export interface IDeleteDiagram {
  id: string;
  intl: IntlShape;
}
export const deleteDiagram = createAsyncAction(
  DiagramsTypes.DELETE,
  DiagramsTypes.DELETE_SUCCEEDED,
  DiagramsTypes.DELETE_FAILED
)<IDeleteDiagram, IDeleteDiagram, IFeedbackFailed>();

interface IWSDiagramUpdated {
  diagram: Diagram;
}
interface IWSDiagramDeleted {
  id: string;
}

export const wsCreateDiagram = createAction(
  DiagramsTypes.WS_CREATE,
  (data: IWSDiagramUpdated) => data
)();
export const wsUpdateDiagram = createAction(
  DiagramsTypes.WS_UPDATE,
  (data: IWSDiagramUpdated) => data
)();
export const wsDeleteDiagram = createAction(
  DiagramsTypes.WS_DELETE,
  (data: IWSDiagramDeleted) => data
)();
