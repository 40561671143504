import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Maintenance from '@models/Maintenance';
import { IFailed, IFeedbackFailed } from './types';

export enum MaintenancesTypes {
  CREATE = '@@maintenance/CREATE',
  CREATE_SUCCEEDED = '@@maintenance/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@maintenance/CREATE_FAILED',

  FETCH_BYASSET = '@@maintenance/FETCH_BYASSET',
  FETCH_BYASSET_SUCCEEDED = '@@maintenance/FETCH_BYASSET_SUCCEEDED',
  FETCH_BYASSET_FAILED = '@@maintenance/FETCH_BYASSET_FAILED',

  OPEN_MAINTENANCE_FORM = '@@maintenance/OPEN_MAINTENANCE_FORM',
  CLOSE_MAINTENANCE_FORM = '@@maintenance/CLOSE_MAINTENANCE_FORM',
}

export interface ICreateMaintenance {
  assetId: string;
  type: string;
  observations: string;
  intl: IntlShape;
}
interface ICreateMaintenanceSuccess {
  maintenance: Maintenance;
  intl: IntlShape;
}

export const createMaintenance = createAsyncAction(
  MaintenancesTypes.CREATE,
  MaintenancesTypes.CREATE_SUCCEEDED,
  MaintenancesTypes.CREATE_FAILED
)<ICreateMaintenance, ICreateMaintenanceSuccess, IFeedbackFailed>();

interface IFetchMaintenancesByAsset {
  assetId: string;
}
interface ISuccessMaintenancesByAsset extends IFetchMaintenancesByAsset {
  maintenances: Maintenance[];
}

interface IFailedMaintenancesByAsset extends IFailed {
  assetId: string;
}

export const fetchMaintenancesByAsset = createAsyncAction(
  MaintenancesTypes.FETCH_BYASSET,
  MaintenancesTypes.FETCH_BYASSET_SUCCEEDED,
  MaintenancesTypes.FETCH_BYASSET_FAILED
)<
  IFetchMaintenancesByAsset,
  ISuccessMaintenancesByAsset,
  IFailedMaintenancesByAsset
>();

export const openMaintenanceForm = createAction(
  MaintenancesTypes.OPEN_MAINTENANCE_FORM,
  (assetId: string) => ({ assetId })
)();
export const closeMaintenanceForm = createAction(
  MaintenancesTypes.CLOSE_MAINTENANCE_FORM,
)();
