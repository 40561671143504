import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { Rule, Rules } from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';
import { convertArrayToIdMap } from './utils';

interface IAPIRuleTemplate {
  id: string;
  name: string;
  help: string;
  uiSchema: UiSchema | undefined;
  schema: JSONSchema6;
}

export function normalizeTemplates(
  templates: IAPIRuleTemplate[]
): RuleTemplates {
  return convertArrayToIdMap(templates);
}

export function normalizeRules(rules: Rule[]): Rules {
  return convertArrayToIdMap(rules);
}
