import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/chats';
import ChatMessage from '@models/ChatMessage';
import FetchError from '@models/FetchError';
import Account from '@models/Account';

type ConfigurationType = ActionType<typeof types | typeof auth>;

interface Chat {
  messages: ChatMessage[];
  error?: FetchError;
  loading: boolean;
}

export interface IChatsState {
  readonly account?: Account;
  readonly chats: Record<string, Chat>;
}

export const initialState: IChatsState = {
  chats: {},
};

export default (
  state: IChatsState = initialState,
  action: ConfigurationType
): IChatsState => {
  switch (action.type) {
    case getType(types.fetchMessages.request): {
      const { toAccountId } = action.payload;

      return {
        ...state,
        chats: {
          ...state.chats,
          [toAccountId]: {
            messages: [],
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchMessages.success): {
      const { toAccountId, messages } = action.payload;

      return {
        ...state,
        chats: {
          ...state.chats,
          [toAccountId]: {
            messages,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.createMessage.request): {
      const { toAccountId } = action.payload;

      return {
        ...state,
        chats: {
          ...state.chats,
          [toAccountId || 0]: {
            ...(state.chats || {})[toAccountId || 0],
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.createMessage.success): {
      const { toAccountId, message } = action.payload;
      const { messages } = (state.chats || {})[toAccountId || 0] || {};

      return {
        ...state,
        chats: {
          ...state.chats,
          [toAccountId || 0]: {
            ...(state.chats || {})[toAccountId || 0],
            messages: [...(messages || []), message],
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchMessages.failure):
    case getType(types.createMessage.failure): {
      const { toAccountId, error } = action.payload;

      return {
        ...state,
        chats: {
          ...state.chats,
          [toAccountId || 0]: {
            ...(state.chats || {})[toAccountId || 0],
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.wsChatMessage): {
      const { toAccountId, fromAccountId } = action.payload;
      const { account } = state;

      let key = toAccountId;
      if ((account || {}).id === toAccountId) {
        key = fromAccountId;
      }

      const { messages } = (state.chats || {})[key] || {};

      return {
        ...state,
        chats: {
          ...state.chats,
          [key]: {
            ...(state.chats || {})[key],
            messages: [...(messages || []), action.payload],
          },
        },
      };
    }

    case getType(auth.fetchAccount.success): {
      const account = action.payload;

      return {
        ...state,
        account,
      };
    }

    default:
      return state;
  }
};
