import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/shifts';
import {
  createShift,
  deleteShift,
  fetchShifts,
  updateShift,
} from '@api/shifts';
import { treatFetchError } from '@api/utils';
import { Shift, Shifts } from '@models/Shift';

export function* deleteShiftSaga(
  action: ActionType<typeof types.deleteShift.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteShift, id);

    yield put(types.deleteShift.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteShift.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* createShiftSaga(
  action: ActionType<typeof types.createShift.request>
) {
  const { intl, name, employees, periods } = action.payload;

  try {
    const shift: Shift = yield call(
      createShift,
      name,
      employees,
      periods
    );

    yield put(types.createShift.success({ shift, intl }));
  } catch (error: any) {
    yield put(
      types.createShift.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchShiftsSaga(
  action: ActionType<typeof types.fetchShifts.request>
) {
  const { shallow } = action.payload;

  try {
    const shifts: Shifts = yield call(fetchShifts);

    yield put(types.fetchShifts.success({ shifts, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchShifts.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* updateShiftSaga(
  action: ActionType<typeof types.updateShift.request>
) {
  const { id, intl, name, employees, periods } = action.payload;

  try {
    const shift: Shift = yield call(
      updateShift,
      id,
      name,
      employees,
      periods,
    );

    yield put(types.updateShift.success({ shift, intl }));
  } catch (error: any) {
    yield put(
      types.updateShift.failure({ error: treatFetchError(error), intl })
    );
  }
}
