import { Employee, Employees } from '@models/Employee';
import { IncompleteAssetAssociation } from '@models/IncompleteAssetAssociation';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

// Helpers
function normalizeEmployee(employees: Employee[]) {
  return convertArrayToIdMap(employees);
}

function employeeToFormData(
  assetAssociations: IncompleteAssetAssociation[] | undefined,
  file: File | undefined,
  id: string,
  image: string | undefined,
  name: string,
  email: string,
  password: string
): FormData {
  const formData = new FormData();

  if (id !== '') {
    formData.append('id', id);
  }
  if (file !== undefined) {
    formData.append('image', file);
  } else if (image !== undefined) {
    formData.append('image', image);
  } else {
    formData.append('image', 'null');
  }
  formData.append('name', name);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('assetAssociations', JSON.stringify(assetAssociations));

  return formData;
}

export async function createEmployee(
  assetAssociations: IncompleteAssetAssociation[] | undefined,
  file: File | undefined,
  name: string,
  email: string,
  password: string
): Promise<Employee> {
  const { data: asset } = await axios.post(
    '/employees',
    employeeToFormData(assetAssociations, file, '', undefined, name, email, password)
  );

  return asset;
}

export async function deleteEmployee(id: string): Promise<void> {
  await axios.delete(`/employees/delete/id/${id}`);
}

export async function fetchEmployees(): Promise<Employees> {
  const { data } = await axios.get('/employees');

  return normalizeEmployee(data);
}

export async function updateEmployee(
  assetAssociations: IncompleteAssetAssociation[] | undefined,
  file: File | undefined,
  id: string,
  image: string | undefined,
  name: string,
  email: string,
  password: string
): Promise<void> {
  const { data: asset } = await axios.put(
    '/employees',
    employeeToFormData(assetAssociations, file, id, image, name, email, password)
  );

  return asset;
}
