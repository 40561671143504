import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import Asset, { Assets } from '@models/Asset';
import AssetTicket, {
  AssetTickets,
  AssetTicketComment,
  AssetTicketStatus,
  AssetTicketOperation,
} from '@models/AssetTicket';
import AssetDocument, {
  AssetDocuments,
  AssetDocumentTypes,
} from '@models/AssetDocument';
import { IncompleteSensorAssociation } from '@models/IncompleteSensorAssociation';
import { IFailed, IFailedWithShallow, IFeedbackFailed } from './types';

export enum AssetsTypes {
  DELETE = '@@assets/DELETE',
  DELETE_SUCCEEDED = '@@assets/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@assets/DELETE_FAILED',

  FETCH = '@@assets/FETCH',
  FETCH_SUCCEEDED = '@@assets/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@assets/FETCH_FAILED',

  CREATE = '@@assets/CREATE',
  CREATE_SUCCEEDED = '@@assets/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@assets/CREATE_FAILED',

  UPDATE = '@@assets/UPDATE',
  UPDATE_SUCCEEDED = '@@assets/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@assets/UPDATE_FAILED',

  UPDATE_PRECHECK = '@@assets/UPDATE_PRECHECK',
  UPDATE_PRECHECK_SUCCEEDED = '@@assets/UPDATE_PRECHECK_SUCCEEDED',
  UPDATE_PRECHECK_FAILED = '@@assets/UPDATE_PRECHECK_FAILED',

  OPEN_TICKET_FORM = '@@assets/OPEN_TICKET_FORM',
  CLOSE_TICKET_FORM = '@@assets/CLOSE_TICKET_FORM',

  FETCH_TICKETS = '@@assets/FETCH_TICKETS',
  FETCH_TICKETS_SUCCEEDED = '@@assets/FETCH_TICKETS_SUCCEEDED',
  FETCH_TICKETS_FAILED = '@@assets/FETCH_TICKETS_FAILED',

  FETCH_TICKET = '@@assets/FETCH_TICKET',
  FETCH_TICKET_SUCCEEDED = '@@assets/FETCH_TICKET_SUCCEEDED',
  FETCH_TICKET_FAILED = '@@assets/FETCH_TICKET_FAILED',

  CREATE_TICKET = '@@assets/CREATE_TICKET',
  CREATE_TICKET_SUCCEEDED = '@@assets/CREATE_TICKET_SUCCEEDED',
  CREATE_TICKET_FAILED = '@@assets/CREATE_TICKET_FAILED',

  CREATE_TICKET_COMMENT = '@@assets/CREATE_TICKET_COMMENT',
  CREATE_TICKET_COMMENT_SUCCEEDED = '@@assets/CREATE_TICKET_COMMENT_SUCCEEDED',
  CREATE_TICKET_COMMENT_FAILED = '@@assets/CREATE_TICKET_COMMENT_FAILED',

  ASSIGN_ASSET_TICKET = '@@assets/ASSIGN_ASSET_TICKET',
  ASSIGN_ASSET_TICKET_SUCCEEDED = '@@assets/ASSIGN_ASSET_TICKET_SUCCEEDED',
  ASSIGN_ASSET_TICKET_FAILED = '@@assets/ASSIGN_ASSET_TICKET_FAILED',

  CHANGE_ASSET_TICKET_STATUS = '@@assets/CHANGE_ASSET_TICKET_STATUS',
  CHANGE_ASSET_TICKET_STATUS_SUCCEEDED = '@@assets/CHANGE_ASSET_TICKET_STATUS_SUCCEEDED',
  CHANGE_ASSET_TICKET_STATUS_FAILED = '@@assets/CHANGE_ASSET_TICKET_STATUS_FAILED',

  ARCHIVE_ASSET_TICKET = '@@assets/ARCHIVE_ASSET_TICKET',
  ARCHIVE_ASSET_TICKET_SUCCEEDED = '@@assets/ARCHIVE_ASSET_TICKET_SUCCEEDED',
  ARCHIVE_ASSET_TICKET_FAILED = '@@assets/ARCHIVE_ASSET_TICKET_FAILED',

  CREATE_ASSET_TICKET_OPERATION = '@@assets/CREATE_ASSET_TICKET_OPERATION',
  CREATE_ASSET_TICKET_OPERATION_SUCCEEDED = '@@assets/CREATE_ASSET_TICKET_OPERATION_SUCCEEDED',
  CREATE_ASSET_TICKET_OPERATION_FAILED = '@@assets/CREATE_ASSET_TICKET_OPERATION_FAILED',

  DELETE_ASSET_TICKET_OPERATION = '@@assets/DELETE_ASSET_TICKET_OPERATION',
  DELETE_ASSET_TICKET_OPERATION_SUCCEEDED = '@@assets/DELETE_ASSET_TICKET_OPERATION_SUCCEEDED',
  DELETE_ASSET_TICKET_OPERATION_FAILED = '@@assets/DELETE_ASSET_TICKET_OPERATION_FAILED',

  FETCH_DOCUMENTS = '@@assets/FETCH_DOCUMENTS',
  FETCH_DOCUMENTS_SUCCEEDED = '@@assets/FETCH_DOCUMENTS_SUCCEEDED',
  FETCH_DOCUMENTS_FAILED = '@@assets/FETCH_DOCUMENTS_FAILED',

  CREATE_DOCUMENT = '@@assets/CREATE_DOCUMENT',
  CREATE_DOCUMENT_SUCCEEDED = '@@assets/CREATE_DOCUMENT_SUCCEEDED',
  CREATE_DOCUMENT_FAILED = '@@assets/CREATE_DOCUMENT_FAILED',

  DELETE_DOCUMENT = '@@assets/DELETE_DOCUMENT',
  DELETE_DOCUMENT_SUCCEEDED = '@@assets/DELETE_DOCUMENT_SUCCEEDED',
  DELETE_DOCUMENT_FAILED = '@@assets/DELETE_DOCUMENT_FAILED',

  WS_CREATE = '@@assets/WS_CREATE',
  WS_UPDATE = '@@assets/WS_UPDATE',
  WS_DELETE = '@@assets/WS_DELETE',

  WS_CREATE_TICKET = '@@assets/WS_CREATE_TICKET',
  WS_CREATE_TICKET_COMMENT = '@@assets/WS_CREATE_TICKET_COMMENT',
  WS_ASSIGN_ASSET_TICKET = '@@assets/WS_ASSIGN_ASSET_TICKET',
  WS_CHANGE_ASSET_TICKET_STATUS = '@@assets/WS_CHANGE_ASSET_TICKET_STATUS',
  WS_ARCHIVE_ASSET_TICKET_STATUS = '@@assets/WS_ARCHIVE_ASSET_TICKET_STATUS',
  WS_CREATE_DOCUMENT = '@@assets/WS_CREATE_DOCUMENT',
  WS_DELETE_DOCUMENT = '@@assets/WS_DELETE_DOCUMENT',

  OPENFORM = '@@assets/OPENFORM',
  CLOSEFORM = '@@assets/CLOSEFORM',
}

export interface ICreateAsset {
  color: string;
  floorplanId: string;
  name: string;
  file?: File;
  sensorAssociations?: IncompleteSensorAssociation[];
  tags: string[];
  type: string;
  intl: IntlShape;
}

export interface IUpdateAsset extends ICreateAsset {
  id: string;
  image?: string;
}

export interface IUpdateAssetPreCheck {
  id: string;
  intl: IntlShape;
  preCheckId: string | null;
}

export interface IDeleteAsset {
  id: string;
  intl: IntlShape;
}

export interface IActionSuccess {
  asset: Asset;
  intl: IntlShape;
}

interface IWSAssetUpdated {
  asset: Asset;
}
interface IWSAssetDeleted {
  id: string;
}

export const deleteAsset = createAsyncAction(
  AssetsTypes.DELETE,
  AssetsTypes.DELETE_SUCCEEDED,
  AssetsTypes.DELETE_FAILED
)<IDeleteAsset, IDeleteAsset, IFeedbackFailed>();

export const createAsset = createAsyncAction(
  AssetsTypes.CREATE,
  AssetsTypes.CREATE_SUCCEEDED,
  AssetsTypes.CREATE_FAILED
)<ICreateAsset, IActionSuccess, IFeedbackFailed>();

export const updateAsset = createAsyncAction(
  AssetsTypes.UPDATE,
  AssetsTypes.UPDATE_SUCCEEDED,
  AssetsTypes.UPDATE_FAILED
)<IUpdateAsset, IActionSuccess, IFeedbackFailed>();

export const updateAssetPreCheck = createAsyncAction(
  AssetsTypes.UPDATE_PRECHECK,
  AssetsTypes.UPDATE_PRECHECK_SUCCEEDED,
  AssetsTypes.UPDATE_PRECHECK_FAILED
)<IUpdateAssetPreCheck, IActionSuccess, IFeedbackFailed>();

export interface IFetchAssets {
  shallow?: boolean;
}
interface IFetchAssetsSuccess {
  assets: Assets;
  shallow?: boolean;
}
export const fetchAssets = createAsyncAction(
  AssetsTypes.FETCH,
  AssetsTypes.FETCH_SUCCEEDED,
  AssetsTypes.FETCH_FAILED
)<IFetchAssets, IFetchAssetsSuccess, IFailedWithShallow>();

export const openTicketForm = createAction(
  AssetsTypes.OPEN_TICKET_FORM,
  (data?: Asset) => data
)();
export const closeTicketForm = createAction(
  AssetsTypes.CLOSE_TICKET_FORM,
)();

interface IFetchAssetData {
  assetId: string;
}

export const fetchAssetTickets = createAsyncAction(
  AssetsTypes.FETCH_TICKETS,
  AssetsTypes.FETCH_TICKETS_SUCCEEDED,
  AssetsTypes.FETCH_TICKETS_FAILED
)<IFetchAssetData, AssetTickets, IFailed>();

interface IFetchAssetTicket {
  ticketId: string;
}

export const fetchAssetTicket = createAsyncAction(
  AssetsTypes.FETCH_TICKET,
  AssetsTypes.FETCH_TICKET_SUCCEEDED,
  AssetsTypes.FETCH_TICKET_FAILED
)<IFetchAssetTicket, AssetTicket, IFailed>();

export interface CreateAssetTicket {
  assetId: string;
  type: string;
  eventDate?: Date;
  subject: string;
  description: string;
  workImpairingIssue: boolean;
  files: File[];
}

export const createAssetTicket = createAsyncAction(
  AssetsTypes.CREATE_TICKET,
  AssetsTypes.CREATE_TICKET_SUCCEEDED,
  AssetsTypes.CREATE_TICKET_FAILED
)<CreateAssetTicket, AssetTicket, IFailed>();

export interface CreateAssetTicketComment {
  ticketId: string;
  comment: string;
}

export const createAssetTicketComment = createAsyncAction(
  AssetsTypes.CREATE_TICKET_COMMENT,
  AssetsTypes.CREATE_TICKET_COMMENT_SUCCEEDED,
  AssetsTypes.CREATE_TICKET_COMMENT_FAILED
)<CreateAssetTicketComment, AssetTicketComment, IFailed>();

export interface AssignAssetTicket {
  ticketId: string;
  accountId: string;
}
export interface AssignAssetTicketSuccess {
  ticketId: string;
  accountId: string;
  accountName: string;
  accountImage?: string;
}
export const assignAssetTicket = createAsyncAction(
  AssetsTypes.ASSIGN_ASSET_TICKET,
  AssetsTypes.ASSIGN_ASSET_TICKET_SUCCEEDED,
  AssetsTypes.ASSIGN_ASSET_TICKET_FAILED
)<AssignAssetTicket, AssignAssetTicketSuccess, IFailed>();

export interface ChangeAssetTicketStatus {
  ticketId: string;
  status: AssetTicketStatus;
}
export const changeAssetTicketStatus = createAsyncAction(
  AssetsTypes.CHANGE_ASSET_TICKET_STATUS,
  AssetsTypes.CHANGE_ASSET_TICKET_STATUS_SUCCEEDED,
  AssetsTypes.CHANGE_ASSET_TICKET_STATUS_FAILED
)<ChangeAssetTicketStatus, ChangeAssetTicketStatus, IFailed>();

export interface ArchiveAssetTicket {
  ticketId: string;
}
export const archiveAssetTicket = createAsyncAction(
  AssetsTypes.ARCHIVE_ASSET_TICKET,
  AssetsTypes.ARCHIVE_ASSET_TICKET_SUCCEEDED,
  AssetsTypes.ARCHIVE_ASSET_TICKET_FAILED
)<ArchiveAssetTicket, ArchiveAssetTicket, IFailed>();

export interface CreateAssetTicketOperation {
  ticketId: string;
  name: string;
  description: string;
  cost: number;
}
export const createAssetTicketOperation = createAsyncAction(
  AssetsTypes.CREATE_ASSET_TICKET_OPERATION,
  AssetsTypes.CREATE_ASSET_TICKET_OPERATION_SUCCEEDED,
  AssetsTypes.CREATE_ASSET_TICKET_OPERATION_FAILED
)<CreateAssetTicketOperation, AssetTicketOperation, IFailed>();

export interface DeleteAssetTicketOperation {
  id: string;
}
export const deleteAssetTicketOperation = createAsyncAction(
  AssetsTypes.DELETE_ASSET_TICKET_OPERATION,
  AssetsTypes.DELETE_ASSET_TICKET_OPERATION_SUCCEEDED,
  AssetsTypes.DELETE_ASSET_TICKET_OPERATION_FAILED
)<DeleteAssetTicketOperation, DeleteAssetTicketOperation, IFailed>();

export const fetchAssetDocuments = createAsyncAction(
  AssetsTypes.FETCH_DOCUMENTS,
  AssetsTypes.FETCH_DOCUMENTS_SUCCEEDED,
  AssetsTypes.FETCH_DOCUMENTS_FAILED
)<IFetchAssetData, AssetDocuments, IFailed>();

export interface CreateAssetDocument {
  assetId: string;
  type: AssetDocumentTypes;
  name: string;
  file: File;
}

export const createAssetDocument = createAsyncAction(
  AssetsTypes.CREATE_DOCUMENT,
  AssetsTypes.CREATE_DOCUMENT_SUCCEEDED,
  AssetsTypes.CREATE_DOCUMENT_FAILED
)<CreateAssetDocument, AssetDocument, IFailed>();

export interface DeleteAssetDocument {
  id: string;
  intl: IntlShape;
}

export const deleteAssetDocument = createAsyncAction(
  AssetsTypes.DELETE_DOCUMENT,
  AssetsTypes.DELETE_DOCUMENT_SUCCEEDED,
  AssetsTypes.DELETE_DOCUMENT_FAILED
)<DeleteAssetDocument, DeleteAssetDocument, IFeedbackFailed>();

export const openAssetForm = createAction(
  AssetsTypes.OPENFORM,
  (data?: Asset) => data
)();
export const closeAssetForm = createAction(
  AssetsTypes.CLOSEFORM,
)();

export const wsCreateAsset = createAction(
  AssetsTypes.WS_CREATE,
  (data: IWSAssetUpdated) => data
)();
export const wsUpdateAsset = createAction(
  AssetsTypes.WS_UPDATE,
  (data: IWSAssetUpdated) => data
)();
export const wsDeleteAsset = createAction(
  AssetsTypes.WS_DELETE,
  (data: IWSAssetDeleted) => data
)();
export const wsCreateAssetTicket = createAction(
  AssetsTypes.WS_CREATE_TICKET,
  (data: AssetTicket) => data
)();
export const wsCreateAssetTicketComment = createAction(
  AssetsTypes.WS_CREATE_TICKET_COMMENT,
  (data: AssetTicketComment) => data
)();
export const wsAssignAssetTicket = createAction(
  AssetsTypes.WS_ASSIGN_ASSET_TICKET,
  (data: AssignAssetTicketSuccess) => data
)();
export const wsChangeAssetTicketStatus = createAction(
  AssetsTypes.WS_CHANGE_ASSET_TICKET_STATUS,
  (data: ChangeAssetTicketStatus) => data
)();
export const wsChangeAssetTicketArchive = createAction(
  AssetsTypes.WS_ARCHIVE_ASSET_TICKET_STATUS,
  (data: ArchiveAssetTicket) => data
)();
export const wsCreateAssetDocument = createAction(
  AssetsTypes.WS_CREATE_DOCUMENT,
  (data: AssetDocument) => data
)();
export const wsDeleteAssetDocument = createAction(
  AssetsTypes.WS_DELETE_DOCUMENT,
  (data: DeleteAssetDocument) => data
)();
