import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/notifications';
import * as rules from '@actions/rules';
import { Notification } from '@models/Notification';

type NotificationsAction = ActionType<
  typeof types | typeof rules | typeof auth
>;

export interface INotificationsState {
  readonly countLoading: boolean;
  readonly countError?: object;
  readonly dismissLoading: boolean;
  readonly error?: object;
  readonly notifications: Record<string, Notification[]>;
  readonly notificationsCount?: number;
  readonly loading: boolean;
  readonly unreadLoading: boolean;
}

export const initialState: INotificationsState = {
  countLoading: false,
  dismissLoading: false,
  loading: false,
  notifications: {},
  unreadLoading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: INotificationsState = initialState,
  action: NotificationsAction
): INotificationsState => {
  switch (action.type) {
    case getType(types.fetchNotifications.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchNotifications.success):
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };

    case getType(types.fetchNotifications.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.fetchNotificationsCount.request):
      return {
        ...state,
        countError: undefined,
        countLoading: true,
      };

    case getType(types.fetchNotificationsCount.success):
      return {
        ...state,
        countLoading: false,
        notificationsCount: action.payload as number,
      };

    case getType(types.fetchNotificationsCount.failure): {
      const { error } = action.payload;

      return {
        ...state,
        countError: error,
        countLoading: false,
      };
    }

    case getType(types.dismissNotifications.request): {
      const { ids } = action.payload;
      const { notifications } = state;

      ids.forEach((nId) => {
        const index = (notifications[nId.type] || []).findIndex(
          (n) => n.id === nId.id
        );
        (notifications[nId.type] || []).splice(index, 1);
      });

      return {
        ...state,
        dismissLoading: true,
        error: undefined,
        notifications: {
          ...state.notifications,
          alerts: notifications.alerts,
          notifications: notifications.notifications,
        },
      };
    }

    case getType(types.dismissNotifications.success): {
      const dismissedNotifications = action.payload;
      const { alerts, notifications } = dismissedNotifications;

      return {
        ...state,
        dismissLoading: false,
        notificationsCount:
          (state.notificationsCount || 0) -
          alerts.length -
          notifications.length,
      };
    }

    case getType(types.dismissNotifications.failure): {
      const { error } = action.payload;

      return {
        ...state,
        dismissLoading: false,
        error,
      };
    }

    case getType(types.handleNotification): {
      const { type, notification } = action.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [type]: [...(state.notifications[type] || []), notification],
        },
        notificationsCount: (state.notificationsCount || 0) + 1,
      };
    }

    case getType(types.unreadNotifications.request):
      return {
        ...state,
        error: undefined,
        notifications: {
          ...state.notifications,
        },
        unreadLoading: true,
      };

    case getType(types.unreadNotifications.success): {
      const unreadedNotifications = action.payload;
      const { notifications, notificationsCount } = state;

      let unreadCount = 0;
      Object.keys(unreadedNotifications).forEach((k) => {
        unreadedNotifications[k].forEach((notification) => {
          unreadCount += 1;
          notifications[k].push(notification);
        });
      });

      return {
        ...state,
        error: undefined,
        notifications,
        notificationsCount: (notificationsCount || 0) + unreadCount,
        unreadLoading: false,
      };
    }

    case getType(types.unreadNotifications.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        unreadLoading: false,
      };
    }

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
