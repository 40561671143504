import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import Account from '@models/Account';
import FetchError from '@models/FetchError';

type AuthenticationAction = ActionType<typeof auth>;

export interface IReqState {
  readonly error?: FetchError;
  readonly loading: boolean;
  readonly success: boolean;
}
export interface ILoginState {
  readonly error?: FetchError;
  readonly loading: boolean;
}
export interface IPasswordRecoveryState {
  readonly error?: FetchError;
  readonly loading: boolean;
}
export interface IPasswordResetState {
  readonly error?: FetchError;
  readonly loading: boolean;
}
export interface ISettingsState {
  readonly changed: boolean;
  readonly error?: FetchError;
  readonly loading: boolean;
}

export interface IAuthenticationState {
  readonly account?: Account;
  readonly firstAccountCheck: boolean;
  readonly authenticated: boolean;
  readonly error?: FetchError;
  readonly loading: boolean;
  readonly signup: IReqState;
  readonly login: ILoginState;
  readonly passwordRecovery: IPasswordRecoveryState;
  readonly passwordReset: IPasswordResetState;
  readonly modeSelector: boolean;
  readonly verification: IReqState;
  readonly invite: IReqState;
  readonly settings: {
    basic: ISettingsState;
    contacts: ISettingsState;
    password: ISettingsState;
    report: ISettingsState;
    delete: {
      loading: boolean;
      error?: FetchError;
    };
  };
}

export const initialState: IAuthenticationState = {
  authenticated: false,
  /** Represents the attempt at authentication with a cookie in storage
   * Being true does not mean the authentication succeeded, just that
   * it got a response from the server.
   */
  firstAccountCheck: false,
  loading: false,
  login: {
    loading: false,
  },
  passwordRecovery: {
    loading: false,
  },
  passwordReset: {
    loading: false,
  },
  modeSelector: false,
  settings: {
    basic: {
      changed: false,
      loading: false,
    },
    contacts: {
      changed: false,
      loading: false,
    },
    password: {
      changed: false,
      loading: false,
    },
    report: {
      changed: false,
      loading: false,
    },
    delete: {
      loading: false,
    },
  },
  signup: {
    loading: false,
    success: false,
  },
  verification: {
    loading: false,
    success: false,
  },
  invite: {
    loading: false,
    success: false,
  },
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAuthenticationState = initialState,
  action: AuthenticationAction
): IAuthenticationState => {
  switch (action.type) {
    case getType(auth.fetchAccount.request):
      return {
        ...state,
        loading: true,
      };

    case getType(auth.fetchAccount.success): {
      const account = action.payload;

      return {
        ...state,
        account,
        authenticated: true,
        error: undefined,
        firstAccountCheck: true,
        loading: false,
      };
    }

    case getType(auth.fetchAccount.failure): {
      const { error } = action.payload;

      return {
        ...state,
        account: undefined,
        error,
        firstAccountCheck: true,
        loading: false,
      };
    }

    case getType(auth.passwordRecovery.request):
      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          error: undefined,
          loading: true,
        },
      };

    case getType(auth.passwordRecovery.success):
      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          loading: false,
        },
      };

    case getType(auth.passwordRecovery.failure): {
      const { error } = action.payload;

      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          error,
          loading: false,
        },
      };
    }

    case getType(auth.passwordReset.request):
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          error: undefined,
          loading: true,
        },
      };

    case getType(auth.passwordReset.success):
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: false,
        },
      };

    case getType(auth.passwordReset.failure): {
      const { error } = action.payload;

      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          error,
          loading: false,
        },
      };
    }

    case getType(auth.accountVerification.request):
      return {
        ...state,
        verification: {
          loading: true,
          success: false,
        },
      };

    case getType(auth.accountVerification.success):
      return {
        ...state,
        authenticated: false,
        verification: {
          loading: false,
          success: true,
        },
      };

    case getType(auth.accountVerification.failure): {
      const { error } = action.payload;

      return {
        ...state,
        verification: {
          error,
          loading: false,
          success: false,
        },
      };
    }

    case getType(auth.accountSetup.request):
      return {
        ...state,
        invite: {
          loading: true,
          success: false,
        },
      };

    case getType(auth.accountSetup.success):
      return {
        ...state,
        authenticated: false,
        invite: {
          loading: false,
          success: true,
        },
      };

    case getType(auth.accountSetup.failure): {
      const { error } = action.payload;

      return {
        ...state,
        invite: {
          error,
          loading: false,
          success: false,
        },
      };
    }

    case getType(auth.changeMode.request):
      return {
        ...state,
        loading: true,
      };

    case getType(auth.changeMode.success): {
      const { organisationId } = action.payload;

      const account = (state.account || {}) as Account;

      return {
        ...state,
        account: {
          ...account,
          organisationId,
        },
        loading: false,
        modeSelector: false,
      };
    }

    case getType(auth.changeMode.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(auth.login.request):
      return {
        ...state,
        login: {
          loading: true,
        },
      };

    case getType(auth.login.success):
      return {
        ...state,
        authenticated: true,
        login: {
          loading: false,
        },
      };

    case getType(auth.login.failure): {
      const { error } = action.payload;

      return {
        ...state,
        login: {
          error,
          loading: false,
        },
      };
    }

    case getType(auth.logout.request):
      return {
        ...state,
        loading: true,
      };

    case getType(auth.clearLogin):
    case getType(auth.logout.success): {
      return {
        ...state,
        account: undefined,
        authenticated: false,
        loading: false,
      };
    }

    case getType(auth.logout.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(auth.signUp.request):
      return {
        ...state,
        signup: {
          loading: true,
          success: false,
        },
      };

    case getType(auth.signUp.success):
      return {
        ...state,
        authenticated: false,
        signup: {
          loading: false,
          success: true,
        },
      };

    case getType(auth.signUp.failure): {
      const { error } = action.payload;

      return {
        ...state,
        signup: {
          error,
          loading: false,
          success: false,
        },
      };
    }

    case getType(auth.updateAccountSettings.request):
      return {
        ...state,
        settings: {
          ...state.settings,
          basic: {
            changed: true,
            loading: true,
          },
        },
      };

    case getType(auth.updateAccountSettings.success): {
      const { accountMeta } = action.payload;

      const organisationId = (state.account || {}).organisationId || '';
      const organisation = ((state.account || {}).organisations || {})[
        organisationId
      ];
      const account = (state.account || {}) as Account;

      return {
        ...state,
        account: {
          ...account,
          organisations: {
            ...(state.account || {}).organisations,
            [organisationId]: { ...organisation, ...accountMeta },
          },
        },
        settings: {
          ...state.settings,
          basic: {
            changed: false,
            loading: false,
          },
        },
      };
    }

    case getType(auth.updateAccountSettings.failure): {
      const { error } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          basic: {
            changed: true,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(auth.updateAccountContacts.request):
      return {
        ...state,
        settings: {
          ...state.settings,
          contacts: {
            changed: true,
            loading: true,
          },
        },
      };

    case getType(auth.updateAccountContacts.success):
      return {
        ...state,
        settings: {
          ...state.settings,
          contacts: {
            changed: false,
            loading: false,
          },
        },
      };

    case getType(auth.updateAccountContacts.failure): {
      const { error } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          contacts: {
            changed: true,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(auth.updateAccountPassword.request):
      return {
        ...state,
        settings: {
          ...state.settings,
          password: {
            changed: true,
            loading: true,
          },
        },
      };

    case getType(auth.updateAccountPassword.success):
      return {
        ...state,
        settings: {
          ...state.settings,
          password: {
            changed: false,
            loading: false,
          },
        },
      };

    case getType(auth.updateAccountPassword.failure): {
      const { error } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          password: {
            changed: true,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(auth.updateAccountReport.request):
      return {
        ...state,
        settings: {
          ...state.settings,
          report: {
            changed: true,
            loading: true,
          },
        },
      };

    case getType(auth.updateAccountReport.success):
      return {
        ...state,
        settings: {
          ...state.settings,
          report: {
            changed: false,
            loading: false,
          },
        },
      };

    case getType(auth.updateAccountReport.failure):
      return {
        ...state,
        settings: {
          ...state.settings,
          report: {
            changed: true,
            error: action.payload.error,
            loading: false,
          },
        },
      };

    case getType(auth.deleteAccount.request):
      return {
        ...state,
        settings: {
          ...state.settings,
          delete: {
            loading: true,
          },
        },
      };

    case getType(auth.deleteAccount.success):
      return {
        ...state,
        settings: {
          ...state.settings,
          delete: {
            loading: false,
          },
        },
        account: undefined,
        authenticated: false,
        loading: false,
      };

    case getType(auth.deleteAccount.failure):
      return {
        ...state,
        settings: {
          ...state.settings,
          delete: {
            error: action.payload.error,
            loading: false,
          },
        },
      };

    case getType(auth.wsUpdateUser):
      return {
        ...state,
        account: { ...state.account, ...action.payload },
      };

    case getType(auth.openModeSelector):
      return {
        ...state,
        modeSelector: true,
      };

    case getType(auth.closeModeSelector):
      return {
        ...state,
        modeSelector: false,
      };

    default:
      return state;
  }
};
