import AccountMeta, { AccountsMeta } from '@models/AccountMeta';
import axios from './axios';
import { convertArrayToIdMap } from './utils';

export const ACCOUNTS_API = {
  getServicePermissions: '/service/permissions',
  getAccounts: '/accounts',
  postCreateAccount: '/accounts',
  putAccountsActivate: '/accounts/activate',
  putAccountsPassword: '/accounts/password',
  putAccountsPermissions: '/accounts/permissions',
};

const normalizeAccounts = (accounts: AccountMeta[]): AccountsMeta =>
  convertArrayToIdMap(accounts);

export async function fetchAvailablePermissions(): Promise<string[]> {
  const { data } = await axios.get(ACCOUNTS_API.getServicePermissions);

  return data;
}

export async function fetchAccounts(): Promise<AccountsMeta> {
  const { data } = await axios.get(ACCOUNTS_API.getAccounts);

  return normalizeAccounts(data);
}

export async function createAccount(
  name: string,
  email: string,
  password: string,
  permissions: string[],
  operator: boolean,
): Promise<AccountMeta> {
  const { data } = await axios.post(ACCOUNTS_API.postCreateAccount, {
    email,
    name,
    password,
    permissions,
    operator,
  });

  return data;
}

export async function createAccountInvite(
  name: string,
  email: string,
  permissions: string[],
  operator: boolean,
): Promise<AccountMeta> {
  const { data } = await axios.post('/accounts/invite', {
    email,
    name,
    permissions,
    operator,
  });

  return data;
}

export async function activateAccount(
  id: string,
  active: boolean
): Promise<AccountMeta> {
  const { data } = await axios.put(ACCOUNTS_API.putAccountsActivate, {
    active,
    id,
  });

  return data;
}

export async function changeAccountPassword(
  id: string,
  password: string
): Promise<AccountMeta> {
  const { data } = await axios.put(ACCOUNTS_API.putAccountsPassword, {
    id,
    password,
  });

  return data;
}

export async function updateAccountPermissions(
  id: string,
  permissions: string[]
): Promise<AccountMeta> {
  const { data } = await axios.put(ACCOUNTS_API.putAccountsPermissions, {
    id,
    permissions,
  });

  return data;
}
