import { createAction, createAsyncAction } from 'typesafe-actions';

import FetchError from '@models/FetchError';
import Scan from '@models/Scan';

export enum ScansTypes {
  CREATE = '@@scans/CREATE',
  CREATE_SUCCEEDED = '@@scans/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@scans/CREATE_FAILED',
  
  WS_CREATE = '@@scans/WS_CREATE',
}

export const createScan = createAsyncAction(
  ScansTypes.CREATE,
  ScansTypes.CREATE_SUCCEEDED,
  ScansTypes.CREATE_FAILED
)<
  {
    scan: string;
    type: 'location';
    x: number;
    y: number;
    z: number;
  },
  { scan: Scan },
  { error: FetchError; }
>();

export const wsCreateScan = createAction(
  ScansTypes.WS_CREATE,
  (data: { scan: Scan }) => data
)();
