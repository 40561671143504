import { IFetchReportData } from '@actions/reports';
import { Date } from '@dashboard_utils/index';
import ROIReport from '@models/ROIReport';
import axios from './axios';

export const REPORTS_API = {
  postReport: '/reports',
  postROIReport: '/reports/roi',
};

export async function fetchReport(
  filterId: string,
  floorplanId: string,
  warehouseId: string,
  locale: string,
  measurementUnits: string,
  assetIds?: string[],
  executiveSummary?: string,
  plotData?: IFetchReportData,
  sensorPlacement?: boolean,
  endDate?: Date,
  startDate?: Date
): Promise<string> {
  const { data } = await axios({
    data: {
      filterId,
      assetIds,
      endDate,
      executiveSummary,
      floorplanId,
      locale,
      measurementUnits,
      data: plotData,
      sensorPlacement,
      startDate,
      warehouseId,
    },
    method: 'post',
    responseType: 'arraybuffer',
    url: REPORTS_API.postReport,
  });

  return data;
}

export async function fetchROIReport(roiData: ROIReport): Promise<string> {
  const { data } = await axios({
    data: roiData,
    method: 'post',
    responseType: 'arraybuffer',
    url: REPORTS_API.postROIReport,
  });

  return data;
}
