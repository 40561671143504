import { createAction, createAsyncAction } from 'typesafe-actions';

import FetchError from '@models/FetchError';
import { Notification } from '@models/Notification';
import { Rule, Rules } from '@models/Rule';
import { RuleTemplates } from '@models/RuleTemplate';
import { IFailed, IFailedWithShallow } from './types';

export enum RulesTypes {
  ACTIVATE = '@@rules/ACTIVATE',
  ACTIVATE_SUCCEEDED = '@@rules/ACTIVATE_SUCCEEDED',
  ACTIVATE_FAILED = '@@rules/ACTIVATE_FAILED',

  DEACTIVATE = '@@rules/DEACTIVATE',
  DEACTIVATE_SUCCEEDED = '@@rules/DEACTIVATE_SUCCEEDED',
  DEACTIVATE_FAILED = '@@rules/DEACTIVATE_FAILED',

  DELETE = '@@rules/DELETE',
  DELETE_SUCCEEDED = '@@rules/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@rules/DELETE_FAILED',

  FETCH = '@@rules/FETCH',
  FETCH_SUCCEEDED = '@@rules/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@rules/FETCH_FAILED',

  FETCH_TEMPLATES = '@@rules/FETCH_TEMPLATES',
  FETCH_TEMPLATES_SUCCEEDED = '@@rules/FETCH_TEMPLATES_SUCCEEDED',
  FETCH_TEMPLATES_FAILED = '@@rules/FETCH_TEMPLATES_FAILED',

  FETCH_RULE_ALERTS = '@@rules/FETCH_RULE_ALERTS',
  FETCH_RULE_ALERTS_FAILED = '@@rules/FETCH_RULE_ALERTS_FAILED',
  FETCH_RULE_ALERTS_SUCCEEDED = '@@rules/FETCH_RULE_ALERTS_SUCCEEDED',

  FETCH_RULE_DESCRIPTIONS = '@@rules/FETCH_RULE_DESCRIPTIONS',
  FETCH_RULE_DESCRIPTIONS_SUCCEEDED = '@@rules/FETCH_RULE_DESCRIPTIONS_SUCCEEDED',
  FETCH_RULE_DESCRIPTIONS_FAILED = '@@rules/FETCH_RULE_DESCRIPTIONS_FAILED',

  CREATE = '@@rules/CREATE',
  CREATE_SUCCEEDED = '@@rules/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@rules/CREATE_FAILED',

  UPDATE = '@@rules/UPDATE',
  UPDATE_SUCCEEDED = '@@rules/UPDATE_SUCCEEDED',
  UPDATE_FAILED = '@@rules/UPDATE_FAILED',

  OPENFORM = '@@rules/OPENFORM',
  CLOSEFORM = '@@rules/CLOSEFORM',
}

export interface IRule {
  id: string;
}

export interface ICreateRule {
  name: string;
  notifyEmail: boolean;
  notifyMobile: boolean;
  notifyWhatsapp: boolean;
  templateId: string;
  options: object;
}

export interface IUpdateRule extends ICreateRule {
  id: string;
}

export interface IFetchRuleAlerts {
  filterId: string;
  floorplanId: string;
  locale: string;
  ruleIds: string[];
  templateIds: string[];
  units: string;
  startTs?: number;
  endTs?: number;
  shallow?: boolean;
}

export interface RuleAlerts {
  list: Notification[];
  returned: number;
  total: number;
}

export interface IFetchRuleAlertsSucceed {
  filterId: string;
  ruleAlerts: RuleAlerts;
}

export interface IFetchRuleAlertsFail {
  filterId: string;
  error: FetchError;
}

export const activateRule = createAsyncAction(
  RulesTypes.ACTIVATE,
  RulesTypes.ACTIVATE_SUCCEEDED,
  RulesTypes.ACTIVATE_FAILED
)<IRule, IRule, IFailed>();

export const deactivateRule = createAsyncAction(
  RulesTypes.DEACTIVATE,
  RulesTypes.DEACTIVATE_SUCCEEDED,
  RulesTypes.DEACTIVATE_FAILED
)<IRule, IRule, IFailed>();

export const deleteRule = createAsyncAction(
  RulesTypes.DELETE,
  RulesTypes.DELETE_SUCCEEDED,
  RulesTypes.DELETE_FAILED
)<IRule, IRule, IFailed>();

export interface IFetchTemplates {
  locale: string;
  units: string;
  shallow?: boolean;
}
export interface IFetchTemplatesSucceeded {
  templates: RuleTemplates;
  shallow?: boolean;
}
export const fetchTemplates = createAsyncAction(
  RulesTypes.FETCH_TEMPLATES,
  RulesTypes.FETCH_TEMPLATES_SUCCEEDED,
  RulesTypes.FETCH_TEMPLATES_FAILED
)<IFetchTemplates, IFetchTemplatesSucceeded, IFailedWithShallow>();

export interface IFetchRules {
  shallow?: boolean;
}
export interface IFetchRulesSuccess {
  rules: Rules;
  shallow?: boolean;
}
export const fetchRules = createAsyncAction(
  RulesTypes.FETCH,
  RulesTypes.FETCH_SUCCEEDED,
  RulesTypes.FETCH_FAILED
)<IFetchRules, IFetchRulesSuccess, IFailedWithShallow>();

export const fetchRuleAlerts = createAsyncAction(
  RulesTypes.FETCH_RULE_ALERTS,
  RulesTypes.FETCH_RULE_ALERTS_SUCCEEDED,
  RulesTypes.FETCH_RULE_ALERTS_FAILED
)<IFetchRuleAlerts, IFetchRuleAlertsSucceed, IFetchRuleAlertsFail>();

export const createRule = createAsyncAction(
  RulesTypes.CREATE,
  RulesTypes.CREATE_SUCCEEDED,
  RulesTypes.CREATE_FAILED
)<ICreateRule, Rule, IFailed>();

export const updateRule = createAsyncAction(
  RulesTypes.UPDATE,
  RulesTypes.UPDATE_SUCCEEDED,
  RulesTypes.UPDATE_FAILED
)<IUpdateRule, Rule, IFailed>();

export const openRuleForm = createAction(
  RulesTypes.OPENFORM,
  (data?: Rule) => data
)();
export const closeRuleForm = createAction(
  RulesTypes.CLOSEFORM,
)();
