import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/diagrams';
import {
  createDiagram,
  deleteDiagram,
  fetchDiagrams,
  updateDiagram,
} from '@api/diagrams';
import { treatFetchError } from '@api/utils';
import Diagram, { Diagrams } from '@models/Diagram';

export function* createDiagramSaga(
  action: ActionType<typeof types.createDiagram.request>
) {
  const { intl, name, diagram } = action.payload;

  try {
    const diagramData: Diagram = yield call(createDiagram, name, diagram);

    yield put(types.createDiagram.success(diagramData));
  } catch (error: any) {
    yield put(
      types.createDiagram.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* deleteDiagramSaga(
  action: ActionType<typeof types.deleteDiagram.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteDiagram, id);

    yield put(types.deleteDiagram.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteDiagram.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchDiagramsSaga() {
  try {
    const diagrams: Diagrams = yield call(fetchDiagrams);

    yield put(types.fetchDiagrams.success({ diagrams }));
  } catch (error: any) {
    yield put(types.fetchDiagrams.failure({ error: treatFetchError(error) }));
  }
}

export function* updateDiagramSaga(
  action: ActionType<typeof types.updateDiagram.request>
) {
  const { id, intl, name, diagram } = action.payload;

  try {
    const diagramData: Diagram = yield call(updateDiagram, id, name, diagram);

    yield put(types.updateDiagram.success(diagramData));
  } catch (error: any) {
    yield put(
      types.updateDiagram.failure({ error: treatFetchError(error), intl })
    );
  }
}
