import { treatFetchError } from '@api/utils';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/items';
import { fetchItemsWithLocations, importItems, importStocks } from '@api/items';
import { Items } from '@models/Item';
import { StocksWithLocations } from '@models/Stocks';

export function* fetchItemsWithLocationsSaga(
  action: ActionType<typeof types.fetchItemsWithLocations.request>
) {
  const { shallow } = action.payload;

  try {
    const items: Items = yield call(fetchItemsWithLocations);

    yield put(types.fetchItemsWithLocations.success({ items, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchItemsWithLocations.failure({
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* importItemsSaga(
  action: ActionType<typeof types.importItems.request>
) {
  try {
    const items: Items = yield call(importItems, action.payload);

    yield put(types.importItems.success(items));
  } catch (error: any) {
    yield put(types.importItems.failure({ error: treatFetchError(error) }));
  }
}

export function* importStocksSaga(
  action: ActionType<typeof types.importStocks.request>
) {
  try {
    const stocks: StocksWithLocations = yield call(
      importStocks,
      action.payload
    );

    yield put(types.importStocks.success(stocks));
  } catch (error: any) {
    yield put(types.importStocks.failure({ error: treatFetchError(error) }));
  }
}
