import Maintenance from '@models/Maintenance';
import axios from './axios';

export const MAINTENANCES_API = {
  postMaintenance: '/maintenances/new',
};

export async function createMaintenance(
  assetId: string,
  observations: string,
  type: string
): Promise<Maintenance> {
  const { data: maintenance } = await axios.post(
    MAINTENANCES_API.postMaintenance,
    {
      assetId,
      observations,
      type,
    }
  );

  return maintenance;
}

export async function fetchMaintenancesByAsset(
  assetId: string
): Promise<Maintenance[]> {
  const { data } = await axios.get(`/maintenances/asset/${assetId}`);

  return data;
}
