import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/accounts';
import {
  activateAccount,
  changeAccountPassword,
  createAccount,
  createAccountInvite,
  fetchAccounts,
  fetchAvailablePermissions,
  updateAccountPermissions,
} from '@api/accounts';
import { treatFetchError } from '@api/utils';
import AccountMeta, { AccountsMeta } from '@models/AccountMeta';

export function* fetchAvailablePermissionsSaga() {
  try {
    const permissions: string[] = yield call(fetchAvailablePermissions);

    yield put(types.fetchAvailablePermissions.success({ permissions }));
  } catch (error: any) {
    yield put(
      types.fetchAvailablePermissions.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchAccountsSaga(
  action: ActionType<typeof types.fetchAccounts.request>
) {
  const { shallow } = action.payload;

  try {
    const accounts: AccountsMeta = yield call(fetchAccounts);

    yield put(types.fetchAccounts.success({ accounts, shallow }));
  } catch (error: any) {
    yield put(types.fetchAccounts.failure({ error: treatFetchError(error), shallow }));
  }
}

export function* createAccountSaga(
  action: ActionType<typeof types.createAccount.request>
) {
  const { name, email, password, permissions, operator, intl } = action.payload;

  try {
    const account: AccountMeta = yield call(
      createAccount,
      name,
      email,
      password,
      permissions,
      operator,
    );

    yield put(types.createAccount.success({ account, intl }));
  } catch (error: any) {
    yield put(
      types.createAccount.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* createAccountInviteSaga(
  action: ActionType<typeof types.createAccountInvite.request>
) {
  const { name, email, permissions, operator, intl } = action.payload;

  try {
    const account: AccountMeta = yield call(
      createAccountInvite,
      name,
      email,
      permissions,
      operator,
    );

    yield put(types.createAccountInvite.success({ account, intl }));
  } catch (error: any) {
    yield put(
      types.createAccountInvite.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* activateAccountSaga(
  action: ActionType<typeof types.activateAccount.request>
) {
  const { active, id, intl } = action.payload;

  try {
    const account: AccountMeta = yield call(activateAccount, id, active);

    yield put(
      types.activateAccount.success({
        active: account.active,
        id: account.id,
        intl,
      })
    );
  } catch (error: any) {
    yield put(
      types.activateAccount.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* changeAccountPasswordSaga(
  action: ActionType<typeof types.changeAccountPassword.request>
) {
  const { id, password, intl } = action.payload;

  try {
    yield call(changeAccountPassword, id, password);

    yield put(types.changeAccountPassword.success({ intl }));
  } catch (error: any) {
    yield put(
      types.changeAccountPassword.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}

export function* updateAccountPermissionsSaga(
  action: ActionType<typeof types.updateAccountPermissions.request>
) {
  const { permissions, id, intl } = action.payload;

  try {
    const account: AccountMeta = yield call(
      updateAccountPermissions,
      id,
      permissions
    );

    yield put(
      types.updateAccountPermissions.success({
        id: account.id,
        intl,
        permissions: account.permissions,
      })
    );
  } catch (error: any) {
    yield put(
      types.updateAccountPermissions.failure({
        error: treatFetchError(error),
        intl,
      })
    );
  }
}
