import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/player';
import { AssetsEventPeriods } from '@models/EventActivity';
import FetchError from '@models/FetchError';

type PlayerAction = ActionType<typeof types>;

export interface IPlayerState {
  readonly activity: Record<string, IEventsActivityState>;
}

interface IEventsActivityState {
  error?: FetchError;
  loading: boolean;
  assets?: AssetsEventPeriods;
}

export const initialState: IPlayerState = {
  activity: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IPlayerState = initialState,
  action: PlayerAction
): IPlayerState => {
  switch (action.type) {
    case getType(types.fetchEventsActivity.request): {
      const { filterId } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          [filterId]: {
            ...((state.activity || {})[filterId] || {}),
            assets: undefined,
            error: undefined,
            loading: true,
          },
        },
      };
    }

    case getType(types.fetchEventsActivity.failure): {
      const { filterId, error } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          [filterId]: {
            ...((state.activity || {})[filterId] || {}),
            error,
            loading: false,
          },
        },
      };
    }

    case getType(types.fetchEventsActivity.success): {
      const { filterId, eventsActivity } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          [filterId]: {
            ...((state.activity || {})[filterId] || {}),
            assets: eventsActivity.assets,
            loading: false,
          },
        },
      };
    }

    default:
      return state;
  }
};
