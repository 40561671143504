import * as types from '@actions/analytics';
import { fetchDataRange, fetchMetrics, fetchMetric } from '@api/analytics';
import { treatFetchError } from '@api/utils';
import Metric from '@models/Metric';
import MetricMeta from '@models/MetricMeta';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

export function* fetchDataRangeSaga(
  action: ActionType<typeof types.fetchDataRange.request>
) {
  const {
    filterId,
    warehouseId,
    warehouseTz,
    floorplanId,
    assetIds,
    shallow,
    tags,
  } = action.payload;

  try {
    const dataRange: types.IDataRange = yield call(
      fetchDataRange,
      warehouseId,
      floorplanId,
      assetIds,
      tags
    );

    yield put(
      types.fetchDataRange.success({
        filterId,
        floorplanId,
        warehouseId,
        warehouseTz,
        assetIds,
        tags,
        dataRange,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchDataRange.failure({
        filterId,
        floorplanId,
        warehouseId,
        assetIds,
        tags,
        shallow,
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchMetricsSaga(
  action: ActionType<typeof types.fetchMetrics.request>
) {
  const {
    filterId,
    section,

    warehouseId,
    floorplanId,
    zoneIds,

    tags,
    assetIds,

    templateIds,
    ruleIds,

    locale,
    units,
    shallow,
  } = action.payload;

  try {
    const metrics: MetricMeta[] = yield call(
      fetchMetrics,
      section,

      warehouseId,
      floorplanId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      locale,
      units
    );

    yield put(types.fetchMetrics.success({ filterId, metrics, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchMetrics.failure({
        filterId,
        shallow,
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchMetricSaga(
  action: ActionType<typeof types.fetchMetric.request>
) {
  const {
    filterId,
    metricId,

    warehouseId,
    floorplanId,
    zoneIds,

    tags,
    assetIds,

    templateIds,
    ruleIds,

    from,
    to,
    aggregations,
    returns,
    locale,
    units,
    shallow,
  } = action.payload;

  try {
    const data: Metric = yield call(
      fetchMetric,
      metricId,

      warehouseId,
      floorplanId,
      zoneIds,

      tags,
      assetIds,

      templateIds,
      ruleIds,

      from,
      to,
      aggregations,
      returns,
      locale,
      units
    );

    yield put(
      types.fetchMetric.success({
        filterId,
        metricId,
        data,
        shallow,
      })
    );
  } catch (error: any) {
    yield put(
      types.fetchMetric.failure({
        aggregations,
        filterId,
        metricId,
        shallow,
        error: treatFetchError(error),
      })
    );
  }
}
