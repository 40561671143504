import { createAction, createAsyncAction } from 'typesafe-actions';

import { Items } from '@models/Item';
import { StocksWithLocations } from '@models/Stocks';
import { IFailed, IFailedWithShallow, IImport } from './types';

export enum ItemsTypes {
  FETCH_ITEMSWITHLOCATIONS = '@@items/FETCH_ITEMSWITHLOCATIONS',
  FETCH_ITEMSWITHLOCATIONS_SUCCEEDED = '@@items/FETCH_ITEMSWITHLOCATIONS_SUCCEEDED',
  FETCH_ITEMSWITHLOCATIONS_FAILED = '@@items/FETCH_ITEMSWITHLOCATIONS_FAILED',

  IMPORT_ITEMS = '@@items/IMPORT_ITEMS',
  IMPORT_ITEMS_SUCCEEDED = '@@items/IMPORT_ITEMS_SUCCEEDED',
  IMPORT_ITEMS_FAILED = '@@items/IMPORT_ITEMS_FAILED',

  IMPORT_STOCKS = '@@items/IMPORT_STOCKS',
  IMPORT_STOCKS_SUCCEEDED = '@@items/IMPORT_STOCKS_SUCCEEDED',
  IMPORT_STOCKS_FAILED = '@@items/IMPORT_STOCKS_FAILED',

  OPEN_IMPORTITEMS_FORM = '@@items/OPEN_IMPORTITEMS_FORM',
  CLOSE_IMPORTITEMS_FORM = '@@items/CLOSE_IMPORTITEMS_FORM',

  OPEN_IMPORTSTOCKS_FORM = '@@items/OPEN_IMPORTSTOCKS_FORM',
  CLOSE_IMPORTSTOCKS_FORM = '@@items/CLOSE_IMPORTSTOCKS_FORM',
}

export interface IFetchIntemsWithLocations {
  shallow?: boolean;
}
export interface IFetchIntemsWithLocationsSuccess {
  items: Items;
  shallow?: boolean;
}
export const fetchItemsWithLocations = createAsyncAction(
  ItemsTypes.FETCH_ITEMSWITHLOCATIONS,
  ItemsTypes.FETCH_ITEMSWITHLOCATIONS_SUCCEEDED,
  ItemsTypes.FETCH_ITEMSWITHLOCATIONS_FAILED
  // eslint-disable-next-line prettier/prettier
)<IFetchIntemsWithLocations, IFetchIntemsWithLocationsSuccess, IFailedWithShallow>();

export const importItems = createAsyncAction(
  ItemsTypes.IMPORT_ITEMS,
  ItemsTypes.IMPORT_ITEMS_SUCCEEDED,
  ItemsTypes.IMPORT_ITEMS_FAILED
)<IImport, Items, IFailed>();

export const importStocks = createAsyncAction(
  ItemsTypes.IMPORT_STOCKS,
  ItemsTypes.IMPORT_STOCKS_SUCCEEDED,
  ItemsTypes.IMPORT_STOCKS_FAILED
)<IImport, StocksWithLocations, IFailed>();

export const openImportItemsForm = createAction(
  ItemsTypes.OPEN_IMPORTITEMS_FORM,
)();
export const closeImportItemsForm = createAction(
  ItemsTypes.CLOSE_IMPORTITEMS_FORM,
)();

export const openImportStocksForm = createAction(
  ItemsTypes.OPEN_IMPORTSTOCKS_FORM,
)();
export const closeImportStocksForm = createAction(
  ItemsTypes.CLOSE_IMPORTSTOCKS_FORM,
)();
