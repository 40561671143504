import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/employees';
import {
  createEmployee,
  deleteEmployee,
  fetchEmployees,
  updateEmployee,
} from '@api/employees';
import { treatFetchError } from '@api/utils';
import { Employee, Employees } from '@models/Employee';

export function* deleteEmployeeSaga(
  action: ActionType<typeof types.deleteEmployee.request>
) {
  const { id, intl } = action.payload;

  try {
    yield call(deleteEmployee, id);

    yield put(types.deleteEmployee.success(action.payload));
  } catch (error: any) {
    yield put(
      types.deleteEmployee.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* createEmployeeSaga(
  action: ActionType<typeof types.createEmployee.request>
) {
  const { file, intl, name, email, password, assetAssociations } = action.payload;

  try {
    const employee: Employee = yield call(
      createEmployee,
      assetAssociations,
      file,
      name,
      email,
      password
    );

    yield put(types.createEmployee.success({ employee, intl }));
  } catch (error: any) {
    yield put(
      types.createEmployee.failure({ error: treatFetchError(error), intl })
    );
  }
}

export function* fetchEmployeesSaga(
  action: ActionType<typeof types.fetchEmployees.request>
) {
  const { shallow } = action.payload;

  try {
    const employees: Employees = yield call(fetchEmployees);

    yield put(types.fetchEmployees.success({ employees, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchEmployees.failure({ error: treatFetchError(error), shallow })
    );
  }
}

export function* updateEmployeeSaga(
  action: ActionType<typeof types.updateEmployee.request>
) {
  const { file, id, image, intl, name, email, password, assetAssociations } = action.payload;

  try {
    const employee: Employee = yield call(
      updateEmployee,
      assetAssociations,
      file,
      id,
      image,
      name,
      email,
      password,
    );

    yield put(types.updateEmployee.success({ employee, intl }));
  } catch (error: any) {
    yield put(
      types.updateEmployee.failure({ error: treatFetchError(error), intl })
    );
  }
}
