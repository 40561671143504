import { omit } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import * as types from '@actions/diagrams';
import { Diagrams } from '@models/Diagram';

type Actions = ActionType<typeof types | typeof auth>;

export interface IDiagramsState {
  readonly diagrams: Diagrams;
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectId?: string;
}

export const initialState: IDiagramsState = {
  diagrams: {},
  error: undefined,
  loading: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IDiagramsState = initialState,
  action: Actions
): IDiagramsState => {
  switch (action.type) {
    case getType(types.createDiagram.request):
      return {
        ...state,
        error: undefined,
        loading: true,
        redirectId: undefined,
      };

    case getType(types.createDiagram.success):
      return {
        ...state,
        diagrams: {
          ...state.diagrams,
          [action.payload.id]: action.payload,
        },
        redirectId: action.payload.id,
        loading: false,
        error: undefined,
      };

    case getType(types.createDiagram.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.fetchDiagrams.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchDiagrams.success): {
      const { diagrams } = action.payload;

      return {
        ...state,
        diagrams,
        error: undefined,
        loading: false,
      };
    }

    case getType(types.fetchDiagrams.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.updateDiagram.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.updateDiagram.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.updateDiagram.success):
      return {
        ...state,
        diagrams: {
          ...state.diagrams,
          [action.payload.id]: action.payload,
        },
        loading: false,
        error: undefined,
      };

    case getType(types.deleteDiagram.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteDiagram.success):
      return {
        ...state,
        diagrams: omit(state.diagrams, action.payload.id),
        error: undefined,
        loading: false,
      };

    case getType(types.deleteDiagram.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(types.wsUpdateDiagram):
    case getType(types.wsCreateDiagram): {
      const { diagram } = action.payload;

      return {
        ...state,
        diagrams: {
          ...state.diagrams,
          [diagram.id]: diagram,
        },
      };
    }

    case getType(types.wsDeleteDiagram):
      return {
        ...state,
        diagrams: omit(state.diagrams, action.payload.id),
      };

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
