import { treatFetchError } from '@api/utils';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/orders';
import { fetchOrdersWithItems, fetchOrdersEvents, importOrders } from '@api/orders';
import { OldOrders } from '@models/Order';
import { IOrdersEvents } from '@api/websocket';

export function* fetchOrdersWithItemsSaga(
  action: ActionType<typeof types.fetchOrdersWithItems.request>
) {
  const { shallow } = action.payload;

  try {
    const orders: OldOrders = yield call(fetchOrdersWithItems);

    yield put(types.fetchOrdersWithItems.success({ orders, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchOrdersWithItems.failure({
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchOrdersEventsSaga(
  action: ActionType<typeof types.fetchOrdersEvents.request>
) {
  const { floorplanId } = action.payload;

  try {
    const data: IOrdersEvents[] = yield call(fetchOrdersEvents, floorplanId);

    yield put(types.fetchOrdersEvents.success({ floorplanId, data }));
  } catch (error: any) {
    yield put(
      types.fetchOrdersEvents.failure({
        floorplanId,
        error: treatFetchError(error),
      })
    );
  }
}

export function* importOrdersSaga(
  action: ActionType<typeof types.importOrders.request>
) {
  try {
    const orders: OldOrders = yield call(importOrders, action.payload);

    yield put(types.importOrders.success(orders));
  } catch (error: any) {
    yield put(types.importOrders.failure({ error: treatFetchError(error) }));
  }
}
