import { ActionType, getType } from 'typesafe-actions';

import * as app from '@actions/app';
import * as auth from '@actions/authentication';
import * as filter from '@actions/filter';
import * as types from '@actions/analytics';
import { getObjectFromLocalStorage } from '@app/utils/localStorageUtils';
import MetricMeta from '@models/MetricMeta';
import FetchError from '@models/FetchError';
import Metric, { MetricAggregation } from '@models/Metric';

const version = process.env.REACT_APP_VERSION;

type AnalyticsAction = ActionType<
  typeof types | typeof filter | typeof auth | typeof app
>;

export interface MetricAggregationWithFeedback extends MetricAggregation {
  loading: boolean;
  error?: FetchError;
  conditions: types.FetchMetric;
}
interface MetricWithFeedback extends Metric {
  aggregations: Record<string, MetricAggregationWithFeedback>;
}

export interface IDataRange {
  loading: boolean;
  dataRange?: types.IDataRange;
  error?: FetchError;
}

export interface MetricsMetaState {
  loading: boolean;
  metrics?: MetricMeta[];
  error?: FetchError;
  conditions: types.FetchMetrics;
}
export interface MetricState {
  loading: boolean;
  data: MetricWithFeedback;
  error?: FetchError;
  conditions: types.FetchMetric;
}

export interface IActiveMetrics {
  diagnostics: string;
  user: string;
  plots?: Record<string, Record<string, { id: string; result: string }>>;
}

export interface IAnalyticsState {
  readonly dataRanges: Record<string, IDataRange>;
  readonly meta: Record<string, MetricsMetaState>;
  readonly metrics: Record<string, Record<string, MetricState>>;
  readonly activeMetrics: Record<string, IActiveMetrics>;
}
const activeMetricsLs = (getObjectFromLocalStorage(
  `app_state_${version}_plots_activeMetrics`
) || {}) as Record<string, IActiveMetrics>;

export const initialState: IAnalyticsState = {
  dataRanges: {},
  meta: {},
  metrics: {},
  activeMetrics: activeMetricsLs,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAnalyticsState = initialState,
  action: AnalyticsAction
): IAnalyticsState => {
  switch (action.type) {
    case getType(types.fetchDataRange.request): {
      const { filterId, shallow } = action.payload;
      const { dataRanges } = state;

      return {
        ...state,
        dataRanges: {
          ...dataRanges,
          [filterId]: {
            ...dataRanges[filterId],
            error: undefined,
            loading:
              shallow !== true ? true : (dataRanges[filterId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchDataRange.success): {
      const { dataRange, filterId, shallow } = action.payload;
      const { dataRanges } = state;

      return {
        ...state,
        dataRanges: {
          ...dataRanges,
          [filterId]: {
            ...dataRanges[filterId],
            dataRange,
            error: undefined,
            loading:
              shallow !== true ? false : (dataRanges[filterId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchDataRange.failure): {
      const { filterId, error, shallow } = action.payload;
      const { dataRanges } = state;

      return {
        ...state,
        dataRanges: {
          ...dataRanges,
          [filterId]: {
            ...dataRanges[filterId],
            error,
            loading:
              shallow !== true ? false : (dataRanges[filterId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchMetrics.request): {
      const { filterId, shallow } = action.payload;
      const { meta } = state;

      return {
        ...state,
        meta: {
          ...meta,
          [filterId]: {
            error: undefined,
            loading: shallow !== true ? true : (meta[filterId] || {}).loading,
            conditions: action.payload,
          },
        },
      };
    }

    case getType(types.fetchMetrics.success): {
      const { metrics, filterId, shallow } = action.payload;

      let treatedMetrics = metrics;
      /**
       * If fetch metrics request is comming from zone popup,
       * remove aggregations with zone levels.
       */
      if (filterId === 'popup') {
        treatedMetrics = metrics.map((m) => ({
          ...m,
          defaultAggregations: (m.defaultAggregations || []).filter(
            (a) =>
              a.level1 !== 'zones' &&
              a.level2 !== 'zones' &&
              a.level3 !== 'zones'
          ),
        }));
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          [filterId]: {
            ...state.meta[filterId],
            metrics: treatedMetrics,
            error: undefined,
            loading:
              shallow !== true ? false : (state.meta[filterId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchMetrics.failure): {
      const { filterId, error, shallow } = action.payload;
      const { meta } = state;

      return {
        ...state,
        meta: {
          ...meta,
          [filterId]: {
            ...meta[filterId],
            error,
            loading: shallow !== true ? false : (meta[filterId] || {}).loading,
          },
        },
      };
    }

    case getType(types.fetchMetric.request): {
      const { aggregations, filterId, metricId, shallow } = action.payload;

      const { loading } = (state.metrics[filterId] || {})[metricId] || {};
      const aggr = {
        ...((((state.metrics[filterId] || {})[metricId] || {}).data || {})
          .aggregations || {}),
      };
      /**
       * If there are no results for a given aggregation, no id is defined
       * and therefor is useless in the next metric fetch
       */
      Object.keys(aggr).forEach((k) => {
        if (!aggr[k].id) {
          delete aggr[k];
        }
      });

      // Update aggregation states
      (aggregations || []).forEach((a) => {
        aggr[a.id] = {
          ...(aggr[a.id] || {}),
          ...a,
          conditions: action.payload,
          error: undefined,
          loading: shallow !== true ? true : loading,
        };
      });

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [filterId]: {
            ...state.metrics[filterId],
            [metricId]: {
              ...(state.metrics[filterId] || {})[metricId],
              data: { aggregations: aggr },
              error: undefined,
              loading: shallow !== true ? true : loading,
              conditions: action.payload,
            },
          },
        },
      };
    }

    case getType(types.fetchMetric.success): {
      const { filterId, metricId, data: req, shallow } = action.payload;

      const { loading, data } = (state.metrics[filterId] || {})[metricId] || {};

      const aggregations: Record<string, MetricAggregationWithFeedback> = {};
      Object.keys(req.aggregations || {}).forEach((id) => {
        aggregations[id] = {
          ...(data || {}).aggregations[id],
          ...aggregations[id],
          ...req.aggregations[id],
          error: undefined,
          loading: shallow !== true ? false : loading,
        };
      });

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [filterId]: {
            ...state.metrics[filterId],
            [metricId]: {
              ...(state.metrics[filterId] || {})[metricId],
              data: {
                ...data,
                ...req,
                aggregations: {
                  ...(data || {}).aggregations,
                  ...aggregations,
                },
              },
              loading: shallow !== true ? false : loading,
            },
          },
        },
      };
    }

    case getType(types.fetchMetric.failure): {
      const { aggregations, filterId, metricId, error, shallow } =
        action.payload;

      const { loading } = (state.metrics[filterId] || {})[metricId] || {};
      const aggr = {
        ...((((state.metrics[filterId] || {})[metricId] || {}).data || {})
          .aggregations || {}),
      };

      (aggregations || []).forEach((a) => {
        aggr[a.id] = { ...(aggr[a.id] || {}), loading: false };
        aggr[a.id].error = error;
        aggr[a.id].loading = shallow !== true ? false : loading;
      });

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [filterId]: {
            ...state.metrics[filterId],
            [metricId]: {
              ...(state.metrics[filterId] || {})[metricId],
              data: {
                ...(((state.metrics[filterId] || {})[metricId] || {}).data ||
                  {}),
                aggregations: aggr,
              },
              error,
              loading: shallow !== true ? false : loading,
            },
          },
        },
      };
    }

    case getType(app.toggleZoneMetrics): {
      const { filterId } = action.payload;

      return {
        ...state,
        activeMetrics: {
          ...state.activeMetrics,
          popup: {
            ...(state.activeMetrics[filterId || ''] || {}),
          },
        },
      };
    }

    case getType(types.toggleMetric): {
      const { filterId, section, metricId } = action.payload;

      return {
        ...state,
        activeMetrics: {
          ...state.activeMetrics,
          [filterId]: {
            ...(state.activeMetrics[filterId] || {}),
            [section]: metricId,
          },
        },
      };
    }

    case getType(types.toggleMetricPlot): {
      const { filterId, metricId, plotType, id, result } = action.payload;

      return {
        ...state,
        activeMetrics: {
          ...state.activeMetrics,
          [filterId]: {
            ...(state.activeMetrics[filterId] || {}),
            plots: {
              ...((state.activeMetrics[filterId] || {}).plots || {}),
              [metricId]: {
                ...(((state.activeMetrics[filterId] || {}).plots || {})[
                  metricId
                ] || {}),
                [plotType]: { id, result },
              },
            },
          },
        },
      };
    }

    case getType(filter.deleteFilter): {
      const { activeMetrics } = state;

      const newActiveMetrics = JSON.parse(JSON.stringify(activeMetrics));
      delete newActiveMetrics[action.payload.id];

      return { ...state, activeMetrics: newActiveMetrics };
    }

    case getType(auth.deleteAccount.success):
    case getType(auth.login.success):
    case getType(auth.logout.success):
    case getType(auth.clearLogin):
    case getType(auth.changeMode.success): {
      return { ...initialState };
    }

    default:
      return state;
  }
};
