import { createAction, createAsyncAction } from 'typesafe-actions';
import { IntlShape } from 'react-intl';

import PreCheck, {
  IAssetPreCheck,
  IPreCheckAnswer,
  IPreCheckQuestion,
  PreChecks,
} from '@models/PreCheck';
import { IFailed, IFeedbackFailed } from './types';

enum Types {
  CREATE = '@@prechecks/CREATE',
  CREATE_SUCCEEDED = '@@prechecks/CREATE_SUCCEEDED',
  CREATE_FAILED = '@@prechecks/CREATE_FAILED',

  CREATEANSWER = '@@prechecks/CREATEANSWER',
  CREATEANSWER_SUCCEEDED = '@@prechecks/CREATEANSWER_SUCCEEDED',
  CREATEANSWER_FAILED = '@@prechecks/CREATEANSWER_FAILED',

  DELETE = '@@prechecks/DELETE',
  DELETE_SUCCEEDED = '@@prechecks/DELETE_SUCCEEDED',
  DELETE_FAILED = '@@prechecks/DELETE_FAILED',

  EDIT = '@@prechecks/EDIT',
  EDIT_SUCCEEDED = '@@prechecks/EDIT_SUCCEEDED',
  EDIT_FAILED = '@@prechecks/EDIT_FAILED',

  FETCH = '@@prechecks/FETCH',
  FETCH_SUCCEEDED = '@@prechecks/FETCH_SUCCEEDED',
  FETCH_FAILED = '@@prechecks/FETCH_FAILED',

  FETCHPRECHECK = '@@prechecks/FETCHPRECHECK',
  FETCHPRECHECK_SUCCEEDED = '@@prechecks/FETCHPRECHECK_SUCCEEDED',
  FETCHPRECHECK_FAILED = '@@prechecks/FETCHPRECHECK_FAILED',

  FETCHASSETPRECHECK = '@@prechecks/FETCHASSETPRECHECK',
  FETCHASSETPRECHECK_SUCCEEDED = '@@prechecks/FETCHASSETPRECHECK_SUCCEEDED',
  FETCHASSETPRECHECK_FAILED = '@@prechecks/FETCHASSETPRECHECK_FAILED',

  OPENFORM = '@@prechecks/OPENFORM',
  CLOSEFORM = '@@prechecks/CLOSEFORM',

  OPEN_PRECHECKLIST_FORM = '@@prechecks/OPEN_PRECHECKLIST_FORM',
  CLOSE_PRECHECKLIST_FORM = '@@prechecks/CLOSE_PRECHECKLIST_FORM',
}

export interface IDeletePreCheck {
  id: string;
}

export interface ICreatePreCheck {
  name: string;
  questions: IPreCheckQuestion[];
}

export interface ICreateAnswer {
  id: string;
  assetId: string;
  answers: IPreCheckAnswer[];
  intl: IntlShape;
}
export interface ICreateAnswerSuccess {
  preCheckAnswer: IAssetPreCheck;
  intl: IntlShape;
}

export interface IEditPreCheck extends ICreatePreCheck {
  id: string;
}

interface IFetchPreCheck {
  id: string;
}

interface IFetchPreCheckAnswers {
  assetId: string;
}
interface ISucceedFetchPreCheckAnswers extends IFetchPreCheckAnswers {
  preCheckAnswers: IAssetPreCheck[];
}
interface IFailedFetchPreCheckAnswers extends IFailed {
  assetId: string;
}

export const createPreCheck = createAsyncAction(
  Types.CREATE,
  Types.CREATE_SUCCEEDED,
  Types.CREATE_FAILED
)<ICreatePreCheck, PreCheck, IFailed>();

export const createPreCheckAnswer = createAsyncAction(
  Types.CREATEANSWER,
  Types.CREATEANSWER_SUCCEEDED,
  Types.CREATEANSWER_FAILED
)<ICreateAnswer, ICreateAnswerSuccess, IFeedbackFailed>();

export const deletePreCheck = createAsyncAction(
  Types.DELETE,
  Types.DELETE_SUCCEEDED,
  Types.DELETE_FAILED
)<IDeletePreCheck, IDeletePreCheck, IFailed>();

export const editPreCheck = createAsyncAction(
  Types.EDIT,
  Types.EDIT_SUCCEEDED,
  Types.EDIT_FAILED
)<IEditPreCheck, PreCheck, IFailed>();

export const fetchPreCheckList = createAsyncAction(
  Types.FETCH,
  Types.FETCH_SUCCEEDED,
  Types.FETCH_FAILED
)<void, PreChecks, IFailed>();

export const fetchPreCheck = createAsyncAction(
  Types.FETCHPRECHECK,
  Types.FETCHPRECHECK_SUCCEEDED,
  Types.FETCHPRECHECK_FAILED
)<IFetchPreCheck, PreCheck, IFailed>();

export const fetchPreCheckAnswersByAsset = createAsyncAction(
  Types.FETCHASSETPRECHECK,
  Types.FETCHASSETPRECHECK_SUCCEEDED,
  Types.FETCHASSETPRECHECK_FAILED
)<
  IFetchPreCheckAnswers,
  ISucceedFetchPreCheckAnswers,
  IFailedFetchPreCheckAnswers
>();

export const openPreCheckForm = createAction(
  Types.OPENFORM,
  (data?: PreCheck) => data
)();
export const closePreCheckForm = createAction(
  Types.CLOSEFORM,
)();

export const openPreCheckListForm = createAction(
  Types.OPEN_PRECHECKLIST_FORM,
  (preCheckId: string, assetId: string) =>
    ({ preCheckId, assetId })
)();
export const closePreCheckListForm = createAction(
  Types.CLOSE_PRECHECKLIST_FORM,
)();
